import React from 'react';
import PropTypes from 'prop-types';
import '../styles/sidebarMenuMobile.css';
import { injectIntl } from 'react-intl';

class SidebarMenuMobile extends React.Component {

    constructor(props) {
        super(props);

        this.itemsMobile = this.props.MenuHeader.concat(this.props.MenuEventsHeader);
        this.state = { isEventsSelected: false };

    }

    ItemSelected(title, route) {

        if (title !== 'menuheader.events')
            this.props.MenuMobileSelect(route);

        if (title == 'menuheader.events')
            this.setState({ isEventsSelected: true });

    }

    informationInstall() {
        return (
            <div id='divInstallInfo'>
                <p >
                    {this.props.InstallationInfo1}
                </p>
                <p >
                    {this.props.InstallationInfo2}
                </p>
            </div>
        );

    }


    renderMenuMobile() {
        return (
            <div className='menuMobile'>

                {this.itemsMobile.map((menu, index) => {
                    const t = this.props.intl.formatMessage({ id: menu.Title });
                    let imgList = ('/images/icons/' + menu.Route.TemplateId + '.svg').toLowerCase();
                    let menuEventsHeader = menu.Title.startsWith('menueventsheader')
                    let cssMenuMobile;
                    if (menu.Title.startsWith('menuheader')) { cssMenuMobile = 'menuMobileHeader' } else cssMenuMobile = 'menuEventsHeader';

                    if ((menu.Mobile !== false && !menuEventsHeader) || (menuEventsHeader && this.state.isEventsSelected === true))

                        return (
                            <div key={index} >
                                <div >
                                    <button id={cssMenuMobile}
                                        onClick={() => this.ItemSelected(menu.Title, menu.Route)}>
                                        <div id='avatarImgIcon' className='e-avatar e-avatar-xsmall e-avatar-circle'>
                                            <img id='imgIcon' src={imgList}></img>
                                        </div>
                                        {t}
                                    </button>
                                </div>
                            </div>
                        );

                })}
            </div>
        );
    }

    render() {

        let widthSidebar = '0px';
        if (this.props.SidebarMenuMobileVisible == 1)
            widthSidebar = '70%';

        return (
            <div id='divBlockSidebarmenuMobile'>
                <div id='divSidebarmenuMobile' className='slide-in-left'
                    style={{ width: widthSidebar }} >
                    {this.renderMenuMobile()}
                    {this.informationInstall()}
                </div>
            </div>
        );
    }
}

SidebarMenuMobile.propTypes = {
    SidebarMenuMobileVisible: PropTypes.number,
    MenuHeader: PropTypes.array,
    MenuEventsHeader: PropTypes.array,
    intl: PropTypes.object,
    MenuMobileSelect: PropTypes.func,
    InstallationInfo1: PropTypes.string,
    InstallationInfo2: PropTypes.string
};

export default injectIntl(SidebarMenuMobile);