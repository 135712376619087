import React from 'react';
import PropTypes from 'prop-types';
import dotnetify from 'dotnetify';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/sidebarSetupDepartments.css';
import Toast from '../components/Toast';

import { injectIntl, FormattedMessage } from 'react-intl';

class SidebarSetupDepartments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            DepartmentDetail: [],
            NewDepartment: [],
            Notification: [],
        };

        this.vm = dotnetify.react.connect('SetupDepartmentDetailVM', this);
    }

    componentWillUnmount() {
        this.vm?.$destroy();
    }


    componentDidUpdate(prevProps) {
        if (prevProps?.SidebarSetupDepartmentsVisible !== this.props?.SidebarSetupDepartmentsVisible) {
            this.setState({ LocalSidebarManageVisible: this.props.SidebarSetupDepartmentsVisible });
        }

        if (prevProps?.IsNewDepartment != this.props?.IsNewDepartment)
            if (this.props.IsNewDepartment)
                this.setState({ DepartmentDetail: this.state.NewDepartment });

        if (prevProps?.SelectedDepartment?.Id != this.props?.SelectedDepartment?.Id) {
            this.vm?.$dispatch({ DepartmentID: this.props?.SelectedDepartment?.Id });
        }
    }

    changeDepartmentDetail(e) {
        this.setState({
            DepartmentDetail: { ...this.state.DepartmentDetail, [e.target.name]: e.target.value }
        });
    }

    saveDepartment() {
        this.vm?.$dispatch({ SaveDepartment: this.state.DepartmentDetail });
    }

    renderDepartmentDetail() {
        if ((!this.props.SidebarSetupDepartmentsVisible) || (this.state.DepartmentDetail == null))
            return (null);
        else
            return (
                <div id='divDepartment'>
                    <Toast
                        Notification={this.state.Notification}
                        Refresh={this.props.RefreshDepartments}
                        Option='departments'
                    />
                    <div className='row rowHeader'>
                        <div className='col-xs-12'>
                            <div className='row rowInfo'>
                                <span className='lblTitle'>{this.props.SelectedDepartment.Name}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='row' id='divDepartmentTop'>
                            <div className='col-xs-6'>
                                <span className='lblValue'><FormattedMessage id='setup_department' /></span>
                            </div>
                            <div className='col-xs-6' id='divBtnDepartment'>
                                <ButtonComponent id='btnSaveDepartment' className='Button' onClick={() => this.saveDepartment()}><FormattedMessage id='setup_departments.save_department' /></ButtonComponent>
                            </div>
                        </div>

                        <div  className='row' id='divDepartmentDetail'>
                            <span className='col-xs-12 lblDescription detailName'><FormattedMessage id='department' /></span>
                            <input className='col-xs-12 e-input' type='text' name='Name' value={this.state?.DepartmentDetail?.Name || ''} onChange={this.changeDepartmentDetail.bind(this)} id='txtName' placeholder={this.props.intl.formatMessage({ id:'enter_department_name'})} />
                        </div>

                    </div>
                </div>
            );
    }

    renderButtonsSidebarManage() {
        return (
            <div id='divButtonsSidebar'>
                <div id='divButtonClear'>
                    <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                </div>

                <div id='divButtonPrev'>
                    <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeDepartment(-1)} iconCss='e-icons e-sidebarprev' />
                </div>
                <div id='divButtonNext'>
                    <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeDepartment(+1)} iconCss='e-icons e-sidebarnext' />
                </div>
            </div>
        );
    }

    renderContentSidebarSetupDepartments() {
        if (this.props.SidebarManageVisible === 0)
            return null;

        let stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            return (
                <div>
                    {this.renderButtonsSidebarManage()}
                    {this.renderDepartmentDetail()}
                </div>
            );
    }

    render() {

        var widthSidebar = { width: '0px' };
        var stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            widthSidebar = { width: '45%' };


        return (
            <div ref={Sidebar => this.sidebarobj = Sidebar}
                id='sidebarSetupDepartments' style={widthSidebar}>
                {this.renderContentSidebarSetupDepartments()}
            </div>
        );

    }
}

SidebarSetupDepartments.propTypes = {
    SidebarManageVisible: PropTypes.bool,
    SidebarSetupDepartmentsVisible: PropTypes.number,
    IsNewDepartment: PropTypes.bool,
    SelectedDepartment: PropTypes.any,
    RefreshDepartments: PropTypes.func,
    ClearSelection: PropTypes.func,
    intl: PropTypes.object,
    ChangeDepartment: PropTypes.func
};

export default injectIntl(SidebarSetupDepartments);
