import * as React from 'react';
import dotnetify from 'dotnetify';

import '../styles/setupSitesPage.css';

import { GridComponent, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import SidebarSetupSites from '../components/SidebarSetupSites';

import { L10n, setCulture } from '@syncfusion/ej2-base';
import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class SetupSitesPage extends React.Component {

    constructor(props) {
        super(props);

        this.vm = dotnetify.react.connect('SetupSitesVM', this);

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            Sites: [],
            SelectedSite: [],
            SidebarSetupSitesVisible: 0,
            IsNewSite: false
        };
    }

    updateLanguage() {
        let commonData = CommonDataManager.getInstance();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('SetupSitesPage');
    }

    componentWillUnmount() {
        if (this.vm !== undefined)
            this.vm?.$destroy();

        if (this.commonData !== undefined)
            this.commonData.unsubscribe(this.updateLanguage);
    }

    clearSelection() {
        let cloneSites = JSON.parse(JSON.stringify(this.state.Sites));

        for (let i = 0; i < cloneSites.length; i++)
            cloneSites[i].Selected = false;

        this.setState({ Sites: cloneSites, SidebarSetupSitesVisible: 0, SelectedSite: [], IsNewSite: false });
    }

    changeSite(direction) {

        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state?.SelectedSite?.Id);

        if (direction == -1)
            selectedRowIndex--;

        if (direction == 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) return;

        if (selectedRowIndex > pageRows.length - 1) return;

        let newId = pageRows[selectedRowIndex].Id;

        let cloneSites = JSON.parse(JSON.stringify(this.state.Sites));

        let rowHeight = this.gridInstance.getRows()[selectedRowIndex].scrollHeight;
        window.scrollBy({ top: rowHeight * direction, behavior: 'smooth' });

        for (let i = 0; i < cloneSites.length; i++)
            cloneSites[i].Selected = cloneSites[i].Id === newId;

        this.setState({ Sites: cloneSites, SelectedSite: cloneSites.filter(h => h.Selected)[0] });
    }

    refreshSites() {
        this.vm?.$dispatch({ RefreshSites: '' });
    }

    newSite() {
        this.setState({ SidebarSetupSitesVisible: 1, IsNewSite: true });
    }

    rowSiteSelected() {
        var selected;
        var id = -1;

        if (this.gridInstance.getSelectedRecords().length === 1) {
            id = this.gridInstance.getSelectedRecords()[0].Id;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        if (selected === undefined)
            id = -1;
        else
            if (selected.Selected)
                id = -1;

        var cloneSites = JSON.parse(JSON.stringify(this.state)).Sites;

        for (let i = 0; i < cloneSites.length; i++) {
            cloneSites[i].Selected = (cloneSites[i].Id === id);
        }

        this.setState({ Sites: cloneSites, SidebarSetupSitesVisible: (id !== -1) ? 1 : 0, SelectedSite: selected, NewSite: false });
    }

    render() {
        let columns = [
            { field: 'Selected', headerText:'' , width:'50px', displayAsCheckBox: true, allowFiltering: false},
            { field: 'Id', visible: false, headerText: 'ID', isPrimaryKey: true},
            { field: 'Name', headerText: this.intl.formatMessage({ id: 'name' })},
            { field: 'TimeZone', headerText: this.intl.formatMessage({ id: 'timezone' })},
        ];
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divSetup'>
                    <BasePage title={this.intl.formatMessage({ id: 'menusetupheader.sites' })} navigation={this.intl.formatMessage({ id: 'menusetupheader.sites' })}></BasePage>
                    <div id='divToolbarSetupSites' className='row'>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                            <ButtonComponent id='btnNewSite' className='Button' onClick={() => this.newSite()}><FormattedMessage id='setup_sites.new_site' /></ButtonComponent>
                        </div>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                        </div>
                    </div>
                    <div id='divSetupSitesGrid'>
                        <GridComponent id='setupSitesGrid' ref={grid => this.gridInstance = grid}
                            dataSource={this.state.Sites} enableHover={true}
                            allowFiltering={true} enableToggle={true} columns={columns}
                            allowExcelExport={true} selectionSettings={{ type: 'Single' }}
                            filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                            allowSorting={true} allowSelection={true}
                            rowSelected={this.rowSiteSelected.bind(this)}>
                            <Inject services={[Filter, Sort]} />
                        </GridComponent>
                        <SidebarSetupSites
                            SidebarSetupSitesVisible={this.state.SidebarSetupSitesVisible}
                            SelectedSite={this.state.SelectedSite}
                            IsNewSite={this.state.IsNewSite}
                            ClearSelection={this.clearSelection.bind(this)}
                            RefreshSites={this.refreshSites.bind(this)}
                            ChangeSite={this.changeSite.bind(this)}
                        />
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

SetupSitesPage.propTypes = {
};

export default SetupSitesPage;