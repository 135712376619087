import React from 'react';
import PropTypes from 'prop-types';
import { GridComponent, ExcelExport } from '@syncfusion/ej2-react-grids';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Zoom, Export, ScatterSeries, DateTime, DateTimeCategory, Legend, Category, Crosshair, LineSeries, Tooltip, StripLine } from '@syncfusion/ej2-react-charts';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import '../../styles/manage/chartControl.css';
import CommonDataManager from '../../components/CommonDataManager';
import { loadCldr, L10n, setCulture } from '@syncfusion/ej2-base';
import { injectIntl, FormattedMessage } from 'react-intl';
import { convertFahrenheit } from '../../components/utils/TemperatureConversion';
import { Internationalization } from '@syncfusion/ej2-base';
class ChartControl extends React.Component {
    constructor(props) {
        super(props);

        this.items = [
            { id: 0, text: 'JPEG' },
            { id: 1, text: 'PNG' },
            { id: 2, text: 'SVG' },
            { id: 3, text: 'PDF' },
            { id: 4, text: 'EXCEL' }
        ];


        this.ENABLE_CROSSHAIR = false;

        this.fields = { text: 'text', value: 'id' };

        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribeTheme(this.updateTheme.bind(this));
        this.commonData.subscribe(this.updateLanguage.bind(this));
        this.commonData.subscribeDateTime(this.updateDateTime.bind(this));

        L10n.load(this.commonData.getMessages());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());
        setCulture(this.commonData.getLanguage());

        this.state = {
            Language: this.commonData.getLanguage(),
            Theme: this.commonData.getTheme(),
            FormatDate: this.commonData.getDateFormat(),
            enableCrosshair: false,
            enabledNextHistory: false,
            enabledPrevHistory: true,
        };


        // this.palette = ['red', 'blue', 'green', 'orange', 'grey', '#8cc98c','#ffe72f'];
        this.palette = ['red', 'blue', 'green', '#ffe72f'];
        this.new_intl = new Internationalization();
    }

    updateLanguage() {
        L10n.load(this.commonData.getMessages());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());
        setCulture(this.commonData.getLanguage());
        this.setState({ Language: this.commonData.getLanguage() });
    }

    updateTheme() {
        this.setState({ Theme: this.commonData.getTheme() });
    }

    updateDateTime() {
        this.setState({ FormatDate: this.commonData.getDateFormat() });
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextState.enableCrosshair !== this.state.enableCrosshair) return true;

        if (nextProps?.UmTemperature !== this.props?.UmTemperature) return true;

        if (nextState.Language !== this.state.Language) return true;

        if (nextState?.Theme !== this.state?.Theme) return true;

        if (!this.props?.Realtime)
            if (this.props?.SelectedDevice)
                if ((this.props.SelectedDevice.Id === nextProps.SelectedDevice.Id) && (this.props?.DataDetailHistory?.DataHistoryTemperature === nextProps?.DataDetailHistory?.DataHistoryTemperature))
                    return false;

        return true;
    }

    componentDidUpdate(prevProps, prevState) {

        if ((this.props?.DataDetailHistory !== prevProps?.DataDetailHistory) || (prevState?.Language != this.state?.Language)) {
            this.chartInstance.dataSource = null;

            if (this.props?.DataDetailHistory) {
                let tmpDataChart = JSON.parse(JSON.stringify(this.props?.DataDetailHistory?.DataHistoryTemperature));
                if (this.props.UmTemperature === 'F') {
                    for (let i = 0; i < tmpDataChart?.length; i++) {

                        tmpDataChart[i].TOven = convertFahrenheit(tmpDataChart[i].TOven);
                        tmpDataChart[i].TFridge = convertFahrenheit(tmpDataChart[i].TFridge);
                        tmpDataChart[i].TProbe = convertFahrenheit(tmpDataChart[i].TProbe);
                    }
                }
                this.chartInstance.dataSource = tmpDataChart;
                this.renderBands();
            }
            else {
                this.chartInstance.clearSeries();
            }
        }

        if (this.chartInstance) {
            let tmpTitle = '';
            if (this.props.SelectedDevice !== undefined)
                tmpTitle = this.props.SelectedDevice.DeviceID + ' ' + this.props.SelectedDevice.Site + ' ' + this.props.SelectedDevice.Department;

            let tmpSubTitle = '';
            if (this.props.Realtime)
                tmpSubTitle = this.new_intl.formatDate(new Date(), { format: this.state.FormatDate });
            else
                if (this.datepickerInstance?.value)
                    tmpSubTitle = this.new_intl.formatDate(new Date(this.datepickerInstance.value), { format: this.state.FormatDate });
                else return null;

            if (this.chartInstance.title !== tmpTitle)
                this.chartInstance.title = tmpTitle;

            if (this.chartInstance.subTitle !== tmpSubTitle)
                this.chartInstance.subTitle = tmpSubTitle;

            if (prevState.enableCrosshair !== this.state.enableCrosshair)
                this.chartInstance.refresh(false);

        }

        if ((this.props?.SelectedDevice?.Id !== prevProps?.SelectedDevice?.Id) || (this.props?.SelectedDevice === undefined)) {
            this.filterDateTemperaturesHistory();
        }

    }

    handleExport(e) {
        let fileName = this.props.SelectedDevice.DeviceID + '_' + this.props.SelectedDevice.Site + '_' + this.props.SelectedDevice.Department;

        if (e.item.id === 0) this.chartInstance.exportModule.export('JPEG', fileName);
        if (e.item.id === 1) this.chartInstance.exportModule.export('PNG', fileName);
        if (e.item.id === 2) this.chartInstance.exportModule.export('SVG', fileName);
        if (e.item.id === 3) this.chartInstance.exportModule.export('PDF', fileName);
        if (e.item.id === 4) this.gridInstance.excelExport(this.getExcelExportProperties(fileName));
    }

    getExcelExportProperties(fileName) {
        return {

            header: {

                headerRows: 10,
                rows: [
                    {
                        index: 2,
                        cells: [
                            { index: 1, value: this.props.intl.formatMessage({ id: 'id_trolley' }), style: { fontColor: '#0a0a0a', fontSize: 10, bold: true } },
                            { index: 2, value: this.props.SelectedDevice.DeviceID },
                        ]
                    },
                    {
                        index: 3,
                        cells: [
                            { index: 1, value: this.props.intl.formatMessage({ id: 'device_sn' }), style: { fontColor: '#0a0a0a', fontSize: 10, bold: true } },
                            { index: 2, value: this.props.SelectedDevice.DeviceSN },
                        ]
                    },
                    {
                        index: 4,
                        cells: [
                            { index: 1, value: this.props.intl.formatMessage({ id: 'site' }), style: { fontColor: '#0a0a0a', fontSize: 10, bold: true } },
                            { index: 2, value: this.props.SelectedDevice.Site }
                        ]
                    },
                    {
                        index: 5,
                        cells: [
                            { index: 1, value: this.props.intl.formatMessage({ id: 'department' }), style: { fontColor: '#0a0a0a', fontSize: 10, bold: true } },
                            { index: 2, value: this.props.SelectedDevice.Department },
                        ]
                    },
                    {
                        index: 6,
                        cells: [
                            { index: 1, value: this.props.intl.formatMessage({ id: 'model' }), style: { fontColor: '#0a0a0a', fontSize: 10, bold: true } },
                            { index: 2, value: this.props.SelectedDevice.Model },
                        ]
                    },
                    {
                        index: 7,
                        cells: [
                            { index: 1, value: this.props.intl.formatMessage({ id: 'temperature' }), style: { fontColor: '#0a0a0a', fontSize: 10, bold: true } },
                            { index: 2, value: '°' + this.props.UmTemperature },
                        ]
                    },
                ]
            },

            fileName: fileName + '.xlsx',
            includeHiddenColumn: false,

        };

    }

    gridChart() {

        let columns = [
            { field: 'Id', visible: false, isPrimaryKey: true },
            { field: 'DateTime', headerText: this.props.intl.formatMessage({ id: 'date' }), type: 'datetime', format: { type: 'dateTime', skeleton: 'short' } },
            { field: 'TFridge', headerText: this.props.intl.formatMessage({ id: 'tfridge' }), visible: true },
            { field: 'TOven', headerText: this.props.intl.formatMessage({ id: 'toven' }), visible: true },
            { field: 'TProbe', headerText: this.props.intl.formatMessage({ id: 'tprobe' }), visible: true },
            { field: 'CycleNumber', headerText: this.props.intl.formatMessage({ id: 'cyclenumber' }), visible: true },
            { field: 'CycleTime', headerText: this.props.intl.formatMessage({ id: 'cycletime' }), visible: true },
        ];
        return (
            <div>
                <GridComponent
                    id='exportExcelChart' ref={grid => this.gridInstance = grid}
                    dataSource={this.chartInstance?.dataSource}
                    allowExcelExport={true}
                    columns={columns} >
                    <Inject services={[ExcelExport]} />
                </GridComponent>
            </div>
        );
    }

    filterDateTemperaturesHistory(direction) {
        if (this.datepickerInstance !== undefined)
            if (this.datepickerInstance.value !== undefined) {
                if (this.datepickerInstance.value !== null) {
                    if (direction === 0)
                        this.datepickerInstance.value = new Date(new Date().toDateString());
                    this.props.FilterTemperaturesHistory(this.props.SelectedDevice.Id, this.datepickerInstance.value);
                } else {
                    this.datepickerInstance.value = new Date(new Date().toDateString());

                    if (direction !== 0)
                        this.datepickerInstance.value.setDate(this.datepickerInstance.value.getDate() + direction);

                    this.props.FilterTemperaturesHistory(this.props.SelectedDevice.Id, this.datepickerInstance.value);
                }

            }
    }

    changeDateTemperaturesHistory(direction) {
        let newDate = this.datepickerInstance.value;

        if (newDate === null)
            newDate = new Date(new Date().toDateString());

        let cloneListPrev = this.props?.DataDetailHistorySummary.filter(d => new Date(d.DateTime) < newDate);
        let cloneListnext = this.props?.DataDetailHistorySummary.filter(d => new Date(d.DateTime) > newDate);

        if (cloneListPrev.length < 1)
            this.setState({ enabledPrevHistory: false });
        else this.setState({ enabledPrevHistory: true });

        if (cloneListnext.length < 1)
            this.setState({ enabledNextHistory: false });
        else this.setState({ enabledNextHistory: true });

        if ((direction === -100) || (direction === 100)) {
            let listDateTime = this.props?.DataDetailHistorySummary.filter(d => direction === -100 ? new Date(d.DateTime) < newDate : new Date(d.DateTime) > newDate);
            if (listDateTime.length !== 0)
                newDate = direction === -100 ? new Date(listDateTime[listDateTime.length - 1].DateTime) : new Date(listDateTime[0].DateTime);
            this.datepickerInstance.value = newDate;

            if (cloneListPrev.length === 1)
                this.setState({ enabledPrevHistory: false });
            else this.setState({ enabledPrevHistory: true });

            if (cloneListnext.length === 1)
                this.setState({ enabledNextHistory: false });
            else this.setState({ enabledNextHistory: true });
        }
        else {
            newDate.setDate(newDate.getDate() + direction);
            this.datepickerInstance.element.value = this.new_intl.formatDate(new Date(newDate), { format: this.state.FormatDate });
            this.datepickerInstance.navigateTo(newDate);
            this.filterDateTemperaturesHistory(direction);
        }
    }

    renderChartControlHeader() {
        let t = this.props.intl.formatMessage({ id: 'chart_history' });
        if (this.props.Realtime) {
            t = this.props.intl.formatMessage({ id: 'chart_realtime' });
            return (
                <div id='divChartControlHeader' className='row'>
                    <div className='col-xs-12 col-lg-9 col-md-9'>
                        <span className='lblValue'>{t}</span>
                    </div>
                    <div className='col-xs-12 col-lg-3 col-md-3'>
                    </div>
                </div>
            );
        }

        if (!this.props.Realtime)
            return (
                <div id='divChartControlHeader' className='row'>
                    <div className='col-xs-12 col-lg-9 col-md-9'>
                        <span className='lblValue'>{t}</span>
                    </div>
                    {this.ENABLE_CROSSHAIR ? <div className='col-xs-12 col-lg-3 col-md-3' id='divBtnCrosshair'>
                        <TooltipComponent position='TopCenter' content={this.props.intl.formatMessage({ id: 'crosshair' })} target='#btnCrosshair'>
                            <ButtonComponent id='btnCrosshair' className='ButtonEmpty' onClick={() => this.changeCrosshair()} iconCss='e-icons e-add-new' cssClass='tooltipElement'></ButtonComponent>
                        </TooltipComponent>
                    </div> : null}
                </div>
            );
    }

    renderFilterTitle() {
        if (!this.props.Realtime)
            return (
                <span className='lblDescription'><FormattedMessage id='date' /></span>
            );
    }

    customDatesSummary(args) {
        let dateSummary = this.props.DataDetailHistorySummary.filter(summary => new Date(summary.DateTime).getTime() == new Date(args.date).getTime());

        if (dateSummary.length == 1) {
            args.element.headers = 'ppp';
            let spanData = document.createElement('span');
            if (dateSummary[0].HasData === 1)
                spanData.setAttribute('class', 'e-icons highlight data');
            args.element.appendChild(spanData);

            let spanAlarm = document.createElement('span');
            if (dateSummary[0].HasAlarms === 1)
                spanAlarm.setAttribute('class', 'e-icons highlight alarm');
            args.element.appendChild(spanAlarm);

            let spanImpacts = document.createElement('span');
            if (dateSummary[0].HasImpacts === 1)
                spanImpacts.setAttribute('class', 'e-icons highlight impacts');
            args.element.appendChild(spanImpacts);

        }
    }

    renderChartDateTimePicker() {
        if (!this.props.Realtime)
            return (
                <div>
                    <DatePickerComponent id='dataFilter'
                        ref={dp => this.datepickerInstance = dp}
                        width='200px' format={this.state.FormatDate}
                        allowEdit={false}
                        showTodayButton={false}
                        cssClass='e-customStyle'
                        change={this.filterDateTemperaturesHistory.bind(this)}
                        renderDayCell={this.customDatesSummary.bind(this)}
                    />
                </div>
            );
    }

    renderChartButtons() {

        let btnEnabledPrevHistory;
        let btnEnabledNextHistory;
        if (this.state.enabledPrevHistory) { btnEnabledPrevHistory = 'enable' } else btnEnabledPrevHistory = 'disable';
        if (this.state.enabledNextHistory) { btnEnabledNextHistory = 'enable' } else btnEnabledNextHistory = 'disable';


        if (!this.props.Realtime)
            return (

                <div className='row'>
                    <TooltipComponent position='TopCenter' opensOn='Hover' content={this.props.intl.formatMessage({ id: 'prev_data' })} >
                        <ButtonComponent className='tooltiphover' id={'btnPrevData' + btnEnabledPrevHistory} disabled={!this.state.enabledPrevHistory} onClick={() => this.changeDateTemperaturesHistory(-100)} iconCss='e-icons e-Data_Prev' cssClass='tooltipElement' ></ButtonComponent>
                    </TooltipComponent>
                    <TooltipComponent position='TopCenter' content={this.props.intl.formatMessage({ id: 'prev_day' })} target='#btnPrevChart'>
                        <ButtonComponent id='btnPrevChart' onClick={() => this.changeDateTemperaturesHistory(-1)} iconCss='e-icons e-Chart_Prev' />
                    </TooltipComponent>
                    <ButtonComponent id='btnToday' className='Button' onClick={() => this.changeDateTemperaturesHistory(0)} ><FormattedMessage id='today' /></ButtonComponent>
                    <TooltipComponent position='TopCenter' content={this.props.intl.formatMessage({ id: 'next_day' })} target='#btnNextChart'>
                        <ButtonComponent id='btnNextChart' onClick={() => this.changeDateTemperaturesHistory(+1)} iconCss='e-icons e-Chart_Next' />
                    </TooltipComponent>
                    <TooltipComponent position='TopCenter' opensOn='Hover' content={this.props.intl.formatMessage({ id: 'next_data' })} >
                        <ButtonComponent className='tooltiphover' id={'btnNextData' + btnEnabledNextHistory} disabled={!this.state.enabledNextHistory} onClick={() => this.changeDateTemperaturesHistory(+100)} iconCss='e-icons e-Data_Next' cssClass='tooltipElement' ></ButtonComponent>
                    </TooltipComponent>
                </div>

            );
    }

    changeCrosshair() {
        let tmp = this.state.enableCrosshair;
        this.setState({ enableCrosshair: !tmp });
    }

    renderChartControlBottom() {
        return (
            <div id='divChartControlBottom' className='row'>
                <div className='col-md-1'>
                    {this.renderFilterTitle()}
                </div>
                <div className='col-md-4'>
                    {this.renderChartDateTimePicker()}
                </div>
                <div className='col-md-4'>
                    {this.renderChartButtons()}
                </div>
                <div className='col-md-3'>
                    {(window.innerWidth >= this.commonData.getMobileWidth()) && <DropDownButtonComponent id='btnExportChart' className='Button'
                        ref={dd => this.dropdown = dd}
                        items={this.items}
                        fields={this.fields}
                        select={this.handleExport.bind(this)}>
                        <FormattedMessage id='export' />
                    </DropDownButtonComponent>}

                </div>
            </div>
        )
    }

    contentFullScreen() {
        return React.cloneElement(this.chartInstance);
    }

    renderBands() {
        if (this.chartInstance?.primaryXAxis) {
            let dataCycleBands = this.props?.DataDetailHistory?.DataHistoryCycleBands;
            let dataAlarmBands = this.props.DataDetailHistory.DataHistoryAlarmBands;
            let dataStatusBands = this.props.DataDetailHistory.DataHistoryStatusBands;
            let dataTemperature = this.props?.DataDetailHistory?.DataHistoryTemperature;
            let colorStatus;
            let bands = new Array();

            if (dataTemperature?.filter(t => t.TFridge !== null || t.TOven !== null || t.TProbe !== null)?.length > 0) {
                if (dataCycleBands) {

                    for (let i = 0; i < dataCycleBands.length; i++)
                        bands.push({
                            start: new Date(dataCycleBands[i].Start),
                            end: new Date(dataCycleBands[i].End),
                            textStyle: { size: 14, fontFamily: 'Roboto' },
                            opacity: 0.5,
                            color: '#ffe72f',
                            text: this.props.intl.formatMessage({ id: 'cycle' }) + ' ' + dataCycleBands[i].Text
                        });

                }

                if (dataAlarmBands) {
                    for (let j = 0; j < dataAlarmBands.length; j++)
                        bands.push({
                            start: new Date(dataAlarmBands[j].Start),
                            end: new Date(dataAlarmBands[j].End),
                            dashArray: '9,5',
                            sizeType: 'Pixel',
                            color: 'red',
                            visible: true,
                            textStyle: { size: 14, fontFamily: 'Roboto', color: 'red' },
                            verticalAlignment: 'Start',
                            horizontalAlignment: 'End',
                            text: this.props.intl.formatMessage({ id: 'alarm' }) + ' ' + dataAlarmBands[j].Text
                        });

                }
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {


                    if (dataStatusBands)
                        for (let l = 0; l < dataStatusBands.length; l++) {
                            if (dataStatusBands[l].Status === 0) colorStatus = 'grey';
                            if (dataStatusBands[l].Status === 2) colorStatus = '#8cc98c';

                            bands.push({
                                start: new Date(dataStatusBands[l].Start),
                                end: new Date(dataStatusBands[l].End),
                                textStyle: { size: 14, fontFamily: 'Roboto' },
                                opacity: 0.5,
                                color: colorStatus,
                            });
                        }
                }

                this.chartInstance.primaryXAxis.stripLines = bands;
                this.chartInstance.primaryYAxis.stripLines = null;


            } else {
                bands.push({
                    start: -15,
                    end: 290,
                    opacity: 0.5,
                    color: 'black',
                    visible: true,
                    textStyle: { size: 20, fontFamily: 'Roboto', color: '#ffe72f' },
                    text: this.props.intl.formatMessage({ id: 'no_data' })
                });

                this.chartInstance.primaryYAxis.stripLines = bands;
                this.chartInstance.primaryXAxis.stripLines = null;
            }


        }

    }

    tooltipRender(args) {
        let y = args.data.pointY;
        let text;
        let type = args.series.typeSeries;

        if (type === 2) {
            text = this.props.intl.formatMessage({ id: 'impacts_event_type_' + args.series.zName })
            if (args.series.zName === '1')
                args.text = text + ' : ' + y + '°';
            else
                args.text = text + ' : ' + y;
        }
        if (type === 1) {
            text = args.text;
            args.text = text;
        }
    }

    render() {

        /*
        let dsImpacts = this.props?.DataDetailHistory?.DataHistoryImpactBand;
        let nameEventType = [];
        if (dsImpacts) {
            for (let i = 0; i < dsImpacts.length; i++) {
                if (dsImpacts[i].EventType === '1' || dsImpacts[i].EventType === '2')
                    nameEventType = dsImpacts[i].EventType;
            }
        }
        */

        let minT = -10;
        let maxT = 150;
        if (this.props?.IsEvo)
            maxT = 190;
        if (this.props.UmTemperature === 'F') {
            maxT = convertFahrenheit(maxT);
            minT = convertFahrenheit(minT);
        }

        return (
            <div id='divChartControl'>
                <span>{this.state.enableCrosshair}</span>
                {this.renderChartControlHeader()}
                <div>
                    <ChartComponent id='chartControl'
                        palettes={this.palette}
                        style={{ textAlign: 'center' }}
                        legendSettings={{
                            visible: true, position: 'Bottom', location: { x: 10, y: 20 }, textStyle: { fontFamily: 'Roboto' }, padding: 11, shapePadding: 15, border: {
                                width: 1, color: 'grey'
                            }, width: '400', enablePages: true
                        }}
                        legendShape='Rectangle'
                        crosshair={{ enable: this.state.enableCrosshair }}
                        enableAnimation={false}
                        ref={chart => this.chartInstance = chart}
                        primaryXAxis={{
                            valueType: 'DateTime',
                            intervalType: 'Minutes',
                            labelFormat: 'HH:mm',
                            crosshairTooltip: { enable: this.state.enableCrosshair, textStyle: { fontFamily: 'Roboto' } },
                            labelStyle: { fontFamily: 'Roboto' }
                        }}
                        chartArea={{ border: { width: 0 } }} primaryYAxis={{
                            labelFormat: '{value}°' + this.props.UmTemperature,
                            minimum: minT,
                            maximum: maxT,
                            lineStyle: { width: 0 },
                            majorTickLines: { width: 0 },
                            minorTickLines: { width: 0 },
                            crosshairTooltip: { enable: this.state.enableCrosshair, textStyle: { fontFamily: 'Roboto' } },
                            labelStyle: { fontFamily: 'Roboto' }
                        }}
                        tooltip={{ enable: true }}
                        tooltipRender={this.tooltipRender.bind(this)}
                        zoomSettings={{
                            enableMouseWheelZooming: false, enablePinchZooming: false,
                            enableSelectionZooming: true, mode: 'X', enableScrollbar: true
                        }}>
                        <Inject services={[LineSeries, Legend, ScatterSeries, Crosshair, Category, DateTime, Export, Tooltip, Zoom, DateTimeCategory, StripLine]} />
                        <SeriesCollectionDirective>
                            <SeriesDirective xName='DateTime' yName='TOven' legendShape='HorizontalLine' width={3} name={this.props.intl.formatMessage({ id: 'toven' })} typeSeries={1} color='blue' type='Line' marker={{ visible: true, width: 1, height: 1 }}>
                            </SeriesDirective>
                            <SeriesDirective xName='DateTime' yName='TFridge' legendShape='HorizontalLine' width={3} name={this.props.intl.formatMessage({ id: 'tfridge' })} typeSeries={1} color='red' type='Line' marker={{ visible: true, width: 1, height: 1 }}>
                            </SeriesDirective>
                            <SeriesDirective xName='DateTime' yName='TProbe' legendShape='Circle' width={3} opacity={0} name={this.props.intl.formatMessage({ id: 'tprobe' })} typeSeries={1} color='green' type='Line' marker={{ visible: true, width: 4, height: 4, fill: 'green' }}>
                            </SeriesDirective>
                            {/*<SeriesDirective xName='End' yName='Value' zName={nameEventType} dataSource={dsImpacts} legendShape='Circle' typeSeries={2} name={this.props.intl.formatMessage({ id: 'menueventsheader.impacts' })} color='orange' type='Scatter' marker={{ visible: true, width: 2, height: 15 }}>
                            </SeriesDirective>
                            <SeriesDirective xName='DateTime' yName='status' legendShape='Rectangle' width={2} name='statusOFF' color='grey' type='Line'>
                            </SeriesDirective>
                            <SeriesDirective xName='DateTime' yName='status' legendShape='Rectangle' name='statusON' color='#8cc98c' type='Line'>
                            </SeriesDirective>*/}
                            <SeriesDirective xName='DateTime' yName='cycle' legendShape='Rectangle' width={3} name='In cycle' type='Line' marker={{ visible: true, width: 1, height: 1 }}>
                            </SeriesDirective>
                            <SeriesDirective xName='DateTime' yName='Alarm' legendShape='VerticalLine' width={3} name='Alarm' color='red' type='Line' marker={{ visible: true, width: 1, height: 1 }}>
                            </SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </div>

                {this.renderChartControlBottom()}
                <div id='divHideGrid'>{this.gridChart()}</div>
            </div>
        );

    }

}


ChartControl.propTypes = {
    Realtime: PropTypes.bool,
    IsEvo: PropTypes.bool,
    SelectedDevice: PropTypes.object,
    DataDetailHistory: PropTypes.object,
    DataDetailHistorySummary: PropTypes.any,
    intl: PropTypes.object,
    FilterTemperaturesHistory: PropTypes.func,
    UmTemperature: PropTypes.string
};

export default injectIntl(ChartControl);