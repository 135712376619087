import React from 'react';
import LoginPage from './LoginPage';
import AppLayout from './AppLayout';
import dotnetify from 'dotnetify';
import auth from '../auth';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    dotnetify.hubServerUrl = process.env.REACT_APP_URL_DOTNETIFY;
} 

dotnetify.hub.reconnectRetry = 10;
dotnetify.hub.reconnectDelay = [5, 30];
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Authenticated: auth.hasAccessToken()
        };
    }

    authenticate() {
        this.setState({ Authenticated: true });
    }


    render() {
        if (this.state.Authenticated)
                return (
                    <AppLayout />
                )

        return (
            <LoginPage Authenticate={this.authenticate.bind(this)} />
        )
    }
}

export default App;