import * as React from 'react';
import PropTypes from 'prop-types';
import dotnetify from 'dotnetify';
import '../styles/monitorPage.css';
import { GridComponent, Filter, Inject, Sort, Page, Reorder } from '@syncfusion/ej2-react-grids';
import BasePage from '../components/BasePage';
import SidebarManage from '../components/SidebarManage';

import { statusTemplate, statusTemplateIntl } from '../components/templates/StatusTemplate';
import { deviceIDTemplate } from '../components/templates/DeviceIDTemplate';
import { cycleTimeTemplate } from '../components/templates/CycleTimeTemplate';
import { tOvenTemplate, tFridgeTemplate } from '../components/templates/TemperatureTemplate';
import AvatarModel from '../components/model/AvatarModel';

import { setSpinner } from '@syncfusion/ej2-popups';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';
import { CheckBoxSelection, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import DialogNewDevice from '../components/DialogNewDevice.js';

import isEqual from 'react-fast-compare';

class MonitorPage extends React.Component {

    constructor(props) {
        super(props);

        this.fixedFields = ['Selected', 'Id', 'DeviceID', 'StatusValue'];
        //   this.toolbarOptions = ['ColumnChooser'];
        setSpinner({ template: '<div style="width:100%;height:100%" className="custom-rolling"><div></div></div>' });

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);
        this.commonData.subscribeTheme(this.updateTheme.bind(this));

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.localData = [];
        this.state = {
            Theme: this.commonData.getTheme(),
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            LicenseType: this.commonData.getLicenseType(),
            Data: undefined,
            DataRefresh: [],
            SidebarManageVisible: 0,
            SelectedDevices: [],
            SelectedRows: [],
            SidebarEnabled: !(window.innerWidth < this.commonData.getMobileWidth()),
            ChangePage: null,
            ColumnChooser: [],
            PersistMonitorData: null,
            ShowDialogNewDevice: false,
            ShowNewDeviceButton: false
        };

        this.vm = dotnetify.react.connect('MonitorVM', this);

        this.rt = this.rowTemplate.bind(this);

        this.filterSettings = { type: 'CheckBox', checkBoxOnly: 'true' };

        this.deviceIDTemplate = deviceIDTemplate.bind(this);
        this.statusTemplate = statusTemplate.bind(this);
        this.cycleTimeTemplate = cycleTimeTemplate.bind(this);
        this.tOvenTemplate = tOvenTemplate.bind(this);
        this.tFridgeTemplate = tFridgeTemplate.bind(this);
    }

    updateLanguage() {
        let commonData = CommonDataManager.getInstance();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
    }

    updateTheme() {
        this.setState({ Theme: this.commonData.getTheme() });
    }

    resizeEvent() {
        let columnSelected = this.gridInstance?.getColumnByField('Selected');

        if (columnSelected) {
            if (window.innerWidth < this.commonData.getMobileWidth()) {
                if (columnSelected !== undefined) columnSelected.visible = false;
                this.setState({ SidebarEnabled: false });
                this.clearSelection();
            }
            else {
                if (columnSelected !== undefined) columnSelected.visible = true;
                this.setState({ SidebarEnabled: true });
            }
        }
    }

    componentDidMount() {
        this.commonData.setCurrentView('MonitorPage');
        window.addEventListener('resize', this.resizeEvent.bind(this));

        this.setRouteInfoFilter(this.state?.RouteInfo);
    }

    componentWillUnmount() {
        this.vm?.$destroy();
        this.commonData?.unsubscribe(this.updateLanguage);
        this.commonData?.unsubscribeTheme(this.updateTheme);
        window.removeEventListener('resize', this.resizeEvent);
    }

    setRouteInfoFilter(arg) {
        if (!this.gridInstance) return;

        this.gridInstance.clearFiltering();

        if (arg === 'TotalDevices')
            this.gridInstance.removeFilteredColsByField('Status', 'CycleNumber');

        if (arg === 'ConnectedDevices')
            this.gridInstance.filterByColumn('Status', 'equal', [1, 2, 3, 4, 5, 6, 7, 202, 203]);


        if (arg === 'InAlarmDevices')
            this.gridInstance.filterByColumn('Status', 'equal', 4);


        if (arg === 'InCycleDevices')
            this.gridInstance.filterByColumn('CycleNumber', 'notequal', 0);


        if (arg === 'InDeliveringDevices')
            this.gridInstance.filterByColumn('Status', 'equal', 200);


        if (arg === 'InDistributionDevices')
            this.gridInstance.filterByColumn('Status', 'equal', 201);

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.PersistMonitorData === null)
            if (nextState.PersistMonitorData !== this.state.PersistMonitorData)
                window.localStorage.setItem('gridmonitorGrid', nextState.PersistMonitorData);

        return true;
    }

    componentDidUpdate(prevProps, prevState) {

        let idx = -1;

        this.umTemperature = this.commonData.getUmTemperature();
        this.Alarms = this.commonData.getAlarms();

        if (prevState.Theme !== this.state.Theme)
            this.gridInstance.refreshColumns();


        if (this.gridInstance !== undefined) {
            if (this.state.ColumnChooser.length === 0) {
                let cc = [];
                for (let i = 0; i < this.gridInstance.columns.length; i++) {
                    if (!this.fixedFields.includes(this.gridInstance.columns[i].field))
                        if (this.gridInstance.columns[i].columnChooser)
                            cc.push({ field: this.gridInstance.columns[i].field, headerText: this.gridInstance.columns[i].headerText });
                }
                if (cc.length !== 0)
                    this.setState({ ColumnChooser: cc });
            }
        }

        if (prevState.SitesPermissions !== this.state.SitesPermissions || prevState.DeparmentsPermissions !== this.state.DeparmentsPermissions) {
            this.usersPermissions();
        }

        if (prevState.Language !== this.state.Language) {
            let cc = [];
            for (let i = 0; i < this.gridInstance.columns.length; i++) {
                if (!this.fixedFields.includes(this.gridInstance.columns[i].field))
                    if (this.gridInstance.columns[i].columnChooser)
                        cc.push({ field: this.gridInstance.columns[i].field, headerText: this.intl.formatMessage({ id: this.gridInstance.columns[i].idText }) })
            }
            this.setState({ ColumnChooser: cc });
            this.gridInstance.refreshHeader();
        }

        if (prevState.RouteInfo !== this.state.RouteInfo) {
            this.setRouteInfoFilter(this.state.RouteInfo);
        }

        if (this.state?.Data !== null) {

            let bSetState = false;

            let tmpData = JSON.parse(JSON.stringify(this.state.Data));

            if (!isEqual(prevState?.DataRefresh, this.state?.DataRefresh) || (prevState.Language !== this.state.Language)) {

                for (let i = 0; i < this.state?.DataRefresh?.length; i++) {

                    idx = tmpData.findIndex(x => x.Id == this.state.DataRefresh[i].Id);
                    if (idx != -1) {

                        tmpData[idx].DeviceID = this.state.DataRefresh[i].DeviceID;
                        tmpData[idx].Status = this.state.DataRefresh[i].Status;
                        tmpData[idx].StatusValue = this.intl.formatMessage({ id: 'status_' + this.state.DataRefresh[i].Status, defaultMessage: ' ' });
                        tmpData[idx].TOven = this.state.DataRefresh[i].TOven;
                        tmpData[idx].TFridge = this.state.DataRefresh[i].TFridge;
                        tmpData[idx].TProbe = this.state.DataRefresh[i].TProbe;
                        tmpData[idx].DeviceDate = new Date(this.state.DataRefresh[i].DeviceDate);
                        tmpData[idx].CycleNumber = this.state.DataRefresh[i].CycleNumber;
                        tmpData[idx].CycleTime = this.state.DataRefresh[i].CycleTime;
                        tmpData[idx].Remotes = this.state.DataRefresh[i].Remotes;
                        tmpData[idx].Alarms = this.state.DataRefresh[i].Alarms;
                        tmpData[idx].InDeliveringDate = this.state.DataRefresh[i].InDeliveringDate;
                        tmpData[idx].InDistributionDate = this.state.DataRefresh[i].InDistributionDate;
                        tmpData[idx].DSPROId = this.state.DataRefresh[i].DSPROId;
                        tmpData[idx].DSPRODeviceID = this.state.DataRefresh[i].DSPRODeviceID;
                    }
                }

                bSetState = true;
            }

            if (!isEqual(prevState?.SelectedRows, this.state?.SelectedRows)) {

                for (let i = 0; i < tmpData?.length; i++) {
                    idx = this.state.SelectedRows.findIndex(x => x == this.state.Data[i].Id);

                    if (idx != -1)
                        tmpData[i].Selected = true;
                    else
                        tmpData[i].Selected = false;
                }

                bSetState = true;
            }

            if (bSetState)
                this.setState({ Data: tmpData, SelectedDevices: tmpData?.filter(item => item.Selected == true) });
        }

    }

    rowTemplate(props) {
        return (
            <tr className='templateRowMonitor'>
                <td className='deviceId'>
                    <AvatarModel Theme={this.state.Theme} ModelId={props.ModelId} DeviceVersion={props.DeviceVersion} />
                    <span>{props.DeviceID}</span>
                </td>
                <td className='deviceDetails'>
                    <table className='deviceText' cellPadding={3} cellSpacing={2}>
                        <colgroup>
                            <col style={{ width: '30%' }} />
                            <col style={{ width: '70%' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td><b>{this.intl.formatMessage({ id: 'site' })}</b></td>
                                <td className='templateMobileText'>{props.Site}</td>
                            </tr>
                            <tr>
                                <td><b>{this.intl.formatMessage({ id: 'status' })}</b></td>
                                <td className='templateMobileText'>{statusTemplateIntl(props, this.intl)}</td>
                            </tr>
                            <tr>
                                <td><b>{this.intl.formatMessage({ id: 'department' })}</b></td>
                                <td className='templateMobileText'>{props.Department}</td>

                            </tr>
                            <tr>
                                <td><b>{this.intl.formatMessage({ id: 'cycletime' })}</b></td>
                                <td className='templateMobileText'>{cycleTimeTemplate(props)}</td>
                            </tr>
                            <tr>
                                <td><b>{this.intl.formatMessage({ id: 'toven' })}</b></td>
                                <td className='templateMobileText'>{tOvenTemplate(props)}</td>
                            </tr>
                            <tr>
                                <td><b>{this.intl.formatMessage({ id: 'tfridge' })}</b></td>
                                <td className='templateMobileText'>{tFridgeTemplate(props)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td className='tdButtonDetail'><ButtonComponent onClick={this.cellSelectedMonitorGridMobile.bind(this, props)}>{this.intl.formatMessage({ id: 'details' })}</ButtonComponent></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    }

    handleCloseMultiSelect() {
        for (let i = 0; i < this.gridInstance.columns.length; i++) {
            if (!this.fixedFields.includes(this.gridInstance.columns[i].field))
                this.gridInstance.columns[i].visible = this.multiselect.value.includes(this.gridInstance.columns[i].field);
        }

        // vm dispatch salva stato griglia
        if (this.gridInstance !== undefined) this.gridInstance.refresh();
        this.vm.$dispatch({ PersistMonitorGrid: this.gridInstance.getPersistData() });
    }

    handleOpenMultiSelect() {
        let Checkbox = [];
        for (let i = 0; i < this.gridInstance.columns.length; i++) {
            if (!this.fixedFields.includes(this.gridInstance.columns[i].field))
                if (this.gridInstance.columns[i].visible)
                    Checkbox.push(this.gridInstance.columns[i].field);
        }
        this.multiselect.value = Checkbox;
    }

    selectAll() {
        let newSelectedRows = [];
        for (let i = 0; i < this.gridInstance.currentViewData.length; i++) {
            this.gridInstance.currentViewData[i].Selected = true;
            newSelectedRows.push(this.gridInstance.currentViewData[i].Id);
        }

        this.gridInstance.refresh();
        this.setState({ SelectedRows: newSelectedRows, SidebarManageVisible: 2 });
    }

    unSelectAll() {
        this.setState({ SelectedRows: [], SidebarManageVisible: 0 });

        for (let i = 0; i < this.gridInstance.currentViewData.length; i++)
            this.gridInstance.setCellValue(this.gridInstance.currentViewData[i].Id, 'Selected', false);
    }

    customizeCell(args) {
        if (this.state.LicenseType > 1) {
            if (args.data['ModelId'] === 0 && args.data['DeviceVersion'] === 'v2') {
                if (args.column.field === 'TOven' || args.column.field === 'TFridge') {
                    (args.cell).style.backgroundColor = '#fafafa';
                }
            }
        }
    }

    usersPermissions() {
        let length_managePermissions = [];
        if (this.state.SitesPermissions !== undefined || this.state.DeparmentsPermissions !== undefined) {
            let usersPermissions = this.state?.SitesPermissions.concat(this.state?.DeparmentsPermissions);
            for (let i = 0; i < usersPermissions.length; i++) {
                if (usersPermissions[i].PermissionManage === true)
                    length_managePermissions.push({ permission: usersPermissions[i].PermissionManage })
            }

            if (length_managePermissions.length > 0) {
                this.setState({ ShowNewDeviceButton: true });
            }
            else
                this.setState({ ShowNewDeviceButton: false });

        }
    }

    openDialogNewTroyller() {
        this.setState({ ShowDialogNewDevice: true });
    }

    closeDialogNewTroyller() {
        this.setState({ ShowDialogNewDevice: false });
    }

    renderDialogNewDevice() {
        if (this.state.ShowDialogNewDevice) {
            return (
                <DialogNewDevice
                    ListDepartments={this.state.DeparmentsPermissions}
                    ListSites={this.state.SitesPermissions}
                    Close={this.closeDialogNewTroyller.bind(this)}
                    Data={this.state.Data}
                />
            );
        }
    }

    viewAllTrolleys() {
        this.vm.$dispatch({ ShowAllDevices: '' });
    }

    gridTemplate() {

        let columnsMobile = [
            { headerText: 'ID', visible: false, isPrimaryKey: true },
            { headerText: this.intl.formatMessage({ id: 'id_trolley' }), width: '30%', allowFiltering: true },
            { headerText: this.intl.formatMessage({ id: 'details' }), allowFiltering: false, allowSorting: false, width: '70%' },

        ];

        let columns = [
            { field: 'Selected', headerText: '', displayAsCheckBox: true, width: '40px', allowFiltering: false, allowSorting: false, allowReordering: false, columnChooser: false, customAttributes: { class: 'selected' } },
            { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false, columnChooser: false },
            { field: 'DeviceID', headerText: this.intl.formatMessage({ id: 'id_trolley' }), idText: 'id_trolley', columnChooser: true, template: this.deviceIDTemplate },
            { field: 'Model', headerText: this.intl.formatMessage({ id: 'model' }), idText: 'model', columnChooser: true, hideAtMedia: '(min-width:800px)' },
            { field: 'Site', headerText: this.intl.formatMessage({ id: 'site' }), idText: 'site', columnChooser: true },
            { field: 'Department', headerText: this.intl.formatMessage({ id: 'department' }), idText: 'department', columnChooser: true },
            { field: 'StatusValue', headerText: this.intl.formatMessage({ id: 'status' }), idText: 'status', columnChooser: true, template: this.statusTemplate },
            { field: 'CycleTime', headerText: this.intl.formatMessage({ id: 'cycletime' }), idText: 'cycletime', columnChooser: true, template: this.cycleTimeTemplate, textAlign: 'Center', hideAtMedia: '(min-width:500px)' },
            { field: 'TOven', headerText: this.intl.formatMessage({ id: 'toven' }), idText: 'toven', columnChooser: true, clipMode: 'Clip', template: this.tOvenTemplate, textAlign: 'Center', hideAtMedia: '(min-width:500px)' },
            { field: 'TFridge', headerText: this.intl.formatMessage({ id: 'tfridge' }), idText: 'tfridge', columnChooser: true, clipMode: 'Clip', template: this.tFridgeTemplate, textAlign: 'Center', hideAtMedia: '(min-width:500px)' },
            { field: 'CycleNumber', headerText: '', visible: false, columnChooser: false },
            { field: 'Status', headerText: '', visible: false, columnChooser: false },
        ];

        let IsDeveloper = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

        if (this.state.PersistMonitorData === null)
            return;

        if (window.innerWidth < this.commonData.getMobileWidth()) {
            return (
                <div>
                    <GridComponent id='monitorGridMobile' ref={grid => this.gridInstance = grid}
                        allowFiltering={true} allowPaging={true} pageSettings={{ pageSize: 30 }}
                        filterSettings={this.filterSettings}
                        dataSource={this.state.Data}
                        enableToggle={false} columns={columnsMobile}
                        allowSelection={true}
                        allowSorting={true}
                        height={(this.state?.Data?.length * 205) + 'px'}
                        rowTemplate={this.rt}>

                        <Inject services={[Filter, Sort, Page]} />
                    </GridComponent>

                </div>
            );
        }
        else
            return (
                <div>
                    <div className='row' id='divMultiSelectColumns' >
                        <div className='col-xs-4 col-lg-6 col-md-6' id='divBtnMonitorpage'>
                            <ButtonComponent id='btnSelectAll' onClick={this.selectAll.bind(this)}><FormattedMessage id='select_all' /></ButtonComponent>
                            <ButtonComponent id='btnUnSelectAll' onClick={this.unSelectAll.bind(this)}><FormattedMessage id='unSelect_all' /></ButtonComponent>
                        </div>
                        {IsDeveloper ?
                            <div className='col-xs-4 col-lg-4 col-md-4' id='divNewDevice'>
                                {this.state.ShowNewDeviceButton && <ButtonComponent id={ this.state.AllDevicesVisible ? 'btnAllDevicesVisible' : 'btnAllDevicesNotVisible' } onClick={this.viewAllTrolleys.bind(this)}><FormattedMessage id='viewAllTrolleys' /></ButtonComponent>}
                                {this.state.ShowNewDeviceButton && <ButtonComponent id='btnNewDevice' iconCss='e-icons e-add-newDevice' onClick={this.openDialogNewTroyller.bind(this)}><FormattedMessage id='new_device' /></ButtonComponent>}
                            </div> : null}
                        <div className='col-xs-4 col-lg-2 col-md-2' id='multiSelectColumns'>
                            <MultiSelectComponent
                                ref={ms => this.multiselect = ms}
                                fields={{ value: 'field', text: 'headerText' }}
                                dataSource={this.state.ColumnChooser}
                                mode='CheckBox'
                                showDropDownIcon={true} allowFiltering={false}
                                allowCustomValue={false}
                                open={this.handleOpenMultiSelect.bind(this)}
                                close={this.handleCloseMultiSelect.bind(this)}
                                selectAllText={this.intl.formatMessage({ id: 'select_all' })}
                                unSelectAllText={this.intl.formatMessage({ id: 'unSelect_all' })}
                                showSelectAll={true}
                                placeholder={this.intl.formatMessage({ id: 'columns' })}>
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                    </div>
                    <GridComponent id='monitorGrid' ref={grid => this.gridInstance = grid}
                        dataSource={this.state.Data}
                        enableHover={true} enableToggle={false} allowFiltering={true} allowPaging={true} pageSettings={{ pageSize: 30 }}
                        filterSettings={this.filterSettings} columns={columns}
                        selectionSettings={{ mode: 'Both' }}
                        allowSorting={true} allowSelection={true}
                        cellSelected={this.cellSelectedMonitorGrid.bind(this)}
                        allowReordering={true} enablePersistence={true}
                        queryCellInfo={this.customizeCell.bind(this)}
                        actionComplete={this.dataStateChangeMonitorGrid.bind(this)}>

                        <Inject services={[Filter, Sort, Page, Reorder]} />

                    </GridComponent>

                </div>
            );
    }



    cellSelectedMonitorGrid(e) {
        let checkboxSelect = (e.cellIndex.cellIndex == 0);
        let selectedId = -1;
        if (this.gridInstance.getSelectedRecords().length != 0)
            selectedId = this.gridInstance.getSelectedRecords()[0].Id;
        let newSelectedRows = this.state.SelectedRows.slice();
        let sidebarVisible = 0;

        if (checkboxSelect) {
            newSelectedRows = this.state.SelectedRows.slice();
            if (newSelectedRows.filter(item => item == selectedId).length != 0)
                newSelectedRows = newSelectedRows.filter(item => item != selectedId);
            else
                newSelectedRows.push(selectedId);
        }
        else {
            if (newSelectedRows.length == 0)
                newSelectedRows.push(selectedId);
            else {
                if (newSelectedRows.length == 1) {
                    if (newSelectedRows.filter(item => item == selectedId).length == 0) {
                        newSelectedRows = [];
                        newSelectedRows.push(selectedId);
                    }
                    else
                        newSelectedRows = [];
                }
                else
                    return;
            }
        }

        if (checkboxSelect) {
            if (newSelectedRows.length == 0)
                sidebarVisible = 0;
            else
                sidebarVisible = 1;
        }
        else {
            if (newSelectedRows.length == 0)
                sidebarVisible = 0;

            if (newSelectedRows.length == 1)
                sidebarVisible = 2;
        }

        if (!this.state.SidebarEnabled)
            sidebarVisible = 0;

        this.setState({
            SelectedRows: newSelectedRows,
            SidebarManageVisible: sidebarVisible
        });

    }

    cellSelectedMonitorGridMobile(e) {
        this.setState({
            SelectedRows: [e.Id],
            SidebarManageVisible: 2
        });
    }

    clearSelection() { this.setState({ SelectedRows: [], SidebarManageVisible: 0 }); }

    dataStateChangeMonitorGrid(e) {

        if (e.requestType === 'reorder' || e.requestType === 'filtering' || e.requestType === 'sorting') {
            this.vm.$dispatch({ PersistMonitorGrid: this.gridInstance.getPersistData() });
        }

        if (e.requestType == 'paging')
            if (this.state.ChangePage != 0) {

                let pageRows = this.gridInstance.getCurrentViewRecords();
                let newSelectedRows = [];

                if (this.state.ChangePage == -1)
                    newSelectedRows.push(pageRows[pageRows.length - 1].Id);


                if (this.state.ChangePage == +1)
                    newSelectedRows.push(pageRows[0].Id);

                this.setState({
                    SelectedRows: newSelectedRows,
                });

            }
    }

    changeDevice(direction) {

        if (this.state.SelectedRows.length != 1)
            return;

        let currentPage = this.gridInstance.pageSettings.currentPage;
        let newCurrentPage = currentPage;
        let pageCount = this.gridInstance.pageSettings.pageCount - 1;

        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state.SelectedDevices[0]);

        let rowHeight = this.gridInstance.getRows()[selectedRowIndex].scrollHeight;
        window.scrollBy({ top: rowHeight * direction, behavior: 'smooth' });

        if (direction == -1)
            selectedRowIndex--;

        if (direction == 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) {
            newCurrentPage--;
            if (newCurrentPage == 0)
                newCurrentPage = pageCount;
        }

        if (selectedRowIndex > pageRows.length - 1) {
            newCurrentPage++;
            if (newCurrentPage > pageCount)
                newCurrentPage = 1;
        }

        if (newCurrentPage != currentPage) {
            this.gridInstance.goToPage(newCurrentPage);
            this.setState({ ChangePage: direction });
            return;
        }

        let newSelectedRows = [];
        newSelectedRows.push(pageRows[selectedRowIndex].Id);

        this.setState({
            SelectedRows: newSelectedRows,
            ChangePage: 0
        });
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divMonitor'>
                    <div id='divMonitorAlert' />
                    <BasePage title={this.intl.formatMessage({ id: 'menuheader.trolleys' })} navigation={this.intl.formatMessage({ id: 'menuheader.trolleys' })}></BasePage>
                    <div id='divMonitorGrid'>
                        {this.gridTemplate()}
                        {this.renderDialogNewDevice()}
                        <SidebarManage
                            SidebarManageVisible={this.state.SidebarManageVisible}
                            SelectedDevices={this.state.SelectedDevices}
                            ClearSelection={this.clearSelection.bind(this)}
                            ChangeDevice={this.changeDevice.bind(this)}
                            SitesPermissions={this.state.SitesPermissions}
                            DeparmentsPermissions={this.state.DeparmentsPermissions}
                            UmTemperature={this.umTemperature}
                            Option='MonitorPage'
                            Theme={this.state.Theme}
                            Alarms={this.Alarms}
                            LicenseType={this.state.LicenseType}
                        >
                        </SidebarManage>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

MonitorPage.propTypes = {
    selectDashboard: PropTypes.string
};

export default MonitorPage;