import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/sidebarSetupConfigurations.css';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import dotnetify from 'dotnetify';
import MenuConfigurations from './manage/MenuConfigurations';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import Toast from '../components/Toast';
import DialogAlert from '../components/DialogAlert';

import { createIntl, createIntlCache, injectIntl, FormattedMessage } from 'react-intl';

import CommonDataManager from '../components/CommonDataManager';
import { L10n, setCulture } from '@syncfusion/ej2-base';

class SidebarSetupConfigurations extends React.Component {

    constructor(props) {
        super(props);

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        this.vm = dotnetify.react.connect('ConfigurationDetailVM', this);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            Notification: [],
            EnabledBtns: true,
            showDialogAlert: false,
            visibleMenu: false
        };
    }

    componentWillUnmount() {
        this.vm.$destroy();
    }

    updateLanguage() {
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: this.commonData.getLanguage(), Messages: this.commonData.getMessages() });
    }

    menuSelected(enable) {
        this.setState({ EnabledBtns: enable })
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.showDialogAlert !== this.state.showDialogAlert)
            if (this.state.showDialogAlert) {

                let msg='<div>' + this.props.intl.formatMessage({ id: 'configuration_delete' }) + '</div>';
                msg= msg + '<div>' +this.state?.ConfigurationDetail?.Name+ ' ?' + '</div>';

                ReactDOM.render(<DialogAlert
                    intl={this.props.intl}
                    AlertType={2}
                    Title={this.props.intl.formatMessage({ id: 'warning' })}
                    Message={msg}
                    Cancel={this.hideAlert.bind(this)}
                    Confirm={this.confirm.bind(this)} />, document.querySelector('#divSetupConfigurationAlert'));

            }
            else {
                ReactDOM.unmountComponentAtNode(document.querySelector('#divSetupConfigurationAlert'));
            }

        if (prevProps.SidebarSetupConfigurationsVisible !== this.props.SidebarSetupConfigurationsVisible) {
            this.setState({ LocalSidebarSetupConfigurationsVisible: this.props.SidebarSetupConfigurationsVisible });
        }

        if (prevState.ConfigurationTypes !== this.state.ConfigurationTypes) {
            let types = this.state?.ConfigurationTypes;

            for (let i = 0; i < types.length; i++)
                types[i].Description = this.intl.formatMessage({ id: types[i].Type });

            this.setState({ ConfigurationTypes: types });
        }

        if (prevProps?.IsNewConfiguration !== this.props?.IsNewConfiguration)
            if (this.props.IsNewConfiguration)
                this.vm.$dispatch({ SelectConfigurationType: -1 });


        if (prevProps?.SelectedConfiguration !== this.props?.SelectedConfiguration) {
            this.vm.$dispatch({ ConfigurationID: this.props.SelectedConfiguration });
        }

    }

    setCycles(cycles) {
        this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, Cycles: cycles } });
    }

    setStarts(starts) {
        this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, Starts: starts } });
    }

    setParams(params) {
        this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, Params: params } });
    }

    setRefrigerations(params) {
        this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, Refrigerations: params } });
    }

    setFlagsEnabled(flag, enabled) {

        let data = null;
        let dataType = '';

        if (enabled) {
            dataType = 'Empty' + flag.replace('Enabled', '');
            data = this.state?.ConfigurationDetail[dataType];
        }

        if (dataType !== '')
            this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, [flag]: enabled, [dataType]: data } });
        else
            this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, [flag]: enabled } });
    }

    changeNameConfigurationDetail() {
        this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, Name: this.txtName.value } });
    }

    changeDescriptionConfigurationDetail() {
        this.setState({ ConfigurationDetail: { ...this.state.ConfigurationDetail, Description: this.txtDescription.value } });
    }

    saveConfiguration() {
        if (this.vm !== undefined) {

            let configuration = JSON.parse(JSON.stringify(this.state?.ConfigurationDetail));
            configuration.Cycles = JSON.stringify(configuration.Cycles);
            configuration.Params = JSON.stringify(configuration.Params);
            configuration.Starts = JSON.stringify(configuration.Starts);
            configuration.Refrigerations = JSON.stringify(configuration.Refrigerations);

            this.vm.$dispatch({ SaveConfiguration: configuration });
            //  this.props.RefreshConfigurations();
        }
    }

    hideAlert() {
        this.setState({ showDialogAlert: false });
    }

    confirm() {
        this.vm.$dispatch({ DeleteConfiguration: this.state?.ConfigurationDetail?.Id });
        this.props.RefreshConfigurations();
        this.setState({ showDialogAlert: false });
    }

    deleteConfiguration() {
        this.setState({ showDialogAlert: true });
    }

    renderDeleteConfiguration() {
        let cssIcon = 'e-icons e-Delete';
        if (!this.state.EnabledBtns) { cssIcon = 'e-icons e-DeleteDisable' }

        if (this.props.IsNewConfiguration)
            return null;

        return (
            <ButtonComponent className='ButtonRed' disabled={!this.state.EnabledBtns} iconCss={cssIcon} onClick={() => this.deleteConfiguration()}><FormattedMessage id='configuration_delete' /></ButtonComponent>
        );
    }

    dropdownConfigurationTypeChange(e) {
        if (e.isInteracted)
            if (this.dropdownConfigurationTypes !== null)
                this.vm.$dispatch({ SelectConfigurationType: this.dropdownConfigurationTypes.value });
    }

    renderMenuConfiguration() {
        if (this.dropdownConfigurationTypes?.value === undefined && this.props.IsNewConfiguration)
            return null;
        if (this.dropdownConfigurationTypes?.value !== '') {
            return (
                <div id='divEditConfiguration'>
                    <MenuConfigurations
                        IsConfigurationMenu={true}
                        SelectedDevices={null}
                        Detail={this.state?.ConfigurationDetail}
                        SetCycles={this.setCycles.bind(this)}
                        SetStarts={this.setStarts.bind(this)}
                        SetParams={this.setParams.bind(this)}
                        SetRefrigerations={this.setRefrigerations.bind(this)}
                        SetFlagsEnabled={this.setFlagsEnabled.bind(this)}
                        Option={this.props.Option}
                        UmTemperature={this.commonData.getUmTemperature()}
                        IsNewConfiguration={this.props.IsNewConfiguration}
                        MenuSelected={this.menuSelected.bind(this)}
                        CyclesPermit={this.state.ConfigurationDetail.CyclesPermit}
                        ParamsPermit={this.state.ConfigurationDetail.ParamsPermit}
                        StartsPermit={this.state.ConfigurationDetail.StartsPermit}
                        RefrigerationsPermit={this.state.ConfigurationDetail.RefrigerationsPermit}
                    />
                </div>
            );
        }
        return null;
    }

    createDescription() {
        this.txtDescription.respectiveElement.style.height = 'auto';
        this.txtDescription.respectiveElement.style.height = (this.txtDescription.respectiveElement.scrollHeight) + 'px';
    }
    inputDescription() {
        this.txtDescription.respectiveElement.style.height = 'auto';
        this.txtDescription.respectiveElement.style.height = (this.txtDescription.respectiveElement.scrollHeight) + 'px';
    }

    renderConfigurationDetail() {
        if ((this.props.SidebarSetupConfigurationsVisible === 0) || (this.state?.ConfigurationDetail === null))
            return null;
        else
            return (
                <div id='divConfigurationPage'>
                    <Toast
                        Notification={this.state.Notification}
                        Refresh={this.props.RefreshConfigurations}
                        Option='configurations'
                    />
                    <div className='row rowHeader'>
                        <div className='col-xs-12'>
                            <div className='row rowInfo'>
                                <span className='lblTitle'>{this.state?.ConfigurationDetail?.Name}</span>
                            </div>
                        </div>
                    </div>

                    <div id='divConfigurationPageTop' className='row'>
                        <div className='col-xs-6'>
                            <span className='lblValue'><FormattedMessage id='setup_configuration' /></span>
                        </div>
                        <div className='col-xs-6'>
                            <ButtonComponent id='btnSaveConfiguration' className='Button'
                                disabled={!this.state.EnabledBtns}
                                onClick={() => this.saveConfiguration()}>
                                <FormattedMessage id='configuration_save' /></ButtonComponent>
                        </div>
                    </div>
                    <div id='divConfigurationDetail'>
                        <div className='row' >
                            <div className='col-xs-6'>
                                <span className='lblDescription'><FormattedMessage id='configuration_type' /></span>
                                <DropDownListComponent id='listConfigurationTypes'
                                    dataSource={(this.state?.ConfigurationTypes === null) ? null : this.state?.ConfigurationTypes}
                                    ref={(dropdownlist) => { this.dropdownConfigurationTypes = dropdownlist; }}
                                    fields={{ value: 'Type', text: 'Description' }}
                                    enabled={this.props?.IsNewConfiguration}
                                    value={this.state?.ConfigurationDetail?.ConfigurationType}
                                    popupHeight='auto'
                                    placeholder={this.props.intl.formatMessage({ id:'enter_typeConfiguration'})} 
                                    change={this.dropdownConfigurationTypeChange.bind(this)}></DropDownListComponent>
                            </div>

                        </div>

                        <div className='row' id='divRowConfig'>
                            <div className='col-xs-12'>
                                <span className='lblDescription'><FormattedMessage id='name' /></span>
                                <TextBoxComponent className='e-input' type='text'
                                    ref={txt => this.txtName = txt}
                                    value={this.state?.ConfigurationDetail?.Name || ''}
                                    placeholder={this.props.intl.formatMessage({ id:'enter_name_config'})} 
                                    change={this.changeNameConfigurationDetail.bind(this)} id='txtName' />
                            </div>
                        </div>

                        <div className='row' id='divRowConfig'>
                            <div className='col-xs-12'>
                                <span className='lblDescription'><FormattedMessage id='description' /></span>
                                <TextBoxComponent className='e-input'
                                    input={this.inputDescription.bind(this)}
                                    created={this.createDescription.bind(this)}
                                    ref={txt => { this.txtDescription = txt }} multiline={true} type='text'
                                    value={this.state?.ConfigurationDetail?.Description || ''}
                                    floatLabelType='Auto' change={this.changeDescriptionConfigurationDetail.bind(this)} id='txtDescription' />
                            </div>
                        </div>

                    </div>
                    <div className='row' id='divConfigurationDelete'>
                        <div className='col-xs-6'></div>
                        <div className='col-xs-6' id='btbConfigurationDelete'>
                            {this.renderDeleteConfiguration()}
                        </div>
                    </div>

                    {this.renderMenuConfiguration()}
                </div>

            );
    }

    renderButtonsSidebarManage() {
        return (
            <div id='divButtonsSidebar'>
                <div id='divButtonClear'>
                    <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                </div>

                <div id='divButtonPrev'>
                    <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeConfiguration(-1)} iconCss='e-icons e-sidebarprev' />
                </div>
                <div id='divButtonNext'>
                    <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeConfiguration(+1)} iconCss='e-icons e-sidebarnext' />
                </div>

            </div>
        );
    }

    renderContentSidebarSetupConfigurations() {
        if (this.props.SidebarSetupConfigurationsVisible === 0)
            return null;

        let stateSidebar = this.props.SidebarSetupConfigurationsVisible;

        if (this.state.LocalSidebarSetupConfigurationsVisible !== 0)
            stateSidebar = this.state.LocalSidebarSetupConfigurationsVisible;

        if (stateSidebar === 1)
            return (
                <div>
                    {this.renderButtonsSidebarManage()}
                    {this.renderConfigurationDetail()}
                </div>
            );
    }

    render() {
        var widthSidebar = { width: '0px' };
        var stateSidebar = this.props.SidebarSetupConfigurationsVisible;

        if (this.state.LocalSidebarSetupConfigurationsVisible !== 0)
            stateSidebar = this.state.LocalSidebarSetupConfigurationsVisible;

        if (stateSidebar === 1)
            widthSidebar = { width: '45%' };


        return (
            <div ref={Sidebar => this.sidebarobj = Sidebar}
                id='sidebarSetupConfigurations' style={widthSidebar}>
                {this.renderContentSidebarSetupConfigurations()}
            </div>
        );

    }
}

SidebarSetupConfigurations.propTypes = {
    SidebarSetupConfigurationsVisible: PropTypes.number,
    IsNewConfiguration: PropTypes.bool,
    SelectedConfiguration: PropTypes.number,
    RefreshConfigurations: PropTypes.func,
    intl: PropTypes.object,
    Option: PropTypes.string,
    ClearSelection: PropTypes.func,
    ChangeConfiguration: PropTypes.func
};

export default injectIntl(SidebarSetupConfigurations);