import React from 'react';
import PropTypes from 'prop-types';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { injectIntl } from 'react-intl';
import '../styles/menu.css';
import AvatarTemplate from '../components/templates/AvatarTemplate';

class Menu extends React.Component {

    constructor() {
        super(...arguments);
    }

    setMenuItems() {
        this.items = [{ id: 0, text: this.props.UserName, iconCss: 'e-icons e-FT_Resource' },
        { id: 1, text: this.props.intl.formatMessage({ id: 'settings' }), iconCss: 'e-icons e-F_Properties' },
        { id: 2, text: this.props.intl.formatMessage({ id: 'information' }), iconCss: 'e-icons e-FT_info' },
        { id: 4, text: this.props.intl.formatMessage({ id: 'logout' }), iconCss: 'e-icons e-FT_close' }];

        if(this.dropDownMenu)
            this.dropDownMenu.items = this.items;
    }
    componentDidMount() {
        this.setMenuItems();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.MobileMenuEnabled !== this.props.MobileMenuEnabled)
            this.setMenuItems();
        if (prevProps?.intl?.locale != this.props?.intl?.locale)
            this.setMenuItems();
    }

    handleSelectMenu(e) {
        if (e.item.id === 0) this.props.OpenSetupProfile();
        if (e.item.id === 1) this.props.OpenDialogSettings();
        if (e.item.id === 2) this.props.OpenDialogAbout();
        if (e.item.id === 4) this.props.Logout();
    }

    handleOpenDropDown() {
        this.dropDownMenu.toggle();
    }

    renderMenu() {
        if (!this.props.MobileMenuEnabled)
            return (
                <div className='buttonMenu'>
                    <ButtonComponent id='elementBtnMenu'
                        onClick={this.handleOpenDropDown.bind(this)}>
                        <AvatarTemplate id='elementAvatar'
                            UserName={this.props.UserName}
                            UserImage={this.props.UserImage}
                            Option='menu' >
                        </AvatarTemplate>

                    </ButtonComponent>

                    <DropDownButtonComponent id='dropDownMenu'
                        ref={dd => this.dropDownMenu = dd}
                        fields={{ text: 'text', value: 'id', iconCss: 'icon' }}
                        select={this.handleSelectMenu.bind(this)}
                    >
                    </DropDownButtonComponent>
                </div>

            );
        else
            return (
                <div>
                    <img id='imgInfo' src={'/images/icons/info.png'} onClick={this.props.OpenDialogAbout}></img>
                    <img id='imgSett' src={'/images/icons/settings.png'} onClick={this.props.OpenDialogSettings}></img>
                    <img id='imgLogout' src={'/images/icons/exit.png'} onClick={this.props.Logout}></img>
                </div>
            );
    }

    render() {
        return (
            <div id='renderMenu'>
                {this.renderMenu()}
            </div>
        );
    }
}

Menu.propTypes = {
    OpenDialogAbout: PropTypes.func,
    OpenDialogSettings: PropTypes.func,
    OpenUSB: PropTypes.func,
    OpenSetupProfile: PropTypes.func,
    Logout: PropTypes.func,
    MobileMenuEnabled: PropTypes.bool,
    UserName: PropTypes.string,
    UserImage: PropTypes.any,
    intl: PropTypes.object
};

export default injectIntl(Menu);