import React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import CommonDataManager from '../../components/CommonDataManager';
import { Internationalization } from '@syncfusion/ej2-base';

export function statusTemplate(props) {

    let new_intl = new Internationalization();
    let commonData = CommonDataManager.getInstance();
    let alarms = commonData.getAlarms();
    let licenseType = commonData.getLicenseType();
    let timeFormat = commonData.getTimeFormat();

    if (this !== undefined) {
        if (this.intl !== undefined)
            return statusTemplateIntl(props, this.intl, alarms, licenseType, new_intl, timeFormat);

        if (this.props.intl !== undefined)
            return statusTemplateIntl(props, this.props.intl, alarms, licenseType, new_intl, timeFormat);
    }
    return (<div></div>);
}


export function statusTemplateIntl(props, intl, alarms, licenseType, new_intl, timeFormat) {
    try {

        let status = '';

        status = intl.formatMessage({ id: 'status_' + props.Status, defaultMessage: ' ' });


        if ((props.Status === -999) && (licenseType > 1))
            return (
                <div className='StatusEMPTY'></div>
            );


        if (props.Status === 0)
            return (
                <div className='StatusNC'>{status}</div>
            );

        if (props.Status === 1)
            return (
                <div className='StatusOFF'>{status}</div>
            );

        if (props.Status === 2)
            return (
                <div className='StatusON'>{status}</div>
            );

        if (props.Status === 3)
            return (
                <div className='StatusCYCLE'>{status}{props.CycleNumber}</div>
            );

        if (props.Status === 4) {
            let alarmCode = '';
            let alarmDescription = intl.formatMessage({ id: 'alarm' });

            if (props.Alarms !== undefined)
                if (props.Alarms.length > 0)
                    alarmCode = props.Alarms[0];

            if (alarmCode !== '') {
                if (alarms !== undefined)
                    if (alarms.length > 0)
                        for (let i = 0; i < alarms.length; i++) {
                            if (alarms[i].ID === alarmCode)
                                if (alarms[i].CustomDescription !== '')
                                    alarmDescription = '<h6>' + alarms[i].Description + '</h6><p>' + alarms[i].CustomDescription + '</p>';
                                else
                                    alarmDescription = '<h6>' + alarms[i].Description + '</h6>';
                        }
            }
            return (
                <TooltipComponent position='TopCenter' target='.StatusALARM' content={alarmDescription}>
                    <div className='StatusALARM'>{status} {alarmCode}</div>
                </TooltipComponent>
            );
        }

        if (props.Status === 5)
            return (
                <div className='StatusERROR'>{status}</div>
            );

        if (props.Status === 6)
            return (
                <div className='StatusWAITING'>{status}</div>
            );

        if (props.Status === 7)
            return (
                <div className='StatusSETTINGS'>{status}</div>
            );
        if (props.Status === 200) {
            let date = new_intl.formatDate(new Date(props.InDeliveringDate), { format: timeFormat });
            return (
                <div className='StatusDELIVERING'>{status} {date}</div>
            );
        }

        if (props.Status === 201) {
            let date = new_intl.formatDate(new Date(props.InDistributionDate), { format: timeFormat });
            return (
                <div className='StatusDISTRIBUTION'>{status} {date}</div>
            );
        }

        if (props.Status === 202) {
            return (
                <div className='StatusDOWNLOAD'>{status}</div>
            );
        }

        if (props.Status === 203) {
            return (
                <div className='StatusCONNESSO'>{status}</div>
            );
        }

        return (
            <div></div>
        );
    }
    catch (exp) {
        return (
            <div></div>
        );
    }
}
