import React from 'react';
import PropTypes from 'prop-types';
import CommonDataManager from '../../components/CommonDataManager';

export function ImageModel(props) {
    let modelImage = 'noModel';

    let commonData = CommonDataManager.getInstance();

    if (props !== undefined) {

        var m = '';
        if (props.DeviceVersion !== null)
            m = '_' + props.DeviceVersion;

        if (props.ModelId !== null)
            m = m + '_' + props.ModelId;

        if (props.Dashboard !== undefined) {
            if (props.ModelId !== commonData.getTAGModelId()) {
                modelImage = 'modelDashboard' + m;
            } else
                if (props.Status !== 2)
                    modelImage = 'modelDashboard' + m + '_PROSERV';
                else
                    modelImage = 'modelDashboard' + m + '_DSPRO_PROSERV';
        } else {
            if (props.ModelId !== commonData.getTAGModelId()) {
                if (props.ModelId === 0) {
                    if (props.Status === 2 || props.Status === 3) {
                        modelImage = 'model' + m + '_DSPRO_PROSERV';
                    }
                    else
                        modelImage = 'model' + m;
                }
                else
                    modelImage = 'model' + m;
            }
            else {
                if (props.Status === 0) {
                    modelImage = 'model' + m + '_TAG';
                }
                else {
                    if (props.DSPROId !== -1)
                        modelImage = 'model' + m + '_DSPRO_PROSERV';
                    else
                        modelImage = 'model' + m + '_PROSERV';
                }
            }

        }

    }
    var pathModel = '/images/models/' + modelImage + '_' + props.Theme + '.png';
    return (
        <img className='imageModel' width={props.Width} height={props.Height} src={pathModel} />
    );
}


ImageModel.propTypes = {
    Dashboard: PropTypes.bool,
    ModelId: PropTypes.number,
    DeviceVersion: PropTypes.string,
    Width: PropTypes.number,
    Height: PropTypes.number,
    Theme: PropTypes.string,
    Status: PropTypes.number,
    DSPROId: PropTypes.number
};

export default ImageModel;