import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import '../styles/toolbar.css';

class Toolbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = { SelectedToolbarItem: this.props?.Items[0]?.Title };
    }

    ItemSelected(title, route) {
        this.setState({ SelectedToolbarItem: title });
        this.props.ToolbarSelect(title, route);
    }

    toolbarHeader() {
        return (
            <div className={'divToolbar ' + this.props.Option}>
                {this.props.Items.map((menu, index) => {
                    const t = this.props.intl.formatMessage({ id: menu.Title });

                    if (this.props.Option === 'menuHeader' || this.props.Option === 'menuSetupHeader' || this.props.Option === 'menuEventHeader')
                        var imgList = ('/images/icons/' + menu.Route.TemplateId + '.svg').toLowerCase();

                    if (this.props.Option === 'setupUsers' || this.props.Option === 'sidebarManage' || this.props.Option === 'newDevice')
                        imgList = ('/images/icons/' + menu.Title + '.svg').toLowerCase();

                    let classSelected = '';

                    if (this.props.Option === 'menuHeader' || this.props.Option === 'menuSetupHeader' || this.props.Option === 'menuEventHeader') {


                        if (this.props.Option === 'menuHeader') {
                            classSelected = (
                                ((menu.Title.startsWith('menuheader.setup')) && (this.props.CurrentView.startsWith('Setup'))) ||
                                ((menu.Title.startsWith('menuheader.events')) && (this.props.CurrentView.startsWith('History'))) ||
                                (this.props.CurrentView === menu.Route.TemplateId)) ? 'selected' : '';
                        }

                        if (this.props.Option === 'menuSetupHeader')
                            classSelected = (this.props.CurrentView === menu.Route.TemplateId) ? 'selected' : '';

                        if (this.props.Option === 'menuEventHeader')
                            classSelected = (this.props.CurrentView === menu.Route.TemplateId) ? 'selected' : '';
                    }
                    else {
                        if (this.props.Option === 'sidebarManage') {
                            let itemSelect = this.props?.Items.filter(t => t.Title === this.state.SelectedToolbarItem).length === 1;

                            if (!itemSelect) {
                                classSelected = (this.props.Items[0].Title === menu.Title) ? 'selected' : '';
                            }
                            else
                                classSelected = (this.state.SelectedToolbarItem === menu.Title) ? 'selected' : '';

                        } else {
                            classSelected = (this.state.SelectedToolbarItem === menu.Title) ? 'selected' : '';
                        }
                    }

                    /* badge implementation
                    let notific = 0;
                     let cssNotific = '';
                     if (notific !== 0 && menu.Title === 'menuheader.trolleys')
                         cssNotific = 'e-badge e-badge-danger e-badge-danger e-badge-overlap e-badge-circle';
                     else
                         notific = '';*/
                    //<span id='cssBagdeToobal' className={cssNotific}>{notific}</span> 

                    return (
                        <div key={index} >
                            <div className={'tabToolbar ' + this.props.Option}>
                                <button className={'listToolbar ' + this.props.Option + ' ' + classSelected}
                                    onClick={() => this.ItemSelected(menu.Title, menu.Route)}>
                                    <div id='avatarToolbar' className='e-avatar e-avatar-xsmall e-avatar-circle' >
                                        <img id={'listImgToolbar' + classSelected} src={imgList}></img>
                                    </div>
                                    {t}
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>);
    }

    render() {
        return (
            <div id='renderToolbar'>
                {this.toolbarHeader()}
            </div>
        );
    }
}

Toolbar.propTypes = {
    ItemSelected: PropTypes.func,
    intl: PropTypes.object,
    Items: PropTypes.array,
    ToolbarSelect: PropTypes.func,
    Option: PropTypes.string,
    CurrentView: PropTypes.string
};
export default injectIntl(Toolbar);