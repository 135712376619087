import React from 'react';
import PropTypes from 'prop-types';
class AvatarTemplate extends React.Component {

    templateAvatar() {
        let pathAvatarImg = this.props.UserImage;
        let classCssAvatar;
        let pathAvatarLetter;
        if (this.props.Option !== 'menu') { classCssAvatar = 'e-avatar e-avatar-xlarge  e-avatar-circle' } else classCssAvatar = 'e-avatar e-avatar-circle';
        if (!this.props?.IsNewUser) { pathAvatarLetter = this.props?.UserName?.charAt(0).toUpperCase(); } else pathAvatarLetter = '';
        if (pathAvatarImg === '') pathAvatarImg = null;
        let imageAvatar = <img className={classCssAvatar} src={pathAvatarImg} ></img>;
        let letterAvatar = <span className={classCssAvatar} >{pathAvatarLetter}</span>;
        return (<div className='listWrapper'>

            {pathAvatarImg !== null ? (imageAvatar) : (letterAvatar)}

        </div>
        );
    }

    render() {

        return (
            <div>
                {this.templateAvatar()}
            </div>
        );
    }

}

AvatarTemplate.propTypes = {
    SelectedUser: PropTypes.any,
    Option: PropTypes.string,
    UserName: PropTypes.string,
    IsNewUser: PropTypes.bool,
    UserImage: PropTypes.any
};

export default AvatarTemplate;