import messages_en from '../translations/en.json';
import messages_it from '../translations/it.json';
import messages_fr from '../translations/fr.json';
import messages_de from '../translations/de.json';
import messages_fi from '../translations/fi.json';
import messages_es from '../translations/es.json';
import messages_nl from '../translations/nl.json';

import en_gregorian from 'cldr-data/main/en/ca-gregorian.json';
import en_numbers from 'cldr-data/main/en/numbers.json';
import en_timeZoneNames from 'cldr-data/main/en/timeZoneNames.json';

import it_gregorian from 'cldr-data/main/it/ca-gregorian.json';
import it_numbers from 'cldr-data/main/it/numbers.json';
import it_timeZoneNames from 'cldr-data/main/it/timeZoneNames.json';

import fr_gregorian from 'cldr-data/main/fr/ca-gregorian.json';
import fr_numbers from 'cldr-data/main/fr/numbers.json';
import fr_timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';

import de_gregorian from 'cldr-data/main/de/ca-gregorian.json';
import de_numbers from 'cldr-data/main/de/numbers.json';
import de_timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';

import fi_gregorian from 'cldr-data/main/fi/ca-gregorian.json';
import fi_numbers from 'cldr-data/main/fi/numbers.json';
import fi_timeZoneNames from 'cldr-data/main/fi/timeZoneNames.json';

import es_gregorian from 'cldr-data/main/es/ca-gregorian.json';
import es_numbers from 'cldr-data/main/es/numbers.json';
import es_timeZoneNames from 'cldr-data/main/es/timeZoneNames.json';

import nl_gregorian from 'cldr-data/main/nl/ca-gregorian.json';
import nl_numbers from 'cldr-data/main/nl/numbers.json';
import nl_timeZoneNames from 'cldr-data/main/nl/timeZoneNames.json';

import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import weekData from 'cldr-data/supplemental/weekData.json';

export default class CommonDataManager {

    static instance = null;

    _subs_language = [];
    _subs_theme = [];
    _subs_current_view = [];

    _subs_dateTime = [];

    _messages = {
        'en': messages_en,
        'it': messages_it,
        'fr': messages_fr,
        'de': messages_de,
        'fi': messages_fi,
        'es': messages_es,
        'nl': messages_nl
    };

    _language;
    _language_ext;
    _current_view;

    _licenseType;

    _umTemperature;

    _theme;

    _alarms;

    _dateTime;
    _date;
    _time;

    static getInstance() {
        if (CommonDataManager.instance == null) {
            CommonDataManager.instance = new CommonDataManager();
        }

        return this.instance;
    }

    getLanguage() {
        if (this._language == null) {
            let tmp = window.localStorage.getItem('language');
            /*
            if(tmp==null)
                this._language='en';
            else    */
            this._language = tmp;
        }

        return this._language;
    }

    getLanguageExt() {
        if (this._language_ext == null) {
            let tmp = window.localStorage.getItem('language_ext');
            if (tmp == null)
                this._language_ext = 'en-EN';
            else
                this._language_ext = tmp;
        }

        return this._language_ext;
    }


    getLicenseType() {
        return this._licenseType;
    }

    getUmTemperature() {
        return this._umTemperature;
    }

    getTheme() {
        return this._theme;
    }

    getAlarms() {
        return this._alarms;
    }

    getMessages() {
        return this._messages[this._language];
    }

    getCalendar() {
        if (this._language == 'en') return en_gregorian;
        if (this._language == 'it') return it_gregorian;
        if (this._language == 'fr') return fr_gregorian;
        if (this._language == 'de') return de_gregorian;
        if (this._language == 'fi') return fi_gregorian;
        if (this._language == 'es') return es_gregorian;
        if (this._language == 'nl') return nl_gregorian;
    }

    getNumbers() {
        if (this._language == 'en') return en_numbers;
        if (this._language == 'it') return it_numbers;
        if (this._language == 'fr') return fr_numbers;
        if (this._language == 'de') return de_numbers;
        if (this._language == 'fi') return fi_numbers;
        if (this._language == 'es') return es_numbers;
        if (this._language == 'nl') return nl_numbers;
    }

    getTimeZoneNames() {
        if (this._language == 'en') return en_timeZoneNames;
        if (this._language == 'it') return it_timeZoneNames;
        if (this._language == 'fr') return fr_timeZoneNames;
        if (this._language == 'de') return de_timeZoneNames;
        if (this._language == 'fi') return fi_timeZoneNames;
        if (this._language == 'es') return es_timeZoneNames;
        if (this._language == 'nl') return nl_timeZoneNames;
    }

    getDayNames() {
        return this.getCalendar().main[this._language].dates.calendars.gregorian.days.format.wide;
    }

    getAbbreviatedDayNames() {
        return this.getCalendar().main[this._language].dates.calendars.gregorian.days.format.abbreviated;
    }

    getNumberingSystems() {
        return numberingSystems;
    }

    getWeekData() {
        return weekData;
    }

    setLanguage(l) {

        let lTmp = l.split(/[-_]/)[0];

        if (this._messages[lTmp] === undefined) {
            lTmp = 'en';
            l = 'en-EN';
        }

        window.localStorage.setItem('language', lTmp);
        window.localStorage.setItem('language_ext', l);
        this._language = l.split(/[-_]/)[0];
        this._language_ext = l;
        this._subs_language.forEach((func) => func());
    }

    setDateTimeFormat(option) {

        if (option === '00') { this._dateTime = 'MM/dd/yyyy H:mm', this._date = 'MM/dd/yyyy', this._time = 'H:mm' }
        if (option === '01') { this._dateTime = 'MM/dd/yyyy h:mm a', this._date = 'MM/dd/yyyy', this._time = 'h:mm a' }
        if (option === '02') { this._dateTime = 'dd/MM/yyyy H:mm', this._date = 'dd/MM/yyyy', this._time = 'H:mm' }
        if (option === '03') { this._dateTime = 'dd/MM/yyyy h:mm a', this._date = 'dd/MM/yyyy', this._time = 'h:mm a' }
        if (option === '04') { this._dateTime = 'dd.MM.yyyy H:mm', this._date = 'dd.MM.yyyy', this._time = 'H:mm' }
        if (option === '05') { this._dateTime = 'dd.MM.yyyy h:mm a', this._date = 'dd.MM.yyyy', this._time = 'h:mm a' }
        if (option === '06') { this._dateTime = 'dd-MM-yyyy H:mm', this._date = 'dd-MM-yyyy', this._time = 'H:mm' }
        if (option === '07') { this._dateTime = 'dd-MM-yyyy h:mm a', this._date = 'dd-MM-yyyy', this._time = 'h:mm a' }

    }

    getDateFormat() {
        return this._date;
    }
    
    getTimeFormat() {
        return this._time;
    }

    getDateTimeFormat() {
        return this._dateTime;
    }

    setLicenseType(l) {
        this._licenseType = l;
    }

    setUmTemperature(u) {
        this._umTemperature = u;
    }

    setTheme(t) {
        this._theme = t;
        this._subs_theme.forEach((func) => func());
    }

    setAlarms(a) {
        this._alarms = a;
    }

    setCurrentView(v) {
        this._subs_current_view.forEach((func) => func(v));

        this._current_view = v;
    }

    subscribe(func) {
        this._subs_language.push(func);
    }

    unsubscribe(func) {
        let index = this._subs_language.indexOf(func);
        this._subs_language.splice(index, 1);
    }

    subscribeView(func) {
        this._subs_current_view.push(func);
    }

    unsubscribeView(func) {
        let index = this._subs_current_view.indexOf(func);
        this._subs_current_view.splice(index, 1);
    }

    subscribeTheme(func) {
        this._subs_theme.push(func);
    }

    unsubscribeTheme(func) {
        let index = this._subs_theme.indexOf(func);
        this._subs_theme.splice(index, 1);
    }

    subscribeDateTime(func) {
        this._subs_dateTime.push(func);
    }

    unsubscribeDateTime(func) {
        let index = this._subs_dateTime.indexOf(func);
        this._subs_dateTime.splice(index, 1);
    }

    getMobileWidth() {
        return 800;
    }

    getPROSERVModelId() {
        return 99;
    }

    getTAGModelId() {
        return 100;
    }


}