import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FormValidator } from '@syncfusion/ej2-inputs';
import Toolbar from '../components/Toolbar';
import dotnetify from 'dotnetify';
import '../styles/dialogNewDevice.css';

class DialogNewDevice extends React.Component {

    constructor(props) {
        super(props);

        this.vm = dotnetify.react.connect('NewDeviceVM', this);

        this.state = {
            SelectedTabPermission: 'general',
            NewDeviceDetail: [],
            Notification: []
        };
    }

    componentWillUnmount() {
        if (this.vm !== undefined)
            this.vm?.$destroy();
    }

    componentDidMount() {
        this.formValidatorTabs();
    }

    formValidatorTabs() {

        if (this.state.SelectedTabPermission === 'general') {

            this.formValidator = {

                rules: {
                    'DepartmentId': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
                    },
                    'SiteId': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
                    },
                    'ModelId': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
                    },
                    'DeviceID': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
                    },
                    'DeviceSN': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
                    }
                }
            };
        }

        else
            this.formValidator = {
                rules: {
                    'ClientID': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
                    },

                    'Username': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })],

                    },

                    'Password': {
                        required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
                    }
                }
            };

        this.formObject = new FormValidator('#formNewDevices', this.formValidator);

        if (this.state.UdpEnabled && this.state.SelectedTabPermission === 'general') {
            this.formObject.addRules('UdpIpAddress', {
                required: [true, this.props.intl.formatMessage({ id: 'field_required' })]
            });
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.Notification !== this.state.Notification) {
            if (this.state.Notification.Type === 1) {
                this.props.Close();
            }
            else {
                // this.state.Notification.Type
            }
        }

        if (prevState.SelectedTabPermission !== this.state.SelectedTabPermission) {
            this.formValidatorTabs();
        }
    }

    close() {
        this.props.Close();
    }

    selectedTabPermission(args) {
        this.setState({ SelectedTabPermission: args });
    }

    saveNewDevice() {

        this.setState({
            NewDeviceDetail: {
                DeviceID: this.state.NewDeviceDetail.DeviceID, DeviceSN: this.state.NewDeviceDetail.DeviceSN,
                DeviceVersion: this.state.NewDeviceDetail.DeviceVersion, SiteId: this.state.NewDeviceDetail.SiteId,
                DepartmentId: this.state.NewDeviceDetail.DepartmentId, ModelId: this.state.NewDeviceDetail.ModelId,
                UdpIpAddress: this.state.NewDeviceDetail.UdpIpAddress, ClientID: this.state.NewDeviceDetail.ClientID,
                Username: this.state.NewDeviceDetail.Username, Password: this.state.NewDeviceDetail.Password
            }
        });

        if (this.formObject.validate()) {
            this.vm?.$dispatch({ AddDevice: this.state.NewDeviceDetail });
        }

    }

    changeNewDevice(e) {
        this.setState({ NewDeviceDetail: { ...this.state.NewDeviceDetail, [e.target.name]: e.target.value } });

    }

    dropdownSaveSiteId() {
        this.setState({
            NewDeviceDetail: { ...this.state.NewDeviceDetail, SiteId: this.listSites.value }
        });
    }

    dropdownSaveDepartmentId() {
        this.setState({
            NewDeviceDetail: { ...this.state.NewDeviceDetail, DepartmentId: this.listDepartments.value }
        });
    }

    dropdownSaveModelId() {
        this.setState({
            NewDeviceDetail: {
                ...this.state.NewDeviceDetail, ModelId: this.listModel.value, DeviceVersion: this.listModel.itemData.DeviceVersion
            }
        });

    }

    newDevice() {
        return (
            <div className='divNewDeviceData'>
                <form id='formNewDevices'>
                    <div className='form-group'>
                        <div className='row rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='id_trolley' /></span>
                            <input id='DeviceID' name='DeviceID'
                                type='text' className='e-input'
                                data-msg-containerid='errorDataDevice'
                                value={this.state.NewDeviceDetail.DeviceID || ''}
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_device_id' })}
                                onChange={this.changeNewDevice.bind(this)} required />
                            <div id='errorDataDevice'></div>
                            <label id='errorMessage'>{this.state.Notification.Message}</label>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='row rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='site' /></span>

                            <DropDownListComponent id='SiteId' name='SiteId'
                                ref={(dropdownlist) => { this.listSites = dropdownlist; }}
                                fields={{ value: 'Id', text: 'Name' }}
                                dataSource={this.state.Sites} data-msg-containerid='errorSiteId'
                                value={this.state.NewDeviceDetail.SiteId}
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_site' })}
                                change={this.dropdownSaveSiteId.bind(this)} required />
                            <div id='errorSiteId'></div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='row rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='department' /></span>
                            <DropDownListComponent id='DepartmentId' name='DepartmentId'
                                dataSource={this.state.Department} data-msg-containerid='errorDepartmentId'
                                ref={(dropdownlist) => { this.listDepartments = dropdownlist; }}
                                fields={{ value: 'Id', text: 'Name' }}
                                popupHeight='auto'
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_department' })}
                                value={this.state.NewDeviceDetail.DepartmentId}
                                change={this.dropdownSaveDepartmentId.bind(this)} required
                            />
                            <div id='errorDepartmentId'></div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='row rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='model' /></span>
                            <DropDownListComponent id='ModelId' name='ModelId'
                                dataSource={this.state.S} data-msg-containerid='errorModelId'
                                ref={dropdownlist => this.listModel = dropdownlist}
                                fields={{ value: 'ModelId', text: 'Description', dv: 'DeviceVersion ' }}
                                value={this.state.NewDeviceDetail.ModelId}
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_model' })}
                                change={this.dropdownSaveModelId.bind(this)} required
                            />
                            <div id='errorModelId'></div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='row rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='sn_trolley' /></span>
                            <input id='DeviceSN' name='DeviceSN'
                                data-msg-containerid='errorDeviceSN'
                                className='e-input' type='text'
                                value={this.state.NewDeviceDetail.DeviceSN || ''}
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_sntrolley' })}
                                onChange={this.changeNewDevice.bind(this)} required />
                            <div id='errorDeviceSN'></div>
                        </div>
                    </div>

                    <div className='form-group'>
                        {this.state.UdpEnabled ? <div className='row rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='udp_ip_address' /></span>
                            <input id='UdpIpAddress' name='UdpIpAddress'
                                ref={text => this.textUdpIpAddress = text}
                                className='e-input' type='text'
                                value={this.state.NewDeviceDetail.UdpIpAddress || ''}
                                data-msg-containerid='errorUdpIpAddress'
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_udp_IpAddress' })}
                                onChange={this.changeNewDevice.bind(this)} required />
                            <div id='errorUdpIpAddress'></div>
                        </div> : null}
                    </div>
                </form >
            </div>


        );
    }

    serverAuthentication() {
        return (
            <div className='row divServerAuthentication'>
                <form id='formNewDevices'>
                    <div className='form-group'>
                        <div className='col-xs-12 rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='addnewdevice.enter_id_client' /></span>
                            <input id='ClientID' name='ClientID'
                                className='e-input' type='text'
                                data-msg-containerid='errorClientID'
                                value={this.state.NewDeviceDetail.ClientID || ''}
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_id_client' })}
                                onChange={this.changeNewDevice.bind(this)} required />
                            <div id='errorClientID'></div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='col-xs-12 rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='username' /></span>
                            <input id='Username' name='Username'
                                className='e-input' type='text'
                                data-msg-containerid='errorUsername'
                                value={this.state.NewDeviceDetail.Username || ''}
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_username' })}
                                onChange={this.changeNewDevice.bind(this)} required />
                            <div id='errorUsername'></div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='col-xs-12 rowDataDevice'>
                            <span className='lblDescription'><FormattedMessage id='password' /></span>
                            <input id='Password' name='Password'
                                className='e-input' type='password'
                                data-msg-containerid='errorPassword'
                                value={this.state.NewDeviceDetail.Password || ''}
                                placeholder={this.props.intl.formatMessage({ id: 'addnewdevice.enter_password' })}
                                onChange={this.changeNewDevice.bind(this)} required />
                            <div id='errorPassword'></div>
                        </div>
                    </div>
                </form >
            </div>
        );
    }

    renderNewDevice() {
        return (
            <div className='renderNewDevice'>
                <div className='headerNewDevice'>
                    <span className='titleDialog'><FormattedMessage id='data_newDevice' /></span>

                </div>
                <div className='tabToobar'>
                    <Toolbar
                        Option='newDevice'
                        Items={[{ Title: 'general' }, { Title: 'server_authentication' }]}
                        ToolbarSelect={this.selectedTabPermission.bind(this)}
                    />
                    {this.state?.SelectedTabPermission === 'general' && this.newDevice()}
                    {this.state?.SelectedTabPermission === 'server_authentication' && this.serverAuthentication()}
                </div>

                <div className='row divBtnsNewDevice'>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnUploader' onClick={this.close.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnUploader' onClick={this.saveNewDevice.bind(this)}><FormattedMessage id='confirm' /></ButtonComponent>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <div className='divBlockDialog'>
                <div className='dialogNewDevice'>
                    {this.renderNewDevice()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        );
    }

}

DialogNewDevice.propTypes = {
    intl: PropTypes.object,
    Close: PropTypes.func,
};

export default injectIntl(DialogNewDevice);