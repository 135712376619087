import * as React from 'react';
import PropTypes from 'prop-types';
import '../styles/dialogAlert.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';


//AlertType 1 solo bottone ok
//AlertType 2 bottone ok e annulla

class DialogAlert extends React.Component {
    constructor() {
        super(...arguments);
    }

    createDescription() {
        this.textAlert.respectiveElement.style.height = '200px';
        this.textAlert.respectiveElement.style.resize = 'none';
        this.textAlert.respectiveElement.style.color = 'var(--text-color-light)';
        this.textAlert.respectiveElement.style.fontWeight = '500';
        this.textAlert.respectiveElement.style.fontSize = '14px';
        this.textAlert.respectiveElement.style.paddingTop = '15px';
        
    }

    renderAlert() {

        let classNameConfirm = 'col-xs-6';

        if (this.props.AlertType === 1)
            classNameConfirm = 'col-xs-12';

        return (
            <div id='divDialogAlert'>
                <h3 id='titleAlert'>{this.props.Title}</h3>

                <div id='message_alert'>
                    { /*<div dangerouslySetInnerHTML={{ __html: this.props.Message }} />*/}
                    <TextBoxComponent className='dialogAlertMessage'
                        ref={txt => { this.textAlert = txt }}
                        created={this.createDescription.bind(this)}
                        multiline={true} id='textAlert' readOnly={true}
                        value={this.props.Message} floatLabelType='Auto' height='150px'
                    />
                </div>

                <div className='row divBtnAlert'>
                    {this.props.AlertType === 2 && <div className='col-xs-6'>
                        <ButtonComponent id='btnSet' onClick={() => this.props.Cancel()}> {this.props.intl.formatMessage({ id: 'cancel' })} </ButtonComponent>
                    </div>}

                    <div className={classNameConfirm}>
                        <ButtonComponent id='btnSet' onClick={() => this.props.Confirm(this.props.Option)}>{this.props.intl.formatMessage({ id: 'confirm' })}</ButtonComponent>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className='dialog'>
                    {this.renderAlert()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        );
    }
}

DialogAlert.propTypes = {
    intl: PropTypes.object,
    AlertType: PropTypes.number,
    Title: PropTypes.string,
    Message: PropTypes.string,
    Cancel: PropTypes.func,
    Confirm: PropTypes.func,
    Option: PropTypes.string
};

export default DialogAlert;