import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/setupConfigurationsPage.css';
import { GridComponent, Filter, Inject, VirtualScroll, Sort, Search } from '@syncfusion/ej2-react-grids';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import SidebarSetupConfigurations from '../components/SidebarSetupConfigurations';

import { L10n, setCulture } from '@syncfusion/ej2-base';
import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class ConfigurationsPage extends React.Component {

    constructor(props) {
        super(props);

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);
        this.commonData.subscribeDateTime(this.updateDateTime.bind(this));

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            FormatDateTime: this.commonData.getDateTimeFormat(),
            Configurations: [],
            SelectedConfiguration: -1,
            SidebarSetupConfigurationsVisible: 0,
            IsNewConfiguration: false
        };

        this.vm = dotnetify.react.connect('ConfigurationsVM', this);
    }

    updateLanguage() {
        let commonData = CommonDataManager.getInstance();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
    }

    updateDateTime() {
        this.setState({ FormatDateTime: this.commonData.getDateTimeFormat() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('ConfigurationsPage');
    }

    componentWillUnmount() {
        if (this.vm !== undefined)
            this.vm.$destroy();

        if (this.commonData !== undefined)
            this.commonData.unsubscribe(this.updateLanguage);
            this.commonData.unsubscribeDateTime(this.updateDateTime);
    }

    componentDidUpdate() {
        if (this.gridInstance !== undefined)
            this.gridInstance.hideSpinner();
    }

    clearSelection() {
        let cloneConfigurations = JSON.parse(JSON.stringify(this.state)).Configurations;

        for (let i = 0; i < cloneConfigurations.length; i++)
            cloneConfigurations[i].Selected = false;

        this.setState({ Configurations: cloneConfigurations, SidebarSetupConfigurationsVisible: 0, SelectedConfiguration: -1, IsNewConfiguration: false });
    }

    refreshConfigurations() {
        this.vm.$dispatch({ RefreshConfigurations: '' });
        this.setState({ SidebarSetupConfigurationsVisible: 0, SelectedConfiguration: -1 });
    }

    newConfiguration() {
        this.setState({ SidebarSetupConfigurationsVisible: 1,SelectedConfiguration: -1, IsNewConfiguration: true });
    }

    changeConfiguration(direction) {
        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state?.SelectedConfiguration);

        if (direction == -1)
            selectedRowIndex--;

        if (direction == 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) return;

        if (selectedRowIndex > pageRows.length - 1) return;

        let newId = pageRows[selectedRowIndex].Id;

        let cloneConfigurations = JSON.parse(JSON.stringify(this.state.Configurations));

        let rowHeight = this.gridInstance.getRows()[selectedRowIndex].scrollHeight;
        window.scrollBy({ top: rowHeight * direction, behavior: 'smooth' });

        for (let i = 0; i < cloneConfigurations.length; i++)
            cloneConfigurations[i].Selected = cloneConfigurations[i].Id === newId;

        this.setState({ Configurations: cloneConfigurations, SelectedConfiguration: cloneConfigurations.filter(h => h.Selected)[0].Id });

    }

    rowConfigurationSelected() {
        var selected;
        var id = -1;

        if (this.gridInstance.getSelectedRecords().length === 1) {
            id = this.gridInstance.getSelectedRecords()[0].Id;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        if (selected === undefined)
            id = -1;
        else
            if (selected.Selected)
                id = -1;

        let cloneConfigurations = JSON.parse(JSON.stringify(this.state.Configurations));

        for (let i = 0; i < cloneConfigurations.length; i++) {
            cloneConfigurations[i].Selected = (cloneConfigurations[i].Id === id);
        }
        this.setState({ Configurations: cloneConfigurations, SidebarSetupConfigurationsVisible: (id !== -1) ? 1 : 0, SelectedConfiguration: selected.Id, IsNewConfiguration: false });
    }

    renderGrid() {
        let columns = [
            { field: 'Selected', headerText:'' , width:'35px', displayAsCheckBox: true, allowFiltering: false},
            { field: 'Id', visible: false, isPrimaryKey: true},
            { field: 'Name', headerText: this.intl.formatMessage({ id: 'name' })},
            { field: 'DateTimeInsert', headerText: this.intl.formatMessage({ id: 'date_time_insert' }), type: 'datetime', format:  this.state.FormatDateTime },
            { field: 'SurnDateTimeLastChangeame', headerText: this.intl.formatMessage({ id: 'date_time_last_change' }), format:  this.state.FormatDateTime }
        ];

        return (
            <div>
                <div id='divToolbarSetupConfigurations' className='row'>
                    <div className='col-xs-6 col-sm-6 col-md-6'>
                    </div>
                    <div className='col-xs-6 col-sm-6 col-md-6'>
                        <ButtonComponent id='btnNewConfiguration' className='Button' onClick={() => this.newConfiguration()}><FormattedMessage id='setup_configurations.new_configuration' /></ButtonComponent>
                    </div>
                </div>
                <div id='divSetupConfigurationsGrid'>
                    <GridComponent id='setupConfigurationsGrid' ref={grid => this.gridInstance = grid}
                        dataSource={this.state.Configurations} enableHover={true} enableVirtualization={false}
                        allowFiltering={true} enableToggle={true}
                        allowExcelExport={true} columns={columns}
                        filterSettings={{ type: 'Menu', checkBoxOnly: 'true' }}
                        allowSorting={true} allowSelection={true}
                        rowSelected={this.rowConfigurationSelected.bind(this)}>
                        <Inject services={[Filter, Sort, VirtualScroll, Search]} />
                    </GridComponent>

                </div>
            </div>
        );
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divSetupConfigurations'>
                    <BasePage title={this.intl.formatMessage({ id: 'menuheader.configurations' })} navigation={this.intl.formatMessage({ id: 'menuheader.configurations' })}></BasePage>

                    {this.renderGrid()}
                    <div id='divSetupConfigurationAlert'/>
                    <SidebarSetupConfigurations
                        SidebarSetupConfigurationsVisible={this.state.SidebarSetupConfigurationsVisible}
                        SelectedConfiguration={this.state.SelectedConfiguration}
                        ClearSelection={() => this.clearSelection()}
                        RefreshConfigurations={() => this.refreshConfigurations()}
                        IsNewConfiguration={this.state.IsNewConfiguration}
                        ChangeConfiguration={this.changeConfiguration.bind(this)}
                        Option='ConfigurationPage'
                    />
                </div>
            </IntlProvider>
        );
    }
}

export default ConfigurationsPage;