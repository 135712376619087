import React from 'react';
import PropTypes from 'prop-types';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, VirtualScroll } from '@syncfusion/ej2-react-grids';
import '../../styles/manage/listSelected.css';
import ImageModel from '../model/ImageModel';
import { statusTemplate } from '../templates/StatusTemplate';
import { deviceIDTemplate, deviceIDTemplateNoAvatar } from '../templates/DeviceIDTemplate';
import { cycleTimeTemplate } from '../templates/CycleTimeTemplate';
import { tOvenTemplate, tFridgeTemplate } from '../templates/TemperatureTemplate';
import { injectIntl, FormattedMessage } from 'react-intl';

class ListSelected extends React.Component {
    constructor(props) {
        super(props);

        this.statusTemplate = statusTemplate.bind(this);
        this.cycleTimeTemplate = cycleTimeTemplate.bind(this);
        this.tOvenTemplate = tOvenTemplate.bind(this);
        this.tFridgeTemplate = tFridgeTemplate.bind(this);

        this.deviceIDTemplate = deviceIDTemplate.bind(this);
        this.deviceIDTemplateNoAvatar = deviceIDTemplateNoAvatar.bind(this);

        this.state = {
            ShowTemperature: true,
            ShowStatus: true,
            ShowDepartment: true
        };

    }

    componentDidUpdate(prevProps) {
        if (prevProps.SelectedDevices !== this.props.SelectedDevices) {
            if (this.props.LicenseType > 1) {
                for (let i = 0; i < this.props.SelectedDevices.length; i++) {
                    if (this.props.SelectedDevices[i].ModelId === 0 || this.props.SelectedDevices[i].ModelId === 99) {
                        if (this.props.SelectedDevices[i].ModelId === 0) {
                            this.setState({ ShowTemperature: false, ShowStatus: true, ShowDepartment: true });
                        }
                        else
                            this.setState({ ShowTemperature: false, ShowStatus: false, ShowDepartment: false });
                    }
                    else
                        this.setState({ ShowTemperature: true, ShowStatus: true, ShowDepartment: true });
                }
            }
        }
    }

    render() {
        if (this.props.SelectedDevices === undefined)
            return null;

        if (this.props.SelectedDevices.length === 0)
            return null;

        if (this.props.SelectedDevices.length === 1) {

            return (
                <div id='divListDevices'>
                    <div className='row rowHeader'>
                        <div className='col-xs-3 col-sm-2 col-md-2 col-lg-2' id='imageModel'>
                            <ImageModel
                                Theme={this.props.Theme}
                                ModelId={this.props.SelectedDevices[0].ModelId}
                                DeviceVersion={this.props.SelectedDevices[0].DeviceVersion}
                                Status={this.props.SelectedDevices[0].Status}
                                DSPROId={this.props.SelectedDevices[0].DSPROId}
                                Height={80} />
                        </div>
                        <div className='col-xs-9 col-sm-10 col-md-10 col-lg-10'>
                            <div className='row rowInfo'>
                                <div className='col-xs-6 col-sm-4 col-md-5 col-lg-6' ><span id='lblDescription'><FormattedMessage id='id_trolley' /></span><span><div className='lblValue'>{this.deviceIDTemplateNoAvatar(this.props.SelectedDevices[0])}</div></span></div>
                                <div className='col-xs-6 col-sm-8 col-md-7 col-lg-6'><span id='lblDescription'><FormattedMessage id='model' /></span><span><div className='lblValue'>{this.props.SelectedDevices[0].Model}</div></span></div>
                            </div>
                            <div className='row rowInfo'>
                                <div className='col-xs-6 col-sm-4 col-md-5 col-lg-6'><span id='lblDescription'><FormattedMessage id='site' /></span><span><div className='lblValue'>{this.props.SelectedDevices[0].Site}</div></span></div>
                                {this.state.ShowDepartment ? <div className='col-xs-6 col-sm-8 col-md-7 col-lg-6'><span id='lblDescription'><FormattedMessage id='department' /></span><span><div className='lblValue'>{this.props.SelectedDevices[0].Department}</div></span></div> : null}
                            </div>
                            {this.state.ShowStatus ?
                                <div className='row rowInfo'>
                                    <div className='col-xs-6 col-sm-4 col-md-5 col-lg-6'><span id='lblDescription'><FormattedMessage id='status' /></span><span><div className='lblValue'>{this.statusTemplate(this.props.SelectedDevices[0])}</div></span></div>
                                    <div className='col-xs-6 col-sm-8 col-md-7 col-lg-6'><span id='lblDescription'><FormattedMessage id='cycletime' /></span><span><div className='lblValue'>{this.cycleTimeTemplate(this.props.SelectedDevices[0])}</div></span></div>
                                </div> : null}
                            {this.state.ShowTemperature ?
                                <div className='row rowInfoLast'>
                                    <div className='col-xs-6 col-sm-4 col-md-5 col-lg-6'><span id='lblDescription'><FormattedMessage id='toven' /></span><span><div className='lblValue'>{this.tOvenTemplate(this.props.SelectedDevices[0])}</div></span></div>
                                    <div className='col-xs-6 col-sm-8 col-md-7 col-lg-6'><span id='lblDescription'><FormattedMessage id='tfridge' /></span><span><div className='lblValue'>{this.tFridgeTemplate(this.props.SelectedDevices[0])}</div></span></div>
                                </div> : null}
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div id='divListDevices'>
                    <GridComponent id='monitorGridSelected'
                        ref={grid => this.gridInstanceSelected = grid}
                        dataSource={this.props.SelectedDevices}
                        enableVirtualization={true}
                        enableHover={false} height='140px'
                        allowSorting={false} allowSelection={false}>
                        <ColumnsDirective>
                            <ColumnDirective field='DeviceSN' visible={false} headerText='KEY' width='0' isPrimaryKey={true}></ColumnDirective>
                            <ColumnDirective field='DeviceID' headerText={this.props.intl.formatMessage({ id: 'id_trolley' })} template={this.deviceIDTemplate} width='20%'></ColumnDirective>
                            <ColumnDirective field='Model' headerText={this.props.intl.formatMessage({ id: 'model' })} width='20%'></ColumnDirective>
                            <ColumnDirective field='Status' headerText={this.props.intl.formatMessage({ id: 'status' })} width='20%' template={this.statusTemplate} />
                            <ColumnDirective field='Site' headerText={this.props.intl.formatMessage({ id: 'site' })} width='20%' />
                            <ColumnDirective field='Department' headerText={this.props.intl.formatMessage({ id: 'department' })} width='20%'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[VirtualScroll]} />
                    </GridComponent>
                </div>
            );
        }
    }
}

ListSelected.propTypes = {
    SelectedDevices: PropTypes.any,
    intl: PropTypes.object,
    UmTemperature: PropTypes.string,
    Theme: PropTypes.string,
    Alarms: PropTypes.array,
    LicenseType: PropTypes.number
};

export default injectIntl(ListSelected);