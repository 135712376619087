import * as React from 'react';
import PropTypes from 'prop-types';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/dialogSet.css';
import { injectIntl, FormattedMessage } from 'react-intl';

class DialogSet extends React.Component {

  constructor() {
    super(...arguments);
    this.fields = { text: 'text', value: 'id' };

  }

  dialogSetCancel() {
    this.props.HideSet();
  }

  dialogSetSave() {
    if (this.comboLanguage.itemData === undefined) { alert(this.props.intl.formatMessage({ id: 'alert_save_language' })) }
    else {
      let settings = { Language: this.comboLanguage.itemData.id, UmTemperature: this.comboTemperature.itemData.id, Theme: this.comboTheme.itemData.id, DateTimeFormat: this.comboDateTime.itemData.id };
      this.props.HideAndSaveSet(settings);
    }
  }

  flagTemplate(props) {
    var flag = '/images/flags/' + props.id + '.png';

    return (
      <div className='row'>
        <div className='col-xs-8'>
          <span className='textLanguage' >{props.text}</span>
        </div>
        <div className='col-xs-4'>
          <img className='imagesFlags' src={flag} />
        </div>
      </div>
    );
  }

  renderSettings() {
    let cloneLanguages = JSON.parse(JSON.stringify(this.props.Languages));
    for (let i = 0; i < cloneLanguages.length; i++)
      cloneLanguages[i].text = this.props.intl.formatMessage({ id: cloneLanguages[i].text });

    let themes = JSON.parse(JSON.stringify(this.props.Themes));
    for (let i = 0; i < themes.length; i++)
      themes[i].text = this.props.intl.formatMessage({ id: themes[i].text })

    return (
      <div id='renderSetting'>
        <div>
          <span className='titleDialog'><FormattedMessage id='menuheader.setup' /></span>
        </div>
        <div className='divDropDownSettings'>
          <div className='row language'>
            <div className='col-xs-3' id='lblDescription'>
              <span className='lblDescription'><FormattedMessage id='language' /></span>
            </div>
            <div className='col-xs-8'>
              <DropDownListComponent id='language'
                ref={combo => this.comboLanguage = combo}
                fields={this.fields}
                dataSource={cloneLanguages}
                value={this.props.AppSettings.Language}
                itemTemplate={this.itemTemplate = this.flagTemplate.bind(this)}
                placeholder={this.props.intl.formatMessage({ id: 'select_language' })} />
            </div>
          </div>

          <div className='row temperature'>
            <div className='col-xs-3' id='lblDescription'>
              <span className='lblDescription' ><FormattedMessage id='temperature' /></span>
            </div>
            <div className='col-xs-8'>
              <DropDownListComponent id='temperatura'
                ref={combo => this.comboTemperature = combo}
                fields={this.fields}
                dataSource={this.props.UmTemperatures}
                value={this.props.AppSettings.UmTemperature}
                placeholder={this.props.intl.formatMessage({ id: 'select_temperature' })} />
            </div>
          </div>

          <div className='row theme'>
            <div className='col-xs-3' id='lblDescription'>
              <span className='lblDescription'><FormattedMessage id='themes' /></span>
            </div>
            <div className='col-xs-8'>
              <DropDownListComponent id='theme'
                ref={combo => this.comboTheme = combo}
                fields={this.fields}
                dataSource={themes}
                value={this.props.AppSettings.Theme}
                placeholder={this.props.intl.formatMessage({ id: 'select_theme' })} />
            </div>
          </div>

          <div className='row formatDateTime'>
            <div className='col-xs-3' id='lblDescription'>
              <span className='lblDescription'><FormattedMessage id='date_time_format' /></span>
            </div>
            <div className='col-xs-8'>
              <DropDownListComponent id='dateTime'
                ref={combo => this.comboDateTime = combo}
                fields={this.fields}
                dataSource={this.props.DateTimeFormat}
                value={this.props.AppSettings.DateTimeFormat}
                placeholder={this.props.intl.formatMessage({ id: 'select_datetime_format' })} />
            </div>
          </div>

        </div>

        <div className='row divBtnSet'>
          <div className='col-xs-6' >
            <ButtonComponent id='btnSet' onClick={this.dialogSetCancel.bind(this)}><FormattedMessage id='cancel' /></ButtonComponent>
          </div>
          <div className='col-xs-6' >
            <ButtonComponent id='btnSet' onClick={this.dialogSetSave.bind(this)}><FormattedMessage id='save' /></ButtonComponent>
          </div>
        </div>
      </div>

    );
  }

  render() {
    return (
      <div className='divBlockDialog'>
        <div className='dialog'>
          {this.renderSettings()}
        </div>
        <div className='e-dlg-overlay' />
      </div>
    );
  }
}


DialogSet.propTypes = {
  HideSet: PropTypes.func,
  HideAndSaveSet: PropTypes.func,
  Languages: PropTypes.array,
  UmTemperatures: PropTypes.array,
  intl: PropTypes.object,
  Themes: PropTypes.array,
  AppSettings: PropTypes.object,
  DateTimeFormat: PropTypes.array
};

export default injectIntl(DialogSet);