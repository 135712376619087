import React from 'react';
import PropTypes from 'prop-types';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import Toast from '../components/Toast';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/sidebarHistoryAlarms.css';
import { injectIntl, FormattedMessage } from 'react-intl';

class SidebarSetupAlarmsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            LocalSidebarManageVisible: 0,
            Description: this.props.SelectedAlarm,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.SidebarAlarmsVisible !== this.props?.SidebarAlarmsVisible) {
            this.setState({ LocalSidebarManageVisible: this.props.SidebarAlarmsVisible });
        }

        if(prevProps?.SelectedAlarm !== this.props?.SelectedAlarm)
        this.setState({Description: this.props.SelectedAlarm});
    }

    saveDescription(){
        this.props.SaveDescription(this.state.Description);
    }

    changeDescription(e){
        this.setState({Description: { ...this.state.Description, CustomDescription: e.target.value }});
    }

    renderAlarmsDetail() {
        if ((!this.props?.SidebarAlarmsVisible) || (this.props?.SelectedAlarm === null))
            return null;
        else
            return (
                <div id='divAlarm'>
                    <Toast
                        Notification={this.props.Notification}
                        Option='configurations'
                    />

                    <div className=' divAlarmHeader'>
                        <div id='testDivAlarm'>
                            <div className='rowInfo' id='testAlarm'>
                                <span className='lblTitle'>{this.props.SelectedAlarm.ID} </span>
                            </div>
                        </div>
                        <div className='row'> 
                        <div className='col-xs-6'></div>
                        <div className='col-xs-6' id='divBtnSaveConfiguration'>
                            <ButtonComponent id='btnSaveConfiguration' className='Button'
                                onClick={() => this.saveDescription()}>
                                <FormattedMessage id='configuration_save' /></ButtonComponent>
                        </div>
                        </div>
                        <div id='divHistoryAlarmDetailTest'>
                            <div id='divHistorySetupAlarm'>
                                <span className='lblDescription'><FormattedMessage id='alarm_description' /></span>
                                <TextBoxComponent className='txtEdit' type='text' name='Description' multiline={true} readOnly={true}
                                    floatLabelType='Auto' cssClass='sample'
                                    value={this.props.SelectedAlarm.Description || ''} id='txtDescription' placeholder='' 
                                    onChange={this.changeDescription.bind(this)}
                                    />
                            </div>
                            <div id='divHistoryAlarmDetail'>
                                <span className='lblDescription'><FormattedMessage id='custom_alarm_description' /> </span>
                                <TextBoxComponent className='txtEdit' type='text' name='CustomDescription' multiline={true}
                                    floatLabelType='Auto'
                                    value={this.props.SelectedAlarm.CustomDescription || ''} id='txtCustomDescription' placeholder='' 
                                    onChange={this.changeDescription.bind(this)}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            );
    }

    renderButtonsSidebarManage() {
        return (
            <div id='divButtonsSidebar'>
                <div id='divButtonClear'>
                    <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                </div>
                <div id='divButtonPrev'>
                    <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeAlarmHistory(-1)} iconCss='e-icons e-sidebarprev' />
                </div>
                <div id='divButtonNext'>
                    <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeAlarmHistory(+1)} iconCss='e-icons e-sidebarnext' />
                </div>
            </div>
        );
    }

    renderContentSidebarHistoryAlarms() {
        if (this.state?.LocalSidebarManageVisible === 1)
            return (
                <div>
                    {this.renderButtonsSidebarManage()}
                    {this.renderAlarmsDetail()}
                </div>
            );
    }

    render() {
        let widthSidebar = { width: '0px' };

        if (this.state?.LocalSidebarManageVisible !== 0)
            widthSidebar = { width: '50%' };

        return (
            <div id='sidebarHistoryAlarms' style={widthSidebar}>
                {this.renderContentSidebarHistoryAlarms()}
            </div>
        );

    }
}

SidebarSetupAlarmsPage.propTypes = {
    SidebarAlarmsVisible: PropTypes.number,
    SelectedAlarm: PropTypes.any,
    ClearSelection: PropTypes.func,
    ChangeAlarmHistory: PropTypes.func,
    SaveDescription: PropTypes.func,
    Notification: PropTypes.array
};

export default injectIntl(SidebarSetupAlarmsPage);