import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/historyAlarmsPage.css';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort, ExcelExport } from '@syncfusion/ej2-react-grids';
import BasePage from '../components/BasePage';
import SidebarSetupAlarmsPage from '../components/SidebarSetupAlarmsPage.js';

import { L10n, setCulture } from '@syncfusion/ej2-base';

import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';

class SetupAlarmsPage extends React.Component {

    constructor(props) {
        super(props);

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            Alarms: [],
            SelectedAlarm: [],
            SidebarAlarmsVisible: 0,
        };

        let initialState = { Language: this.state.Language };
        this.vm = dotnetify.react.connect('SetupAlarmsVM', this, { vmArg: initialState });
    }

    updateLanguage() {
        let commonData = CommonDataManager.getInstance();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('SetupAlarmsPage');
    }

    componentWillUnmount() {
        if (this.vm !== undefined)
            this.vm.$destroy();

        if (this.commonData !== undefined)
            this.commonData.unsubscribe(this.updateLanguage);
    }

    componentDidUpdate() {
        if (this.gridInstance !== undefined)
            this.gridInstance.hideSpinner();
    }

    saveDescription(e) {
        this.vm.$dispatch({ SaveAlarm: e });
    }

    clearSelection() {
        let cloneAlarms = JSON.parse(JSON.stringify(this.state.Alarms));

        for (let i = 0; i < cloneAlarms.length; i++)
            cloneAlarms[i].Selected = false;

        this.setState({ Alarms: cloneAlarms, SidebarAlarmsVisible: 0, SelectedAlarm: [] });
    }

    changeAlarmHistory(direction) {

        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state?.SelectedAlarm?.ID);

        if (direction === -1)
            selectedRowIndex--;

        if (direction === 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) return;

        if (selectedRowIndex > pageRows.length - 1) return;

        let newId = pageRows[selectedRowIndex].ID;

        let cloneAlarmsData = JSON.parse(JSON.stringify(this.state.Alarms));

        let rowHeight = this.gridInstance.getRows()[selectedRowIndex].scrollHeight;
        window.scrollBy({ top: rowHeight * direction, behavior: 'smooth' });

        for (let i = 0; i < cloneAlarmsData.length; i++)
            cloneAlarmsData[i].Selected = cloneAlarmsData[i].ID === newId;

        this.setState({ Alarms: cloneAlarmsData, SelectedAlarm: cloneAlarmsData.filter(h => h.Selected)[0] });
    }

    rowAlarmSelected() {
        var selected;
        var id = -1;

        if (this.gridInstance.getSelectedRecords().length === 1) {
            id = this.gridInstance.getSelectedRecords()[0].ID;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        if (selected === undefined)
            id = -1;
        else
            if (selected.Selected)
                id = -1;

        var cloneAlarms = JSON.parse(JSON.stringify(this.state.Alarms));

        for (let i = 0; i < cloneAlarms.length; i++) {
            cloneAlarms[i].Selected = (cloneAlarms[i].ID === id);
        }

        this.setState({ Alarms: cloneAlarms, SidebarAlarmsVisible: (id != -1) ? 1 : 0, SelectedAlarm: selected });
    }


    gridTemplate() {

        return (
            <div id='divHistoryAlarmsGrid'>
                <GridComponent id='historyAlarmsGrid' ref={grid => this.gridInstance = grid}
                    dataSource={this.state.Alarms} enableHover={true}
                    allowFiltering={true} enableToggle={true}
                    allowExcelExport={true}
                    filterSettings={{ type: 'Checkbox', checkBoxOnly: 'true' }}
                    allowSorting={true} allowSelection={true}
                    delayUpdate={true} allowTextWrap={true}
                    rowSelected={this.rowAlarmSelected.bind(this)}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='Selected' width='35px' headerText='' allowFiltering={false} displayAsCheckBox={true}></ColumnDirective>
                        <ColumnDirective field='ID' headerText={this.intl.formatMessage({ id: 'alarm' })} width='10%' isPrimaryKey={true} textAlign='Center' exportexcel={true}></ColumnDirective>
                        <ColumnDirective field='Description' headerText={this.intl.formatMessage({ id: 'alarm_description' })} exportexcel={true}></ColumnDirective>
                        <ColumnDirective field='CustomDescription' headerText={this.intl.formatMessage({ id: 'custom_alarm_description' })} exportexcel={true}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Filter, Sort, ExcelExport]} />
                </GridComponent>
            </div>
        );
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divToolbarHistoryAlarms'>
                    <BasePage title={this.intl.formatMessage({ id: 'menuheader.alarms' })} navigation={this.intl.formatMessage({ id: 'menuheader.alarms' })}></BasePage>
                    {this.gridTemplate()}
                    <SidebarSetupAlarmsPage
                        SidebarAlarmsVisible={this.state.SidebarAlarmsVisible}
                        SelectedAlarm={this.state.SelectedAlarm}
                        ClearSelection={this.clearSelection.bind(this)}
                        ChangeAlarmHistory={this.changeAlarmHistory.bind(this)}
                        SaveDescription={this.saveDescription.bind(this)}
                        Notification={this.state.Notification}
                    />
                </div>
            </IntlProvider >
        );
    }
}

SetupAlarmsPage.propTypes = {
};

export default SetupAlarmsPage;