import React from 'react';
import AvatarModel from '../model/AvatarModel';
import CommonDataManager from '../../components/CommonDataManager';

export function deviceIDTemplate(props) {
    this.commonData = CommonDataManager.getInstance();
    this.theme = this.commonData.getTheme();

    let deviceID = props.DeviceID;
    if (props?.DSPROId !== -1)
        deviceID = deviceID + ' - ' + props.DSPRODeviceID;

    return (
        <div>
            <AvatarModel 
                Theme={this.theme} 
                ModelId={props.ModelId} 
                DeviceVersion={props.DeviceVersion} 
                Width={40} 
                Height={40} 
                Status={props.Status} 
                DSPROId={props.DSPROId} 
                />
            <div>
                <span id='DeviceIDText'>{deviceID}</span>
            </div>
        </div>);
}

export function deviceIDTemplateNoAvatar(props) {
    this.commonData = CommonDataManager.getInstance();
    this.theme = this.commonData.getTheme();

    let deviceID = props.DeviceID;
    if (props?.DSPROId !== -1)
        deviceID = deviceID + ' - ' + props.DSPRODeviceID;

    return (
        <div>
            <div>
                <span id='DeviceIDText'>{deviceID}</span>
            </div>
        </div>);
}