import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ListSelected from './manage/ListSelected';
import RemoteControl from './manage/RemoteControl';
import ChartControl from './manage/ChartControl';
import MenuConfigurations from './manage/MenuConfigurations';
import '../styles/sidebarManage.css';
import DialogAlert from './DialogAlert';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import dotnetify from 'dotnetify';
import Toolbar from '../components/Toolbar';
import CommonDataManager from '../components/CommonDataManager';
import DialogPasteResults from '../components/DialogPasteResults';

import { injectIntl, FormattedMessage } from 'react-intl';

class SidebarManage extends React.Component {

    constructor(props) {
        super(props);

        this.commonData = CommonDataManager.getInstance();

        this.state = {
            SelectedToolbarItem: 'realtime',
            LocalSidebarManageVisible: 0,
            DataDetail: [],
            IsCommSis: null,
            IsPROSERV: null
        };
    }

    remoteControl(cmd) {
        for (let i = 0; i < this.props?.SelectedDevices.length; i++) {
            if (this.props.SelectedDevices[i].ModelId === this.commonData.getTAGModelId()) {
                if (this.props.SelectedDevices[i].DSPROId !== -1)
                    this.vm.$dispatch({ RemoteControl: { Data: cmd, Id: this.props.SelectedDevices[i].DSPROId } });
                else
                    this.vm.$dispatch({ RemoteControl: { Data: cmd, Id: this.props.SelectedDevices[i].Id } });
            }
            else
                this.vm.$dispatch({ RemoteControl: { Data: cmd, Id: this.props.SelectedDevices[i].Id } });
        }
    }

    setData(data) {
        for (let i = 0; i < this.props?.SelectedDevices?.length; i++) {

            let deviceData = JSON.stringify({ Id: data.Id, DeviceID: data.DeviceID, DepartmentId: data.DepartmentId, Department: data.Department, SiteId: data.SiteId, Site: data.Site });

            this.vm?.$dispatch({ SetDeviceData: deviceData });
        }
    }

    setCycles(cycles) {
        for (let i = 0; i < this.props?.SelectedDevices?.length; i++) {
            this.vm?.$dispatch({ SetCycles: { Data: JSON.stringify(cycles), Id: this.props.SelectedDevices[i].Id } });
        }
    }

    setStarts(starts) {
        for (let i = 0; i < this.props?.SelectedDevices?.length; i++) {
            this.vm?.$dispatch({ SetStarts: { Data: JSON.stringify(starts), Id: this.props.SelectedDevices[i].Id } });
        }
    }

    setParams(params) {
        for (let i = 0; i < this.props?.SelectedDevices?.length; i++) {
            this.vm.$dispatch({ SetParams: { Data: JSON.stringify(params), Id: this.props.SelectedDevices[i].Id } });
        }
    }

    setRefrigerations(params) {
        for (let i = 0; i < this.props?.SelectedDevices?.length; i++) {
            this.vm.$dispatch({ SetParams: { Data: JSON.stringify(params), Id: this.props.SelectedDevices[i].Id } });
        }
    }

    copyConfiguration(data) {
        this.vm.$dispatch({ CopyConfiguration: data });
    }

    pasteConfiguration(enabled) {
        let listDevices = [];
        for (let i = 0; i < this.props?.SelectedDevices?.length; i++)
            listDevices.push(this.props.SelectedDevices[i].Id);

        let pasteSelection = { ...enabled, ListDevices: listDevices };

        this.vm.$dispatch({ PasteConfiguration: pasteSelection });
    }


    loadConfiguration(SelectedConfiguration) {
        this.vm.$dispatch({ LoadConfiguration: SelectedConfiguration.Id });
    }

    saveConfiguration(data) {
        this.vm.$dispatch({ SaveConfiguration: data });
    }

    filterTemperaturesHistory(id, date) {
        if (id && date)
            this.vm?.$dispatch({ FilterHistoryTemperatures: { id: id, FromDate: date, ToDate: date } });
    }

    hideAlert() {
        ReactDOM.unmountComponentAtNode(document.querySelector('#divMonitorAlert'));
    }
    componentWillUnmount() {
        this.vm?.$destroy();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.Notification !== this.state.Notification) {
            if (this.state?.Notification?.Type === -1) {

                ReactDOM.render(
                    <DialogAlert
                        intl={this.props.intl}
                        AlertType={1}
                        Title={this.props.intl.formatMessage({ id: 'warning' })}
                        Message={this.props.intl.formatMessage({ id: '_', defaultMessage: this.state.Notification.Message }, this.commonData.getMessages())}
                        Confirm={this.hideAlert.bind(this)}
                    />, document.querySelector('#divMonitorAlert'));
            }
        }

        if ((prevState.PasteState !== this.state.PasteState) || (prevState.PasteResults !== this.state.PasteResults))
            if (this.state.PasteState !== 0) {

                let cloneSelectedDevices = JSON.parse(JSON.stringify(this.props.SelectedDevices));

                for (let i = 0; i < this.state.PasteResults.length; i++) {
                    let d = cloneSelectedDevices.filter(d => d.Id === this.state.PasteResults[i].Id)[0];

                    if (d)
                        d.Result = this.state.PasteResults[i].Result;
                }

                ReactDOM.render(<DialogPasteResults
                    intl={this.props.intl}
                    Title={''}
                    Message={''}
                    PasteState={this.state.PasteState}
                    Confirm={() => this.setState({ PasteState: 0 })}
                    Theme={this.props.Theme}
                    SelectedDevices={cloneSelectedDevices} />, document.querySelector('#divMonitorAlert'));

            }
            else {
                ReactDOM.unmountComponentAtNode(document.querySelector('#divMonitorAlert'));
            }

        if (prevProps?.SidebarManageVisible != this.props?.SidebarManageVisible) {
            if (this.props.SidebarManageVisible === 0) {

                this.setState({ LocalSidebarManageVisible: this.props.SidebarManageVisible });
                this.vm?.$destroy();
                this.vm = undefined;
            }

            if ((this.props.SidebarManageVisible === 0) || (this.props.SidebarManageVisible === 1)) {
                this.setState({ SelectedToolbarItem: 'realtime' });
            }
        }
        if (prevProps.SelectedDevices !== this.props.SelectedDevices) {
            this.setState({
                IsPROSERV: this.props.SelectedDevices.filter(d => d.ModelId === this.commonData.getPROSERVModelId()).length === 1,
                IsCommSis: this.props.LicenseType > 1 && this.props.SelectedDevices.filter(d => d.ModelId === 0).length === 1 && this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length === 1
            });

        }

        if ((this.props?.SidebarManageVisible === 2) || (this.props?.SidebarManageVisible === 1) || (this.state?.LocalSidebarManageVisible === 2)) {
            if (this.props.SelectedDevices !== undefined) {
                if (this.props.SelectedDevices.length === 1) {

                    if (prevProps.SelectedDevices.length === 0) {
                        if (this.vm === undefined) {
                            let initialState = { DeviceID: this.props.SelectedDevices[0].Id };
                            this.vm = dotnetify.react.connect('MonitorDetailVM', this, { vmArg: initialState });
                        }
                        else {

                            this.vm.$dispatch({ DeviceID: this.props.SelectedDevices[0].Id });
                        }
                    }
                    else
                        if (prevProps.SelectedDevices[0].Id != this.props.SelectedDevices[0].Id) {

                            if (this.vm === undefined) {
                                let initialState = { DeviceID: this.props.SelectedDevices[0].Id };
                                this.vm = dotnetify.react.connect('MonitorDetailVM', this, { vmArg: initialState });
                            }
                            else {

                                this.vm.$dispatch({ DeviceID: this.props.SelectedDevices[0].Id });
                            }
                        }
                }
            }
        }

    }

    toolbarSelect(titleMonitor) {
        this.setState({ SelectedToolbarItem: titleMonitor });
    }

    renderToolbar() {
        let toolbarItems = [{ Title: 'realtime' }];

        if (this.state.IsPROSERV) {
            toolbarItems.pop([{ Title: 'realtime' }])
        }

        let historyEnabled = false;
        if (this.props?.SelectedDevices)
            if (this.props.SelectedDevices.length === 1) {
                historyEnabled = this.props?.SitesPermissions.filter(p => p.SiteId === this.props?.SelectedDevices[0]?.SiteId && p.PermissionHistory).length === 1;
                historyEnabled = historyEnabled || (this.props?.DeparmentsPermissions.filter(p => p.DepartmentId === this.props?.SelectedDevices[0]?.DepartmentId && p.PermissionHistory).length === 1);

                if (historyEnabled)
                    historyEnabled = historyEnabled && !this.state.IsPROSERV;

                if (historyEnabled)
                    historyEnabled = historyEnabled && !this.state.IsCommSis;

                if (historyEnabled)
                    toolbarItems.push({ Title: 'history' });
            }

        let configurationEnabled = false;
        if (this.props?.SelectedDevices?.length === 1) {
            configurationEnabled = this.props?.SitesPermissions.filter(p => p.SiteId === this.props?.SelectedDevices[0]?.SiteId && p.PermissionManage).length === 1;
            configurationEnabled = configurationEnabled || (this.props?.DeparmentsPermissions.filter(p => p.DepartmentId === this.props?.SelectedDevices[0]?.DepartmentId && p.PermissionManage).length === 1);

            if (window.innerWidth < this.commonData.getMobileWidth())
                configurationEnabled = false;

            if (configurationEnabled)
                toolbarItems.push({ Title: 'configurations' });
        }

        if (this.props?.SelectedDevices?.length != 1 || toolbarItems.length < 1)
            return null;
        else
            return (
                <Toolbar
                    Option='sidebarManage'
                    Items={toolbarItems}
                    ToolbarSelect={this.toolbarSelect.bind(this)} />
            );
    }

    renderChartControlRealtime() {

        if (this.props?.SelectedDevices?.length === 1 && !this.state.IsCommSis && !this.state.IsPROSERV) {
            return (
                <ChartControl
                    Realtime={true}
                    SelectedDevice={this.getSelectedDevices()}
                    IsEvo={this.props.SelectedDevices[0].IsEvo}
                    DataDetailHistory={this.state.DataDetailRealtime}
                    UmTemperature={this.props.UmTemperature}
                />
            );
        }
    }

    getSelectedDevices() {
        let selectedDevice;
        if (this.props?.SelectedDevices)
            if (this.props.SelectedDevices.length === 1)
                selectedDevice = Object.fromEntries(
                    Object.entries(this.props.SelectedDevices[0])
                        .filter(([key]) => ['Id', 'DeviceID', 'DeviceSN', 'Site', 'Department'].includes(key))
                );
        return (
            selectedDevice
        );
    }

    renderRealtime() {
        let isMultiSelected = this.props?.SelectedDevices.length > 1;

        let remoteControlEnabled = false;
        if (this.props?.SelectedDevices)
            if (this.props.SelectedDevices.length > 0) {
                remoteControlEnabled = this.props?.SitesPermissions.filter(p => p.SiteId === this.props?.SelectedDevices[0]?.SiteId && p.PermissionRemoteControl).length > 0;
                remoteControlEnabled = remoteControlEnabled || (this.props?.DeparmentsPermissions.filter(p => p.DepartmentId === this.props?.SelectedDevices[0]?.DepartmentId && p.PermissionRemoteControl).length > 0);

                if (this.props.SelectedDevices.filter(d => d.ModelId === this.commonData.getTAGModelId()).length === 1) {
                    for (let i = 0; i < this.props.SelectedDevices.length; i++) {
                        let alarmsVisible = this.props?.SelectedDevices[i]?.Alarms?.length > 0;
                        if ((this.props.SelectedDevices[i].DSPROId !== -1) || alarmsVisible) {
                            remoteControlEnabled = true;
                        }
                        else
                            remoteControlEnabled = false;
                    }
                }


            }


        if (this.state?.SelectedToolbarItem === 'realtime' && !this.state.IsPROSERV || (this.state?.SelectedToolbarItem === 'configurations' && isMultiSelected))
            return (
                <div>
                    {remoteControlEnabled && <RemoteControl
                        SelectedDevices={this.props.SelectedDevices}
                        Detail={this.state.DataDetail}
                        RemoteControl={this.remoteControl.bind(this)} />}
                    {this.renderChartControlRealtime()}
                </div>
            );

        return null;
    }

    renderHistory() {
        if (this.props?.SelectedDevices !== undefined)
            if (this.props.SelectedDevices.length > 0)
                if (this.state.SelectedToolbarItem === 'history' && !this.state.IsCommSis)
                    return (
                        <div>
                            <ChartControl
                                Realtime={false}
                                SelectedDevice={this.getSelectedDevices()}
                                IsEvo={this.props.SelectedDevices[0].IsEvo}
                                DataDetailHistory={this.state.DataDetailHistory}
                                DataDetailHistorySummary={this.state.DataDetailHistorySummary}
                                FilterTemperaturesHistory={this.filterTemperaturesHistory.bind(this)}
                                UmTemperature={this.props.UmTemperature}
                            />
                        </div>
                    );

        return null;
    }

    renderConfigurations() {
        let isMultiSelected = this.props?.SelectedDevices.length > 1;

        let configurationEnabled = this.props?.SitesPermissions.filter(p => p.SiteId === this.props?.SelectedDevices[0]?.SiteId && p.PermissionManage).length === 1;
        configurationEnabled = configurationEnabled || (this.props?.DeparmentsPermissions.filter(p => p.DepartmentId === this.props?.SelectedDevices[0]?.DepartmentId && p.PermissionManage).length === 1);

        if (configurationEnabled)
            if (this.state?.SelectedToolbarItem === 'configurations' && this.props?.SelectedDevices.length === 1 || this.props.Option === 'USBPage' || isMultiSelected)
                return (
                    <div>
                        <MenuConfigurations
                            IsConfigurationMenu={false}
                            SelectedDevices={this.props?.SelectedDevices}
                            Detail={this.state.DataDetail}
                            SetData={this.setData.bind(this)}
                            SetCycles={this.setCycles.bind(this)}
                            SetStarts={this.setStarts.bind(this)}
                            SetParams={this.setParams.bind(this)}
                            SetRefrigerations={this.setRefrigerations.bind(this)}
                            Configurations={this.state.Configurations}
                            CurrentConfiguration={this.state.CurrentConfiguration}
                            CopyConfiguration={this.copyConfiguration.bind(this)}
                            PasteConfiguration={this.pasteConfiguration.bind(this)}
                            LoadConfiguration={this.loadConfiguration.bind(this)}
                            SaveConfiguration={this.saveConfiguration.bind(this)}
                            ListSites={this.state.ListSites}
                            ListDepartments={this.state.ListDepartments}
                            UmTemperature={this.props.UmTemperature}
                            Option={this.props.Option}
                            IsMultiSelected={isMultiSelected}
                            IsCommSis={this.state.IsCommSis}
                            CyclesPermit={this.state.DataDetail?.CyclesPermit}
                            ParamsPermit={this.state.DataDetail?.ParamsPermit}
                            StartsPermit={this.state.DataDetail?.StartsPermit}
                            RefrigerationsPermit={this.state.DataDetail?.RefrigerationsPermit}
                        />
                    </div>
                );

        return null;
    }

    openCloseSidebar(open) {
        if (open)
            this.setState({ LocalSidebarManageVisible: 2 });
        else {
            this.setState({ LocalSidebarManageVisible: 1 });
            if (this.toolbarInstance !== undefined)
                this.toolbarInstance.selectedIndex = 0;
        }
    }

    renderButtonsSidebarManage() {
        let btn;
        let stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            btn = (<ButtonComponent id='btnOpen' cssClass='e-round' onClick={() => this.openCloseSidebar(true)} iconCss='e-icons e-sidebaropen' />);
        if (stateSidebar === 2)
            btn = (<ButtonComponent id='btnClose' cssClass='e-round' onClick={() => this.openCloseSidebar(false)} iconCss='e-icons e-sidebarclose' />);

        if (window.innerWidth < this.commonData.getMobileWidth())
            return (
                <div id='divButtonsSidebar'>
                    <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                    <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeDevice(-1)} iconCss='e-icons e-sidebarprev' />
                    <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeDevice(+1)} iconCss='e-icons e-sidebarnext' />
                </div>
            );
        else
            return (
                <div id='divButtonsSidebar'>
                    <div id='divButtonsOpenClose'>
                        {btn}
                    </div>
                    <div id='divButtonClear'>
                        <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                    </div>
                    {this.props?.SelectedDevices?.length === 1 && <div id='divButtonPrev'>
                        <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeDevice(-1)} iconCss='e-icons e-sidebarprev' />
                    </div>}
                    {this.props?.SelectedDevices?.length === 1 && <div id='divButtonNext'>
                        <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeDevice(+1)} iconCss='e-icons e-sidebarnext' />
                    </div>}
                </div>
            );
    }

    renderContentSidebarManage() {
        if (this.props.SidebarManageVisible === 0)
            return null;

        let stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            return (
                <div>
                    {this.renderButtonsSidebarManage()}
                </div>
            );

        if (stateSidebar === 2 && this.props.Option !== 'USBPage')

            return (
                <div id='divSidebarManage'>
                    {this.renderButtonsSidebarManage()}
                    <ListSelected SelectedDevices={this.props.SelectedDevices}
                        UmTemperature={this.props.UmTemperature}
                        Theme={this.props.Theme}
                        Alarms={this.props.Alarms}
                        LicenseType={this.props.LicenseType}
                    />
                    <div id='divSidebarManage_1'>
                        <div id='divSidebarManage_2'>
                            {this.renderToolbar()}
                            {this.renderRealtime()}
                            {this.renderHistory()}
                            {this.renderConfigurations()}

                        </div>
                    </div>
                </div>
            );
        else
            if (this.props.Option === 'USBPage')
                return (
                    <div id='divsideBarManageUSB'>
                        {this.renderConfigurations()}
                        <div >
                            <ButtonComponent id='btn' onClick={this.props.openManageUSB} ><FormattedMessage id='close' /></ButtonComponent>
                        </div>
                    </div>
                );

    }

    render() {
        let sidebarId = 'sidebarManage';
        if (this.props.Option === 'USBPage') sidebarId = 'divsideBarManageUSB';

        var widthSidebar = '0px';
        var stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            widthSidebar = '60px';

        if (stateSidebar === 2) {
            if (window.innerWidth < this.commonData.getMobileWidth())
                widthSidebar = '100%';
            else
                widthSidebar = '50%';
        }



        return (
            <div ref={Sidebar => this.sidebarobj = Sidebar}
                id={sidebarId} style={{ width: widthSidebar }} >
                {this.renderContentSidebarManage()}
            </div>
        );
    }
}

SidebarManage.propTypes = {
    intl: PropTypes.object,
    SidebarManageVisible: PropTypes.number,
    SelectedDevices: PropTypes.any,
    ClearSelection: PropTypes.func,
    ChangeDevice: PropTypes.func,
    SitesPermissions: PropTypes.array,
    DeparmentsPermissions: PropTypes.array,
    Option: PropTypes.string,
    UmTemperature: PropTypes.string,
    openManageUSB: PropTypes.func,
    Theme: PropTypes.string,
    Alarms: PropTypes.array,
    LicenseType: PropTypes.number
};

export default injectIntl(SidebarManage);
