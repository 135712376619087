import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/historyAlarmsPage.css';
import { GridComponent, Filter, Inject, VirtualScroll, Sort, ExcelExport } from '@syncfusion/ej2-react-grids';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';

import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class HistoryImpactsPage extends React.Component {

    constructor(props) {
        super(props);

        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            HistoryImpactsData: [],
            FromDate: this.today,
            ToDate: this.today,
        };

        let initialState = { Language: this.state.Language, Filter: { FromDate: this.state.FromDate, ToDate: this.state.ToDate } };
        this.vm = dotnetify.react.connect('HistoryImpactsVM', this, { vmArg: initialState });
        this.templateMobile = this.rowTemplate.bind(this);

    }

    updateLanguage() {
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: this.commonData.getLanguage(), Messages: this.commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('HistoryImpactsPage');

        if (this.daterangepickerInstance !== undefined) {
            this.daterangepickerInstance.startDate = this.state.FromDate;
            this.daterangepickerInstance.endDate = this.state.ToDate;
        }
    }

    componentWillUnmount() {
        this.vm?.$destroy();

        this.commonData?.unsubscribe(this.updateLanguage);
    }

    filterDateImpactsHistory() {
        if (this.daterangepickerInstance.startDate !== undefined)
            if (this.daterangepickerInstance.endDate !== undefined) {
                this.setState({ FromDate: this.daterangepickerInstance.startDate, ToDate: this.daterangepickerInstance.endDate })
                this.vm?.$dispatch({ Filter: { FromDate: this.daterangepickerInstance.startDate, ToDate: this.daterangepickerInstance.endDate } });
            }

    }

    exportAlarmsHistory() {
        let pathExcelExport = this.intl.formatMessage({ id: 'menueventsheader.impacts' }) + '_' + this.intl.formatDate(this.daterangepickerInstance.startDate) + '_' + this.intl.formatDate(this.daterangepickerInstance.endDate) + '.xlsx';
        let cloneColumns = JSON.parse(JSON.stringify(this.gridInstance.columns));
        if (this.gridInstance != undefined)
            this.gridInstance.excelExport({ includeHiddenColumn: true, columns: cloneColumns.filter(c => c.exportexcel), fileName: pathExcelExport });
    }

    rowTemplate(props) {

        return (
            <tr className='templateRow'>
                <td className='deviceId'>
                    <table className='deviceText' cellPadding={3} cellSpacing={2}>
                        <colgroup>
                            <col style={{ width: '40%' }} />
                            <col style={{ width: '60%' }} />
                        </colgroup>
                        <tbody>

                            <tr>
                                <td className='detailsTrolley'><b>{this.intl.formatMessage({ id: 'id_trolley' })}</b></td>
                                <td className='textGridMobile_historys'> {props.DeviceID} </td>
                            </tr>
                            <tr>
                                <td className='detailsdate'><b>{this.intl.formatMessage({ id: 'site' })}</b></td>
                                <td className='textGridMobile_historys'>{props.Site} </td>
                            </tr>

                            <tr>
                                <td className='Department'><b>{this.intl.formatMessage({ id: 'department' })}</b></td>
                                <td className='textGridMobile_historys'>{props.Department} </td>
                            </tr>
                        </tbody>
                    </table>
                </td>

                <td className='deviceDetails'>
                    <table className='deviceText' cellPadding={4} cellSpacing={2}>
                        <colgroup>
                            <col style={{ width: '40%' }} />
                            <col style={{ width: '60%' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className='detailsdate'><b>{this.intl.formatMessage({ id: 'date' })}</b></td>
                                <td className='textGridMobile_historys'>{this.intl.formatDate(props.DateTime)} {this.intl.formatTime(props.DateTime)}</td>
                            </tr>
                            <tr>
                                <td className='detailsdate'><b>{this.intl.formatMessage({ id: 'eventType' })}</b></td>
                                <td className='textGridMobile_historys'>{this.eventTemplate(props)} </td>
                            </tr>

                            <tr>
                                <td className='Department'><b>{this.intl.formatMessage({ id: 'value' })}</b></td>
                                <td className='textGridMobile_historys'>{this.templateType(props)} </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    }

    eventTemplate(type) {
        let typeEvent = 'impacts_event_type_' + type.EventType;

        return (
            <div><FormattedMessage id={typeEvent} /></div>
        );
    }

    templateType(value) {
        let valueType = value.Value1;
        if (value.EventType === '1') valueType = value.Value1 + '°';

        return (
            <span>{valueType}</span>
        );
    }

    gridTemplate() {

        let columnsMobile = [
            { headerText: this.intl.formatMessage({ id: 'details' }), exportexcel: false },
            { headerText: '', exportexcel: false },
            { field: 'DateTime', headerText: this.intl.formatMessage({ id: 'date' }), visible: false, type: 'datetime', format: { type: 'dateTime', skeleton: 'short' }, exportexcel: true },
            { field: 'DeviceID', headerText: this.intl.formatMessage({ id: 'id_trolley' }), visible: false, exportexcel: true },
            { field: 'Site', headerText: this.intl.formatMessage({ id: 'site' }), visible: false, exportexcel: true },
            { field: 'DeviceSN', visible: false, exportexcel: true },
            { field: 'Department', headerText: this.intl.formatMessage({ id: 'department' }), visible: false, exportexcel: true },
            { field: 'EventType', headerText: this.intl.formatMessage({ id: 'eventType' }), visible: false, template: this.eventTemplate.bind(this), exportexcel: true },
            { field: 'Value1', headerText: this.intl.formatMessage({ id: 'value' }), visible: false, template: this.templateType, exportexcel: true },

        ];

        let columns = [
            { field: 'Id', visible: false, isPrimaryKey: true },
            { field: 'Selected', visible: false, width: '50px', displayAsCheckBox: true, allowFiltering: false },
            { field: 'DateTime', headerText: this.intl.formatMessage({ id: 'date' }), width: '190px', type: 'datetime', format: { type: 'dateTime', skeleton: 'short' }, exportexcel: true },
            { field: 'DeviceID', headerText: this.intl.formatMessage({ id: 'id_trolley' }), width: '20%', exportexcel: true },
            { field: 'Site', headerText: this.intl.formatMessage({ id: 'site' }), width: '30%', exportexcel: true },
            { field: 'DeviceSN', visible: false, exportexcel: true },
            { field: 'Model', visible: false, exportexcel: false },
            { field: 'Department', headerText: this.intl.formatMessage({ id: 'department' }), width: '30%', exportexcel: true },
            { field: 'EventType', headerText: this.intl.formatMessage({ id: 'eventType' }), width: '30%', template: this.eventTemplate, exportexcel: true },
            { field: 'Value1', headerText: this.intl.formatMessage({ id: 'value' }), width: '30%', template: this.templateType, exportexcel: true },
            { field: 'Value2', headerText: this.intl.formatMessage({ id: 'value' }), width: '30%', exportexcel: false, visible: false }

        ];

        if (window.innerWidth < this.commonData.getMobileWidth())

            return (
                <div>
                    <GridComponent id='historyAlarmsGrid' ref={grid => this.gridInstance = grid}
                        dataSource={this.state?.HistoryImpactsData} columns={columnsMobile}
                        allowExcelExport={true} delayUpdate={true} rowTemplate={this.templateMobile} >
                        <Inject services={[ExcelExport]} />
                    </GridComponent>

                </div>
            );
        else
            return (
                <div id='divHistoryAlarmsGrid'>
                    <GridComponent id='historyAlarmsGrid' ref={grid => this.gridInstance = grid}
                        dataSource={this.state?.HistoryImpactsData} enableHover={true} enableVirtualization={false}
                        allowFiltering={true} enableToggle={true}
                        allowExcelExport={true} columns={columns}
                        filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                        allowSorting={true} allowSelection={true}
                        delayUpdate={true}
                    >
                        <Inject services={[Filter, Sort, VirtualScroll, ExcelExport]} />
                    </GridComponent>
                </div>
            );
    }

    render() {

        let presets = [
            { label: this.intl?.formatMessage({ id: 'today' }), start: this.today, end: this.today },
            { label: this.intl?.formatMessage({ id: 'this_week' }), start: this.weekStart, end: this.weekEnd },
            { label: this.intl?.formatMessage({ id: 'this_month' }), start: this.monthStart, end: this.monthEnd },
            { label: this.intl?.formatMessage({ id: 'last_month' }), start: this.lastStart, end: this.lastEnd },
            { label: this.intl?.formatMessage({ id: 'last_year' }), start: this.yearStart, end: this.yearEnd },
        ];
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divToolbarHistoryAlarms' >
                    <BasePage title={this.intl.formatMessage({ id: 'menueventsheader.impacts' })} navigation={this.intl.formatMessage({ id: 'menueventsheader.impacts' })}></BasePage>
                    <div className='row divToolbarHistoryCell'>
                        <div className='col-sm-12 col-md-10 col-lg-10'><span id='textFilter'><FormattedMessage id='filter' /></span>
                            <DateRangePickerComponent id='datarangeFilter' format={{ type: 'date', skeleton: 'short' }}
                                startDate={this.state.FromDate} endDate={this.state.ToDate}
                                allowEdit={false} delayUpdate={true} presets={presets}
                                change={this.filterDateImpactsHistory.bind(this)}
                                ref={calendar => this.daterangepickerInstance = calendar}>
                            </DateRangePickerComponent>
                        </div>
                        <div className='btnExportHistory col-sm-12 col-md-2 col-lg-2'>
                            <ButtonComponent className='Button'
                                disabled={this.state?.HistoryImpactsData.length <= 0}
                                delayUpdate={true}
                                onClick={() => this.exportAlarmsHistory()}><FormattedMessage id='export' /></ButtonComponent>
                        </div>
                    </div>
                    <div>
                        {this.gridTemplate()}
                    </div>
                </div>

            </IntlProvider >
        );
    }
}

HistoryImpactsPage.propTypes = {};

export default HistoryImpactsPage;