import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import '../../styles/manage/menuConfigurations.css';

import DialogAlert from '../DialogAlert';
import CyclesConfiguration from './CyclesConfiguration';
import DeviceDataConfiguration from './DeviceDataConfiguration';
import ParamsConfiguration from './ParamsConfiguration';
import StartsConfiguration from './StartsConfiguration';
import RefrigerationConfiguration from './RefrigerationConfiguration';
import ProgressBar from '../../components/ProgressBar';
import CommonDataManager from '../../components/CommonDataManager';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort, Search } from '@syncfusion/ej2-react-grids';

import { injectIntl, FormattedMessage } from 'react-intl';
import isEqual from 'react-fast-compare';

class MenuConfigurations extends React.Component {

    constructor(props) {
        super(props);

        this.commonData = CommonDataManager.getInstance();

        this.state = {
            SelectedMenu: -1,
            CyclesEnabled: this.props?.Detail?.CyclesEnabled,
            StartsEnabled: this.props?.Detail?.StartsEnabled,
            ParamsEnabled: this.props?.Detail?.ParamsEnabled,
            RefrigerationsEnabled: this.props?.Detail?.RefrigerationsEnabled,
            SelectedConfiguration: [],
            showDialogAlert: false,
            CloneDataSave: [],
            LoadEnabled: false,
            ConfigurationsLoad: null,
            Option: '',
            DetailPaste: [],
            IsTAG: null,
            IsPROSERV: null,
        };
    }

    componentDidMount() {
        this.visibleBtns();
    }

    visibleBtns() {
        if (this.props.SelectedDevices !== null) {
            this.setState(
                {
                    IsTAG: this.props?.SelectedDevices.filter(d => d.ModelId === this.commonData.getTAGModelId()).length === 1,
                    IsPROSERV: this.props.SelectedDevices.filter(d => d.ModelId === this.commonData.getPROSERVModelId()).length === 1
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.Detail, this.props?.Detail)) {
            this.setState({
                CyclesEnabled: this.props?.Detail?.CyclesEnabled,
                StartsEnabled: this.props?.Detail?.StartsEnabled,
                ParamsEnabled: this.props?.Detail?.ParamsEnabled,
                RefrigerationsEnabled: this.props?.Detail?.RefrigerationsEnabled
            });
        }

        if (this.state.ConfigurationsLoad === null)
            this.setState({ ConfigurationsLoad: this.props?.Configurations });

        if (prevProps?.Configurations !== this.props?.Configurations) {
            this.setState({ ConfigurationsLoad: this.props?.Configurations });
        }

        if (prevState?.SelectedMenu !== this.state?.SelectedMenu)
            if (this.props.MenuSelected !== undefined)
                this.props.MenuSelected(this.state?.SelectedMenu === -1);

        if (prevState.showDialogAlert !== this.state.showDialogAlert)
            if (this.state.showDialogAlert) {

                let idMessage = '';

                if (this.state.Option === 'saveConfiguration') {
                    if (this.state.CloneDataSave.Name === '')
                        idMessage = 'alert_save_name';
                    else
                        idMessage = 'alert_save_data';

                    ReactDOM.render(
                        <DialogAlert
                            intl={this.props.intl}
                            AlertType={1}
                            Title={this.props.intl.formatMessage({ id: 'warning' })}
                            Message={this.props.intl.formatMessage({ id: idMessage })}
                            Confirm={this.hideAlert.bind(this)}
                        />, document.querySelector('#divMonitorAlert'));
                }

                if (this.state.Option === 'pasteConfiguration') {
                    let msg = this.props.intl.formatMessage({ id: 'alert_paste' });
                    msg = msg + '\r\n' + this.props.CurrentConfiguration.Name + ' ?' + '\r\n';

                    ReactDOM.render(
                        <DialogAlert
                            intl={this.props.intl}
                            AlertType={2}
                            Title={this.props.intl.formatMessage({ id: 'configuration_paste' })}
                            Message={msg}
                            Confirm={this.confirmPaste.bind(this)}
                            Cancel={this.hideAlert.bind(this)}
                        />, document.querySelector('#divMonitorAlert'));
                }
            }
            else {
                ReactDOM.unmountComponentAtNode(document.querySelector('#divMonitorAlert'));
            }
        if (prevProps.SelectedDevices !== this.props.SelectedDevices) {
            this.visibleBtns();
        }
    }

    menuClick(index) {
        this.setState({ SelectedMenu: index });
    }

    menuCloseClick() {
        this.setState({ SelectedMenu: -1 });
    }

    menuCloseClear() {
        let cloneConfigurations = JSON.parse(JSON.stringify(this.props?.Configurations));

        for (let i = 0; i < cloneConfigurations.length; i++)
            cloneConfigurations[i].SelectedDevices = false;

        this.setState({ ConfigurationsLoad: cloneConfigurations, SelectedMenu: -1, SelectedConfiguration: [], LoadEnabled: false });
    }

    buttonConfigurationEnable(e) {
        this.setState({ [e.target.name]: e.target.checked });
        this.props.SetFlagsEnabled(e.target.name, e.target.checked);
    }

    renderMenuConfigurations() {

        let visible_MenuTop = true;

        if (this.state.IsPROSERV || this.props.IsConfigurationMenu) { visible_MenuTop = false; }

        let cssIcon = 'e-icons e-FB_Paste';
        let pasteMenuEnabled = (this.props?.CurrentConfiguration);

        let multiselected = 'OneSelected';

        if (this.props.IsMultiSelected) multiselected = 'MultiSelected';

        if (!pasteMenuEnabled) cssIcon = 'e-icons e-FB_PasteDisable';

        if (this.state.SelectedMenu === -1)
            return (
                <div id='divMenuConfigurations'>
                    {visible_MenuTop ?
                        <div id='divMenuTop'>
                            {this.props.IsMultiSelected ? <div>
                                <span className='lblDescription'><FormattedMessage id='configuration' /></span>
                            </div> : null}

                            <div className={'row headerConfig' + this.props.Option + multiselected}>
                                {!this.props.IsMultiSelected ?
                                    <div className={'col-sm-6 col-md-6 col-lg-3 ' + this.props.Option}>
                                        <ButtonComponent id={'btnMenuConfiguration' + multiselected} onClick={() => this.menuClick(1000)} className='ButtonEmpty' iconCss='e-icons e-FB_copy'><FormattedMessage id='configuration_copy' /></ButtonComponent>
                                    </div> : null
                                }
                                <div className={'col-sm-6 col-md-6 col-lg-3 ' + this.props.Option}>
                                    <ButtonComponent id={'btnMenuConfiguration' + multiselected} onClick={() => this.menuClick(1001)} className='ButtonEmpty' disabled={!pasteMenuEnabled} iconCss={cssIcon}><FormattedMessage id='configuration_paste' /></ButtonComponent>
                                </div>
                                {!this.props.IsMultiSelected ?
                                    <div className={'col-sm-6 col-md-6 col-lg-3 ' + this.props.Option}>
                                        <ButtonComponent id={'btnMenuConfiguration' + multiselected} onClick={() => this.menuClick(1002)} className='ButtonEmpty' iconCss='e-icons e-F_PV_Download' ><FormattedMessage id='configuration_load' /></ButtonComponent>
                                    </div> : null
                                }
                                {!this.props.IsMultiSelected ?
                                    <div className={'col-sm-6 col-md-6 col-lg-3 ' + this.props.Option}>
                                        <ButtonComponent id={'btnMenuConfiguration' + multiselected} onClick={() => this.menuClick(1003)} className='Button' iconCss='e-icons e-FB_PV_Save'><FormattedMessage id='configuration_save' /></ButtonComponent>
                                    </div> : null
                                }
                            </div>
                        </div> : null
                    }
                    {!this.props.IsMultiSelected ?
                        <div>
                            {!this.props.IsConfigurationMenu ?
                                <div id='divMenuBottom'>

                                    <div className='row'>
                                        <ButtonComponent id='btnDeviceConfiguration' onClick={() => this.menuClick(0)} className='ButtonEmpty'><FormattedMessage id='configuration_trolley_data' /></ButtonComponent>
                                    </div>
                                    {this.props.CyclesPermit ?
                                        <div className='row'>
                                            <ButtonComponent id='btnCyclesConfiguration' onClick={() => this.menuClick(1)} className='ButtonEmpty'><FormattedMessage id='configuration_cycles' /></ButtonComponent>
                                        </div> : null
                                    }
                                    {this.props.StartsPermit ?
                                        <div className='row'>
                                            <ButtonComponent id='btnStartsConfiguration' onClick={() => this.menuClick(2)} className='ButtonEmpty'><FormattedMessage id='configuration_starts' /></ButtonComponent>
                                        </div> : null
                                    }
                                    {this.props.ParamsPermit ? <div className='row'>
                                        <ButtonComponent id='btnParamsConfiguration' onClick={() => this.menuClick(3)} className='ButtonEmpty'><FormattedMessage id='configuration_params' /></ButtonComponent>
                                    </div> : null}
                                    {this.props.RefrigerationsPermit ?
                                        <div className='row'>
                                            <ButtonComponent id='btnRefrigerationConfiguration' onClick={() => this.menuClick(4)} className='ButtonEmpty'><FormattedMessage id='configuration_refrigeration' /></ButtonComponent>
                                        </div> : null
                                    }
                                </div>
                                : <div id='divMenuBottom'>

                                    {this.props.CyclesPermit ? <div className='row'>
                                        <div className='col-sm-1'>
                                            <CheckBoxComponent name='CyclesEnabled'
                                                checked={this.state.CyclesEnabled}
                                                onChange={this.buttonConfigurationEnable.bind(this)} />
                                        </div>
                                        <div className='col-sm-11'>
                                            <ButtonComponent id='btnCyclesConfiguration'
                                                disabled={!this.state.CyclesEnabled}
                                                onClick={() => this.menuClick(1)}
                                                className='ButtonEmpty'>
                                                <FormattedMessage id='configuration_cycles' /></ButtonComponent>
                                        </div>
                                    </div> : null}

                                    {this.props.StartsPermit ? <div className='row'>
                                        <div className='col-sm-1'>
                                            <CheckBoxComponent
                                                name='StartsEnabled'
                                                checked={this.state.StartsEnabled}
                                                onChange={this.buttonConfigurationEnable.bind(this)} />
                                        </div>
                                        <div className='col-sm-11'>
                                            <ButtonComponent id='btnStartsConfiguration'
                                                disabled={!this.state.StartsEnabled}
                                                onClick={() => this.menuClick(2)}
                                                className='ButtonEmpty'>
                                                <FormattedMessage id='configuration_starts' /></ButtonComponent>
                                        </div>
                                    </div> : null}

                                    {this.props.ParamsPermit ? <div className='row'>
                                        <div className='col-sm-1'>
                                            <CheckBoxComponent
                                                name='ParamsEnabled'
                                                checked={this.state.ParamsEnabled}
                                                onChange={this.buttonConfigurationEnable.bind(this)} />
                                        </div>
                                        <div className='col-sm-11'>
                                            <ButtonComponent id='btnParamsConfiguration'
                                                disabled={!this.state.ParamsEnabled}
                                                onClick={() => this.menuClick(3)}
                                                className='ButtonEmpty'>
                                                <FormattedMessage id='configuration_params' /></ButtonComponent>
                                        </div>

                                    </div> : null}

                                    {this.props.RefrigerationsPermit ?
                                        <div className='row'>
                                            <div className='col-sm-1'>
                                                <CheckBoxComponent
                                                    name='RefrigerationsEnabled'
                                                    checked={this.state.RefrigerationsEnabled}
                                                    onChange={this.buttonConfigurationEnable.bind(this)} />
                                            </div>
                                            <div className='col-sm-11'>
                                                <ButtonComponent id='btnRefrigerationConfiguration'
                                                    disabled={!this.state.RefrigerationsEnabled}
                                                    onClick={() => this.menuClick(4)}
                                                    className='ButtonEmpty'>
                                                    <FormattedMessage id='configuration_refrigeration' /></ButtonComponent>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            }</div> : null}
                </div >
            )
    }

    renderDeviceDataConfiguration() {
        let s = undefined;

        if (Array.isArray(this.props.SelectedDevices)) {
            if (this.props.SelectedDevices.length > 0)
                s = this.props.SelectedDevices[0];
        }
        else
            s = this.props.SelectedDevices;

        if (this.state.SelectedMenu === 0)
            return (
                <DeviceDataConfiguration
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    SelectedDevices={s}
                    Detail={this.props.Detail}
                    SetData={this.props.SetData}
                    ListSites={this.props.ListSites}
                    ListDepartments={this.props.ListDepartments}
                    Option={this.props.Option}
                    IsCommSis={this.props.IsCommSis} />
            );
    }

    renderCyclesConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.SelectedDevices)) {
                if (this.props.SelectedDevices.length > 0)
                    s = this.props.SelectedDevices[0];
            }
            else
                s = this.props.SelectedDevices;
        if (this.state.SelectedMenu === 1)
            return (
                <CyclesConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    SelectedDevices={s}
                    Detail={this.props.Detail}
                    SetCycles={this.props.SetCycles}
                    UmTemperature={this.props.UmTemperature}
                    Option={this.props.Option}
                    IsCommSis={this.props.IsCommSis} />
            );
    }

    renderStartsConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.SelectedDevices)) {
                if (this.props.SelectedDevices.length > 0)
                    s = this.props.SelectedDevices[0];
            }
            else
                s = this.props.SelectedDevices;

        if (this.state.SelectedMenu === 2)
            return (
                <StartsConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    SelectedDevices={s}
                    Detail={this.props.Detail}
                    SetStarts={this.props.SetStarts}
                    Option={this.props.Option}
                    IsCommSis={this.props.IsCommSis} />
            );
    }

    renderParamsConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.SelectedDevices)) {
                if (this.props.SelectedDevices.length > 0)
                    s = this.props.SelectedDevices[0];
            }
            else
                s = this.props.SelectedDevices;

        if (this.state.SelectedMenu === 3)
            return (
                <ParamsConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    SelectedDevices={s}
                    Detail={this.props.Detail}
                    SetParams={this.props.SetParams}
                    UmTemperature={this.props.UmTemperature}
                    Option={this.props.Option}
                    IsCommSis={this.props.IsCommSis} />
            );
    }

    renderRefrigerationConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.SelectedDevices)) {
                if (this.props.SelectedDevices.length > 0)
                    s = this.props.SelectedDevices[0];
            }
            else
                s = this.props.SelectedDevices;

        if (this.state.SelectedMenu === 4)
            return (
                <RefrigerationConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    SelectedDevices={s}
                    Detail={this.props.Detail}
                    SetParams={this.props.SetRefrigerations}
                    UmTemperature={this.props.UmTemperature}
                    Option={this.props.Option}
                    IsCommSis={this.props.IsCommSis} />
            );
    }

    copyConfiguration() {
        let copyCycles;
        let copyStarts;
        let copyRefrigerations;
        if (this.state.IsTAG) {
            copyCycles = false;
            copyStarts = false;
            copyRefrigerations = false;
        }
        else {
            copyCycles = this.chkBoxCopyCycles.checked;
            copyStarts = this.chkBoxCopyStarts.checked;
            copyRefrigerations = this.chkBoxCopyRefrigerations.checked;
        }
        let data = {
            Name: this.props.intl?.formatMessage({ id: 'copy_trolley_configuration' }) + ' ' + this.props?.Detail?.DeviceID,
            Description: this.props.intl?.formatMessage({ id: 'site' }) + ' ' + this.props?.Detail?.Site + '\r\n' +
                this.props.intl?.formatMessage({ id: 'department' }) + ' ' + this.props?.Detail?.Department + '\r\n' +
                this.props.intl?.formatMessage({ id: 'model' }) + ' ' + this.props?.Detail?.Model + '\r\n' +
                this.props.intl?.formatMessage({ id: 'at_time' }) + ' ' + this.props.intl.formatDate(new Date()) + ' ' + this.props.intl.formatTime(new Date()),
            CyclesEnabled: copyCycles,
            StartsEnabled: copyStarts,
            ParamsEnabled: this.chkBoxCopyParams.checked,
            RefrigerationsEnabled: copyRefrigerations
        };

        let detailPaste = {
            DeviceVersion: this.props?.Detail.DeviceVersion,
            ModelId: this.props?.Detail.ModelId
        }

        this.setState({ DetailPaste: detailPaste })
        this.props.CopyConfiguration(data);
        this.menuCloseClick();
    }

    renderCopyConfiguration() {

        if (this.state.SelectedMenu === 1000)
            if (this.props?.Detail === null)
                return (
                    <ProgressBar Id='CopyConfiguration' Message={this.props.intl?.formatMessage({ id: 'waiting' })} />
                );
            else
                return (
                    <div id={'divCopyConfig' + this.props.Option}>

                        <div className=' row headerMenuConfiguration'>
                            <div id='divConfigurationTop' className='col-xs-4'>
                                <span className='lblDescription'><FormattedMessage id='configuration_copy' /></span>
                            </div>
                            <div className={'col-xs-8' + ' ' + 'divBtnTop' + this.props.Option} >
                                <ButtonComponent id='btnCopy' className='Button' iconCss='e-icons e-FB_copy' onClick={() => this.copyConfiguration()}><FormattedMessage id='copy' /></ButtonComponent>
                                <ButtonComponent id='btnCloseMenuTop' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClick()}><FormattedMessage id='close' /></ButtonComponent>
                            </div>
                        </div>

                        <div className='row divSelectCopyConfiguration'>
                            <div className='col-xs-12' id='titleCopyConfiguration'>
                                <span className='lblDescription'><FormattedMessage id='select_data_copy' /></span>
                            </div>
                            {!this.state.IsTAG ?
                                <div className='col-xs-12' id='checkboxMenu'>
                                    <CheckBoxComponent ref={chkbox => this.chkBoxCopyCycles = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_cycles' })} />
                                </div> : null}
                            {!this.state.IsTAG ?
                                <div className='col-xs-12' id='checkboxMenu'>
                                    <CheckBoxComponent ref={chkbox => this.chkBoxCopyStarts = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_starts' })} />
                                </div> : null}
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxCopyParams = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_params' })} />
                            </div>
                            {!this.state.IsTAG ?
                                <div className='col-xs-12' id='checkboxMenu'>
                                    <CheckBoxComponent ref={chkbox => this.chkBoxCopyRefrigerations = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_refrigeration' })} />
                                </div> : null}
                        </div>
                    </div>
                );
    }


    pasteConfiguration() {
        this.setState({ showDialogAlert: true, Option: 'pasteConfiguration' });
    }

    confirmPaste() {
        let enable = {
            CyclesEnabled: this?.chkCyclesEnabled?.checked,
            StartsEnabled: this?.chkStartsEnabled?.checked,
            ParamsEnabled: this?.chkParamsEnabled?.checked,
            RefrigerationsEnabled: this?.chkRefrigerationsEnabled?.checked,
        };

        this.props.PasteConfiguration(enable);
        this.setState({ showDialogAlert: false });
        this.menuCloseClear();
    }

    onCreate() {
        this.txtDescription.respectiveElement.style.height = 'auto';
        this.txtDescription.respectiveElement.style.height = (this.txtDescription.respectiveElement.scrollHeight) + 'px';
    }
    onInput() {
        this.txtDescription.respectiveElement.style.height = 'auto';
        this.txtDescription.respectiveElement.style.height = (this.txtDescription.respectiveElement.scrollHeight) + 'px';
    }

    renderPasteConfiguration() {
        if (this.state.SelectedMenu === 1001)
            return (
                <div id={'divPasteConfiguration' + this.props.Option}>

                    <div className='row headerMenuConfiguration' >
                        <div id='divConfigurationTop' className='col-xs-4'>
                            <span className='lblDescription'><FormattedMessage id='configuration_paste' /></span>
                        </div>
                        <div className={'col-xs-8' + ' ' + 'divBtnTop' + this.props.Option}>
                            <ButtonComponent className='Button' iconCss='e-icons e-FB_Paste' onClick={() => this.pasteConfiguration()}><FormattedMessage id='paste' disabled={this.state.PasteEnabled} /></ButtonComponent>
                            <ButtonComponent id='btnCloseMenuTop' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClick()}><FormattedMessage id='close' /></ButtonComponent>
                        </div>
                    </div>

                    <div className='row divSelectCopyConfiguration'>
                        <div className='col-xs-12' id='titleCopyConfiguration'>
                            <span className='lblDescription'><FormattedMessage id='select_data_paste' /></span>
                        </div>
                        {!this.state.IsTAG ?
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_cycles' })}
                                    ref={chk => this.chkCyclesEnabled = chk}
                                    disabled={!this.props?.CurrentConfiguration?.CyclesEnabled}
                                    checked={this.props?.CurrentConfiguration?.CyclesEnabled} />
                            </div> : null}
                        {!this.state.IsTAG ?
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_starts' })}
                                    ref={chk => this.chkStartsEnabled = chk}
                                    disabled={!this.props?.CurrentConfiguration?.StartsEnabled}
                                    checked={this.props?.CurrentConfiguration?.StartsEnabled} />
                            </div> : null}
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_params' })}
                                ref={chk => this.chkParamsEnabled = chk}
                                disabled={!this.props?.CurrentConfiguration?.ParamsEnabled}
                                checked={this.props?.CurrentConfiguration?.ParamsEnabled} />
                        </div>
                        {!this.state.IsTAG ?
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_refrigeration' })}
                                    ref={chk => this.chkRefrigerationsEnabled = chk}
                                    disabled={!this.props?.CurrentConfiguration?.RefrigerationsEnabled}
                                    checked={this.props?.CurrentConfiguration?.RefrigerationsEnabled} />
                            </div> : null}
                    </div>
                    <div className='row'>
                        <div className='col-xs-12' id='titlePasteData'>
                            <span className='lblDescription'><FormattedMessage id='data_origin' /></span>
                        </div>
                        <div className='col-xs-12'>
                            <TextBoxComponent className='lblDescription'
                                value={this.props?.CurrentConfiguration?.Name + '\r\n' + ' ' + '\r\n' + this.props?.CurrentConfiguration?.Description + '\r\n'}
                                input={this.onInput = this.onInput.bind(this)} created={this.onCreate = this.onCreate.bind(this)}
                                multiline={true} readOnly={true} ref={txt => { this.txtDescription = txt }} floatLabelType='Always'
                            />
                        </div>
                    </div>
                </div>
            );
    }

    rowConfigurationSelected() {
        let id = -1;
        let selected;

        if (this.gridInstance.getSelectedRecords().length === 1) {
            id = this.gridInstance.getSelectedRecords()[0].Id;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        let cloneConfigurations = JSON.parse(JSON.stringify(this.state.ConfigurationsLoad));
        for (let i = 0; i < cloneConfigurations.length; i++) {
            cloneConfigurations[i].Selected = (cloneConfigurations[i].Id === id);
        }

        this.setState({ SelectedConfiguration: selected, LoadEnabled: (id !== -1) ? true : false, ConfigurationsLoad: cloneConfigurations });
    }

    loadConfiguration() {
        this.props.LoadConfiguration(this.state.SelectedConfiguration);
        this.menuCloseClear();
    }
    renderLoadConfiguration() {

        if (this.state.SelectedMenu === 1002)
            return (
                <div id={'divLoadConfiguration' + this.props.Option}>

                    <div className='row headerMenuConfiguration'>

                        <div id='divConfigurationTop' className='col-xs-4'>
                            <span className='lblDescription'><FormattedMessage id='configuration_load' /></span>
                        </div>
                        <div className={'col-xs-8' + ' ' + 'divBtnTop' + this.props.Option}>
                            <ButtonComponent className='Button' iconCss='e-icons e-F_PV_Download' disabled={!this.state.LoadEnabled} onClick={() => this.loadConfiguration()}><FormattedMessage id='load' /></ButtonComponent>
                            <ButtonComponent id='btnCloseMenuTop' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClear()}><FormattedMessage id='close' /></ButtonComponent>
                        </div>
                    </div>

                    <div id='divLoadConfigurationsGrid'>
                        <GridComponent id='gridLoadConfiguration' ref={grid => this.gridInstance = grid}
                            dataSource={this.state.ConfigurationsLoad}
                            allowFiltering={true} enableToggle={true} enableHover={true}
                            allowSorting={true} allowSelection={true}
                            filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                            rowSelected={this.rowConfigurationSelected.bind(this)} >
                            <ColumnsDirective>
                                <ColumnDirective field='Selected' headerText='' width='35px' displayAsCheckBox={true} allowFiltering={false}  ></ColumnDirective>
                                <ColumnDirective field='Id' visible={false} isPrimaryKey={true}></ColumnDirective>
                                <ColumnDirective field='Name' headerText={this.props.intl.formatMessage({ id: 'name' })} width='25%' ></ColumnDirective>
                                <ColumnDirective field='Description' headerText={this.props.intl.formatMessage({ id: 'description' })} visible={true} ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort, Search]} />
                        </GridComponent>
                    </div>
                </div>
            );
    }

    hideAlert() {
        this.setState({ showDialogAlert: false });
    }

    saveConfiguration() {

        let data = {
            Name: this.txtConfigurationName.value,
            Description: this.txtConfigurationDescription.value,
            CyclesEnabled: this?.chkBoxSaveCycles?.checked,
            StartsEnabled: this?.chkBoxSaveStarts?.checked,
            ParamsEnabled: this?.chkBoxSaveParams?.checked,
            RefrigerationsEnabled: this?.chkBoxSaveRefrigerations?.checked
        };

        if (data.Name === '' || !data.CyclesEnabled && !data.StartsEnabled && !data.ParamsEnabled && !data.RefrigerationsEnabled) {
            this.setState({ showDialogAlert: true, Option: 'saveConfiguration', CloneDataSave: data });
        } else {
            this.props.SaveConfiguration(data);
            this.menuCloseClick();
        }
    }

    renderSaveConfiguration() {
        if (this.state.SelectedMenu === 1003)
            return (
                <div id={'divCopyConfig' + this.props.Option}>

                    <div className='row headerMenuConfig'>
                        <div id='divConfigurationTop' className='col-xs-4'>
                            <span className='lblDescription'><FormattedMessage id='configuration_save' /></span>
                        </div>

                        <div className={'col-xs-8' + ' ' + 'divBtnTop' + this.props.Option}>
                            <ButtonComponent className='Button' iconCss='e-icons e-FB_PV_Save' onClick={() => this.saveConfiguration()}><FormattedMessage id='save' /></ButtonComponent>
                            <ButtonComponent id='btnCloseMenuTop' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClick()}><FormattedMessage id='close' /></ButtonComponent>
                        </div>
                    </div>

                    <div id='divDescriptionConfiguration' >
                        <div className='titleDescription'>
                            <span className='lblDescription'><FormattedMessage id='configuration_name' /></span>
                            <input ref={txt => this.txtConfigurationName = txt} className='e-input' required placeholder={this.props.intl.formatMessage({ id: 'enter_name_config' })} />
                        </div>
                        <div className='titleDescription'>
                            <span className='lblDescription'>{this.props.intl.formatMessage({ id: 'description' }).toUpperCase()}</span>
                            <input ref={txt => this.txtConfigurationDescription = txt} className='e-input' placeholder={this.props.intl.formatMessage({ id: 'enter_description_config' })} />
                        </div>
                    </div>

                    <div className='row divSelectSaveConfiguration'>
                        <div className='col-xs-12' id='titleSaveConfiguration'>
                            <span className='lblDescription'><FormattedMessage id='select_data_save' /></span>
                        </div>
                        {!this.state.IsTAG ?
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxSaveCycles = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_cycles' })} />
                            </div> : null}
                        {!this.state.IsTAG ?
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxSaveStarts = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_starts' })} />
                            </div> : null}
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent ref={chkbox => this.chkBoxSaveParams = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_params' })} />
                        </div>
                        {!this.state.IsTAG ?
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxSaveRefrigerations = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_refrigeration' })} />
                            </div> : null}
                    </div>
                </div>
            );
    }

    render() {
        return (
            <div>
                {this.renderMenuConfigurations()}
                {this.renderDeviceDataConfiguration()}
                {this.renderCyclesConfiguration()}
                {this.renderStartsConfiguration()}
                {this.renderParamsConfiguration()}
                {this.renderCopyConfiguration()}
                {this.renderPasteConfiguration()}
                {this.renderLoadConfiguration()}
                {this.renderSaveConfiguration()}
                {this.renderRefrigerationConfiguration()}
            </div>

        );
    }
}

MenuConfigurations.propTypes = {
    IsConfigurationMenu: PropTypes.bool,
    SelectedDevices: PropTypes.any,
    Detail: PropTypes.any,
    intl: PropTypes.object,
    SetData: PropTypes.func,
    SetCycles: PropTypes.func,
    SetStarts: PropTypes.func,
    SetParams: PropTypes.func,
    SetRefrigerations: PropTypes.func,
    SetFlagsEnabled: PropTypes.func,
    Configurations: PropTypes.array,
    CurrentConfiguration: PropTypes.object,
    LoadConfiguration: PropTypes.func,
    SaveConfiguration: PropTypes.func,
    CopyConfiguration: PropTypes.func,
    PasteConfiguration: PropTypes.func,
    ListSites: PropTypes.any,
    ListDepartments: PropTypes.any,
    Option: PropTypes.string,
    UmTemperature: PropTypes.string,
    IsNewConfiguration: PropTypes.bool,
    RowConfigurationSelected: PropTypes.func,
    MenuSelected: PropTypes.func,
    IsMultiSelected: PropTypes.bool,
    IsCommSis: PropTypes.bool,
    CyclesPermit: PropTypes.bool,
    ParamsPermit: PropTypes.bool,
    StartsPermit: PropTypes.bool,
    RefrigerationsPermit: PropTypes.bool,
};

export default injectIntl(MenuConfigurations);