import * as React from 'react';
import PropTypes from 'prop-types';
import '../styles/dialogPasteResults.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, VirtualScroll } from '@syncfusion/ej2-react-grids';

class DialogPasteResults extends React.Component {
    constructor() {
        super(...arguments);

        this.result = this.resultTemplate.bind(this);
    }


    resultTemplate(props) {
        if (props.Result === 0)
            return (<div></div>);
        if (props.Result === 1)
            return (<img className='pasteSuccess' src={'/images/icons/success.svg'}></img>);
        if (props.Result === -1)
            return (<img className='pasteError' src={'/images/icons/error.svg'}></img>);
    }


    render() {
        return (
            <div className='divBlockDialog'>
                <div className='dialogPasteResults'>
                    <div>
                        <div>
                            <GridComponent id='monitorGridSelected'
                                ref={grid => this.gridInstanceSelected = grid}
                                dataSource={this.props.SelectedDevices}
                                enableVirtualization={true}
                                enableHover={false} height='95%'
                                allowSorting={false} allowSelection={false}>
                                <ColumnsDirective>
                                    <ColumnDirective field='DeviceSN' visible={false} headerText='KEY' width='0' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective field='DeviceID' headerText={this.props.intl.formatMessage({ id: 'id_trolley' })} width='20%'></ColumnDirective>
                                    <ColumnDirective field='Site' headerText={this.props.intl.formatMessage({ id: 'site' })} width='30%' />
                                    <ColumnDirective field='Department' headerText={this.props.intl.formatMessage({ id: 'department' })} width='30%'></ColumnDirective>
                                    <ColumnDirective field='Result' headerText='Result' width='20%' template={this.result}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[VirtualScroll]} />
                            </GridComponent>
                        </div>
                        <div>
                            <ButtonComponent id='divBtnClosePasteResult' disabled={this.props.PasteState !== 2} onClick={() => this.props.Confirm()}>{this.props.intl.formatMessage({ id: 'confirm' })}</ButtonComponent>
                        </div>
                    </div>
                </div>
                <div className='e-dlg-overlay' />
            </div>
        );
    }
}

DialogPasteResults.propTypes = {
    intl: PropTypes.object,
    Title: PropTypes.string,
    Message: PropTypes.string,
    Confirm: PropTypes.func,
    SelectedDevices: PropTypes.any,
    PasteState: PropTypes.number,
    Theme: PropTypes.string,
};

export default DialogPasteResults;