import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import '../../styles/manage/remoteControl.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import isEqual from 'react-fast-compare';
import DialogAlert from '../../components/DialogAlert';
import CommonDataManager from '../../components/CommonDataManager';
import { injectIntl, FormattedMessage } from 'react-intl';

class RemoteControl extends React.Component {
    constructor(props) {
        super(props);

        this.commonData = CommonDataManager.getInstance();

        this.state = {
            showDialogAlert: false,
            SelectedCycle: null,
            Option: ''
        };

        this.remoteControlDisable();

    }

    remoteControlDisable() {
        this.enableOFF = false;
        this.enableON = false;
        this.enableMUTE = false;
        this.enableC1 = false;
        this.enableC2 = false;
        this.enableC3 = false;
        this.enableC4 = false;
        this.enableC5 = false;
        this.enableC6 = false;
        this.enableC7 = false;
        this.enableC8 = false;
        this.enableR_ = false;
        this.enableLB1 = false;
        this.enableLB2 = false;
        this.enableWP = false;
        this.enableDETACH = false;

        this.optionLB1 = '';
        this.optionLB2 = '';
        this.optionWP = '';
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextState.showDialogAlert !== this.state.showDialogAlert) return true;

        //aggiorna solo se il numero di dispositivi selezionati è diverso o se i dispositivi selezionati sono differenti
        let equals = nextProps?.SelectedDevices?.length === this.props?.SelectedDevices?.length &&
            nextProps?.SelectedDevices.every((e) => this.props.SelectedDevices?.filter((s) => s.Id === e.Id).length === 1);

        //se i dispositivi selezionati non sono cambiati,e se ne è stato selezionato solo uno, verifica se aggiornare solo se c'e' stata una modifica nei dati del carrello
        if (equals)
            if (this.props?.SelectedDevices?.length === 1)
                equals = !isEqual(nextProps?.SelectedDevices[0]?.Detail, this.props?.SelectedDevices[0]?.Detail);

        //se non ci sono modifiche,aggiorna lo stato dei bottoni di controllo remoto
        if (nextProps?.SelectedDevices?.length === 1) {
            if (!equals) {
                let r = nextProps?.SelectedDevices[0]?.Remotes;

                if (r !== undefined) {
                    this.enableOFF = (r.filter(x => x.RemoteCmd === 'OFF').length === 1 ? r.filter(x => x.RemoteCmd === 'OFF')[0].Enabled : false);
                    this.enableON = (r.filter(x => x.RemoteCmd === 'ON').length === 1 ? r.filter(x => x.RemoteCmd === 'ON')[0].Enabled : false);
                    this.enableC1 = (r.filter(x => x.RemoteCmd === 'C_1').length === 1 ? r.filter(x => x.RemoteCmd === 'C_1')[0].Enabled : false);
                    this.enableC2 = (r.filter(x => x.RemoteCmd === 'C_2').length === 1 ? r.filter(x => x.RemoteCmd === 'C_2')[0].Enabled : false);
                    this.enableC3 = (r.filter(x => x.RemoteCmd === 'C_3').length === 1 ? r.filter(x => x.RemoteCmd === 'C_3')[0].Enabled : false);
                    this.enableC4 = (r.filter(x => x.RemoteCmd === 'C_4').length === 1 ? r.filter(x => x.RemoteCmd === 'C_4')[0].Enabled : false);
                    this.enableC5 = (r.filter(x => x.RemoteCmd === 'C_5').length === 1 ? r.filter(x => x.RemoteCmd === 'C_5')[0].Enabled : false);
                    this.enableC6 = (r.filter(x => x.RemoteCmd === 'C_6').length === 1 ? r.filter(x => x.RemoteCmd === 'C_6')[0].Enabled : false);
                    this.enableC7 = (r.filter(x => x.RemoteCmd === 'C_7').length === 1 ? r.filter(x => x.RemoteCmd === 'C_7')[0].Enabled : false);
                    this.enableC8 = (r.filter(x => x.RemoteCmd === 'C_8').length === 1 ? r.filter(x => x.RemoteCmd === 'C_8')[0].Enabled : false);
                    this.enableR_ = (r.filter(x => x.RemoteCmd === 'C_R_').length === 1 ? r.filter(x => x.RemoteCmd === 'C_R_')[0].Enabled : false);
                    this.enableLB1 = (r.filter(x => x.RemoteCmd === 'EVO_LB_1').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_LB_1')[0].Enabled : false);
                    this.optionLB1 = (r.filter(x => x.RemoteCmd === 'EVO_LB_1').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_LB_1')[0].Option : '0');
                    this.enableLB2 = (r.filter(x => x.RemoteCmd === 'EVO_LB_2').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_LB_2')[0].Enabled : false);
                    this.optionLB2 = (r.filter(x => x.RemoteCmd === 'EVO_LB_2').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_LB_2')[0].Option : '0');
                    this.enableWP = (r.filter(x => x.RemoteCmd === 'EVO_WP').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_WP')[0].Enabled : false);
                    this.optionWP = (r.filter(x => x.RemoteCmd === 'EVO_WP').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_WP')[0].Option : '0');
                    this.enableMUTE = (r.filter(x => x.RemoteCmd === 'MUTE').length === 1 ? r.filter(x => x.RemoteCmd === 'MUTE')[0].Enabled : false);
                    this.enableDETACH = (r.filter(x => x.RemoteCmd === 'DETACH').length === 1 ? r.filter(x => x.RemoteCmd === 'DETACH')[0].Enabled : false);
                }
            }
        }
        else {
            equals = false;
            this.enableOFF = true;
            this.enableON = true;
            this.enableC1 = true;
            this.enableC2 = true;
            this.enableC3 = true;
            this.enableC4 = true;
            this.enableC5 = true;
            this.enableC6 = true;
            this.enableC7 = true;
            this.enableC8 = true;
            this.enableR_ = true;
            this.enableLB1 = true;
            this.enableLB2 = true;
            this.enableWP = true;
            this.enableMUTE = true;
            this.enableDETACH = true;
        }

        return !equals;
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevState.showDialogAlert !== this.state.showDialogAlert)
            if (this.state.showDialogAlert) {

                let idMessage = 'alert_cycle_progress';

                if (this.props.SelectedDevices.length > 1)
                    idMessage = 'alert_multi_select';

                ReactDOM.render(<DialogAlert
                    intl={this.props.intl}
                    AlertType={2}
                    Title={this.props.intl.formatMessage({ id: 'warning' })}
                    Message={this.props.intl.formatMessage({ id: idMessage })}
                    Cancel={this.hideAlert.bind(this)}
                    Confirm={this.confirm.bind(this)}
                    Option={this.state.Option} />, document.querySelector('#divMonitorAlert'));

            }
            else {
                ReactDOM.unmountComponentAtNode(document.querySelector('#divMonitorAlert'));
            }
    }


    cycleStart() {
        let startCycle = 'C_' + this.state.SelectedCycle;
        this.props.RemoteControl(startCycle);
    }

    cycleStartPrefer(cicle) {
        let startCyclePrefer = 'C_' + cicle;
        this.props.RemoteControl(startCyclePrefer);
    }

    dropdownCyclesChanged(args) {
        if (args.isInteracted)
            this.setState({ SelectedCycle: this.dropdownCycles.value });
    }

    renderRemoteControlV3() {

        let btnPreferredCycles = [];
        let listPreferred = this.props?.Detail?.Cycles?.filter(item => item.IsPreferred === true);
        let btnDisconnected;

        let contPreferredCycles = listPreferred?.length;
        if (contPreferredCycles > 8)
            contPreferredCycles = 8;

        if (contPreferredCycles > 0) {
            for (let i = 0; i < contPreferredCycles; i++)
                btnPreferredCycles.push(<ButtonComponent id={'remote_cycle_v3_' + i} key={i}
                    className='btnRemote'
                    onClick={() => this.cycleStartPrefer(listPreferred[i].ID)}
                    disabled={this.props?.Detail?.Status !== 2}>
                    <FormattedMessage id='remote_cycle' /> {listPreferred[i].ID}
                </ButtonComponent>);
        }

        if (this.props.SelectedDevices !== undefined) {
            if (this.props.SelectedDevices.filter(cm => cm.ModelId === 2).length === 1) {
                btnDisconnected = (
                    <div id='btnRelease'>
                        <ButtonComponent className='btnRemote' disabled={!this.enableDETACH} iconCss='e-icons e-chevron-up-fill' onClick={() => this.props.RemoteControl('DETACH')} ></ButtonComponent>
                    </div>
                )
            }
        }

        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 0)
            return (
                <div id='divControlV3'>
                    <div >
                        {btnPreferredCycles?.length > 0 &&
                            <div id='btnCyclesPreferred'>
                                <span id='lblInfoRemote'><FormattedMessage id='cycles_preferred' /></span>
                                {btnPreferredCycles}
                            </div>
                        }
                        <span id='lblInfoRemote'><FormattedMessage id='remote_cycles' /></span>
                        <div id='listCycle'>
                            <DropDownListComponent className='listCycle' width='280px'
                                ref={dropdown => this.dropdownCycles = dropdown}
                                fields={{ value: 'ID', text: 'Name' }}
                                dataSource={this.props?.Detail?.Cycles}
                                enabled={this.props?.Detail?.Status === 2}
                                change={this.dropdownCyclesChanged.bind(this)} />
                            <ButtonComponent className='btnStartCycle' disabled={this.state.SelectedCycle === null || this.props?.Detail?.Status !== 2} onClick={() => this.cycleStart()}><FormattedMessage id='start_cycle' /></ButtonComponent>
                        </div>
                        {btnDisconnected}
                    </div>
                </div>);
    }

    renderRemoteControlCYCLE() {
        let multiCycle = false;
        let visibileCyclesV3;

        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 0) { visibileCyclesV3 = true }
        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length > 1 || this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 1) { multiCycle = true }
        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length > 0 && this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 0) { multiCycle = true }

        if (this.props.SelectedDevices.filter(d => d.ModelId === this.comm).length === 1) {
            for (let i = 0; i < this.props.SelectedDevices.length; i++) {
                let alarmsVisible = this.props.SelectedDevices[i].Alarms;
                if (alarmsVisible !== undefined) {
                    if ((this.props.SelectedDevices[i].DSPROId === -1) && (alarmsVisible.length > 0)) {
                        multiCycle = true;
                    }
                    else
                        multiCycle = false;
                }
            }
        }

        if (this.props?.SelectedDevices === undefined)
            return null;

        let extraCycle = null;
        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length === 0)
            if (this.props.SelectedDevices.filter(d => d.HasColdCycles === false).length === 0)
                extraCycle = (
                    <div>
                        <ButtonComponent className='btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>
                        <ButtonComponent className='btnRemote' disabled={!this.enableC5} onClick={() => this.props.RemoteControl('C_5')}><FormattedMessage id='remote_cycle' /> 5</ButtonComponent>
                        <ButtonComponent className='btnRemote' disabled={!this.enableC6} onClick={() => this.props.RemoteControl('C_6')}><FormattedMessage id='remote_cycle' /> 6</ButtonComponent>
                    </div>
                );
            else
                extraCycle = (<ButtonComponent className='btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>);

        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v1').length === 0)
            extraCycle = (<ButtonComponent className='btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>);

        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v1').length !== 0)
            if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length !== 0)
                if (this.props.SelectedDevices.filter(d => d.HasColdCycles === false).length === 0)
                    extraCycle = (<ButtonComponent className='btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>);


        return (
            <div> {!multiCycle &&
                <div>
                    {!visibileCyclesV3 &&
                        <div>
                            <span id='lblInfoRemote'><FormattedMessage id='remote_cycle' /></span>
                            <ButtonComponent className='btnRemote' immediateRender='true' disabled={!this.enableC1} onClick={() => this.props.RemoteControl('C_1')}><FormattedMessage id='remote_cycle' /> 1</ButtonComponent>
                            <ButtonComponent className='btnRemote' immediateRender='true' disabled={!this.enableC2} onClick={() => this.props.RemoteControl('C_2')}><FormattedMessage id='remote_cycle' /> 2</ButtonComponent>
                            <ButtonComponent className='btnRemote' immediateRender='true' disabled={!this.enableC3} onClick={() => this.props.RemoteControl('C_3')}><FormattedMessage id='remote_cycle' /> 3</ButtonComponent>
                            {extraCycle}
                        </div>
                    }
                    {visibileCyclesV3 &&
                        <div>
                            {this.renderRemoteControlV3()}
                        </div>
                    }
                </div>
            }
            </div>
        );
    }

    renderRemoteControlMUTE() {
        if (this.props?.SelectedDevices)
            if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v1').length > 0)
                return null;

        return (
            <ButtonComponent className='btnRemoteMute' disabled={!this.enableMUTE} onClick={() => this.props.RemoteControl('MUTE')}><FormattedMessage id='remote_mute' /></ButtonComponent>
        );
    }

    recipeStart() {
        let recipeStartTest = 'C_' + this.dropDownReceipt.value;
        this.props.RemoteControl(recipeStartTest);
    }

    renderRemoteControlEVO() {

        //comandi cicli evomulti visibili solo se non ci sono dispositivi evomulti selezionati
        let visibileRemoteCmdEvo = this.props?.SelectedDevices?.filter(d => !d.IsEvo).length === 0;

        //comando ricetta visibile solo se è stato selezionato un singolo dispositivo ed è un modello evomulti
        //(come è possibile fare partire simultaneamente l'avvio di ricette di più evomulti? potrebbero avere ricette totalmente differenti...)
        let visibileRemoteReceiptEvo = this.props?.SelectedDevices?.filter(d => d.IsEvo).length === 1;

        //elabora il nome della ricette aggiungendo il numero della ricetta con il suo relativo nome 
        let listRecipes = this.props?.Detail?.Cycles?.filter(item => item.ID > 8);
        if (listRecipes !== undefined)
            for (let i = 0; i < listRecipes.length; i++) {
                listRecipes[i].CompleteName = listRecipes[i].IDCycle + ' ' + listRecipes[i].Description;
            }

        return (
            visibileRemoteCmdEvo &&
            <div id='divRemoteEVO'>
                {visibileRemoteCmdEvo &&
                    <div>
                        <ButtonComponent className='btnRemote' disabled={!this.enableC5} onClick={() => this.props.RemoteControl('C_5')}><FormattedMessage id='remote_cycle' /> 5</ButtonComponent>
                        <ButtonComponent className='btnRemote' disabled={!this.enableC6} onClick={() => this.props.RemoteControl('C_6')}><FormattedMessage id='remote_cycle' /> 6</ButtonComponent>
                        <ButtonComponent className='btnRemote' disabled={!this.enableC7} onClick={() => this.props.RemoteControl('C_7')}><FormattedMessage id='remote_cycle' /> 7</ButtonComponent>
                        <ButtonComponent className='btnRemote' disabled={!this.enableC8} onClick={() => this.props.RemoteControl('C_8')}><FormattedMessage id='remote_cycle' /> 8</ButtonComponent>
                    </div>
                }
                {visibileRemoteReceiptEvo &&
                    <div>
                        <span id='lblInfoRemote'><FormattedMessage id='recipes' /></span>
                        <div id='listRecipe'>
                            <DropDownListComponent className='listRecipe' width='280px'
                                ref={dropdown => this.dropDownReceipt = dropdown}
                                enabled={this.enableR_}
                                fields={{ value: 'IDCycle', text: 'CompleteName' }}
                                dataSource={listRecipes}
                            />
                            <ButtonComponent className='btnRecipe' disabled={!this.enableR_} onClick={this.recipeStart.bind(this)}><FormattedMessage id='start_recipe' /></ButtonComponent>
                        </div>
                    </div>}
                {visibileRemoteCmdEvo &&
                    <div>
                        <span id='lblInfoRemote'><FormattedMessage id='actions' /></span>
                        <div>
                            <ButtonComponent className='btnRemoteActions' disabled={!this.enableLB1} onClick={() => this.props.RemoteControl('EVO_LB_1')}><img id={'imgIconLight'} src={'/images/icons/light_' + this.optionLB1 + '.png'}></img><FormattedMessage id='remote_light' /> 1</ButtonComponent>
                            <ButtonComponent className='btnRemoteActions' disabled={!this.enableLB2} onClick={() => this.props.RemoteControl('EVO_LB_2')}><img id={'imgIconLight'} src={'/images/icons/light_' + this.optionLB2 + '.png'}></img><FormattedMessage id='remote_light' /> 2</ButtonComponent>
                            <ButtonComponent className='btnRemoteActionsPlate' disabled={!this.enableWP} onClick={() => this.props.RemoteControl('EVO_WP')}><img id={'imgIconLight'} src={'/images/icons/plate_' + this.optionWP + '.png'}></img><FormattedMessage id='remote_plate_warmer' /></ButtonComponent>
                        </div>
                    </div>
                }
            </div>
        );
    }

    hideAlert() {
        this.setState({ showDialogAlert: false });
    }

    confirm(option) {
        this.props.RemoteControl(option);
        this.setState({ showDialogAlert: false });
    }


    offRemote() {

        if (this.props?.SelectedDevices?.length === 1) {
            let cycleNumber = this.props?.SelectedDevices[0]?.CycleNumber;

            if ((cycleNumber === null) || (cycleNumber === 0))
                this.props.RemoteControl('OFF');
            else
                this.setState({ showDialogAlert: true, Option: 'OFF' });
        }
        else {
            this.setState({ showDialogAlert: true, Option: 'OFF' });
        }
    }

    onRemote() {
        if (this.props?.SelectedDevices?.length === 1) {
            this.props.RemoteControl('ON');
        }
        else
            if (this.props?.SelectedDevices?.length > 1)
                this.setState({ showDialogAlert: true, Option: 'ON' });

    }

    renderButtonsONOFF() {
        if (this.props?.SelectedDevices?.length > 0) {
            if (this.props.SelectedDevices.filter(d => d.ModelId === this.commonData.getTAGModelId()).length === 1) {
                for (let i = 0; i < this.props.SelectedDevices.length; i++) {
                    let alarmsVisible = this.props.SelectedDevices[i].Alarms;
                    if (alarmsVisible !== undefined) {
                        if ((this.props.SelectedDevices[i].DSPROId === -1) && (alarmsVisible.length > 0)) {
                            return (
                                <div>
                                    {this.renderRemoteControlMUTE()}
                                </div>
                            );
                        }
                        else
                            return (
                                <div>
                                    <ButtonComponent className='btnRemote' disabled={!this.enableON} onClick={() => this.onRemote()}><FormattedMessage id='remote_on' /></ButtonComponent>
                                    <ButtonComponent className='btnRemote' disabled={!this.enableOFF} onClick={() => this.offRemote()}><FormattedMessage id='remote_off' /></ButtonComponent>
                                    {this.renderRemoteControlMUTE()}
                                </div>
                            );
                    }
                }
            }
            else
                return (
                    <div>
                        <ButtonComponent className='btnRemote' disabled={!this.enableON} onClick={() => this.onRemote()}><FormattedMessage id='remote_on' /></ButtonComponent>
                        <ButtonComponent className='btnRemote' disabled={!this.enableOFF} onClick={() => this.offRemote()}><FormattedMessage id='remote_off' /></ButtonComponent>
                        {this.renderRemoteControlMUTE()}
                    </div>
                );
        }
    }

    render() {
        if (this.props?.SelectedDevices?.length === 0)
            return null;

        return (
            <div id='divRemoteControl'>
                <div id='divRemote'>
                    <span id='lblTitleRemote'><FormattedMessage id='remote_controls' /></span>
                    <span id='lblInfoRemote'><FormattedMessage id='status' /></span>
                    <div>
                        {this.renderButtonsONOFF()}
                    </div>

                    {this.renderRemoteControlCYCLE()}
                </div>
                {this.renderRemoteControlEVO()}
            </div>
        );
    }
}

RemoteControl.propTypes = {
    intl: PropTypes.object,
    Detail: PropTypes.any,
    SelectedDevices: PropTypes.any,
    RemoteControl: PropTypes.func,
    CycleNumber: PropTypes.any
};

export default injectIntl(RemoteControl);