import 'regenerator-runtime/runtime';

class Auth {
    
    
    async login(username, password) {

        let url='';
        
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_URL_TOKEN;
        } else {
            url = 'token';
        }

        let response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            body: 'username=' + username + '&password=' + password + '&grant_type=password&client_id=communicator',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        });

        let json = await response.json();

        if (!response.ok) {
            throw new Error(json.error_description);
        }
        else {
            let access_token = await json.access_token;
            await json.token_type;

            await window.localStorage.setItem('access_token', access_token);
        }
    }

    logout() {
        window.localStorage.removeItem('access_token');
        window.location.href = '/';
    }

    getAccessToken() {
        return window.localStorage.getItem('access_token');
    }

    hasAccessToken() {
        return this.getAccessToken() != null;
    }
}

export default new Auth();