import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/historyAlarmsPage.css';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, VirtualScroll, Sort, ExcelExport } from '@syncfusion/ej2-react-grids';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { L10n, setCulture } from '@syncfusion/ej2-base';


import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class AdminLoginAuditLogsPage extends React.Component {

    constructor(props) {
        super(props);

        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);
        this.commonData.subscribeDateTime(this.updateDateTime.bind(this));

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            FormatDateTime: this.commonData.getDateTimeFormat(),
            FormatDate: this.commonData.getDateFormat(),
            LoginAuditLogs: [],
            FromDate: this.today,
            ToDate: this.today
        };

        let initialState = { Filter: { FromDate: this.state.FromDate, ToDate: this.state.ToDate } };
        this.vm = dotnetify.react.connect('AdminLoginAuditLogsVM', this, { vmArg: initialState });
    }

    updateLanguage() {
        let commonData = CommonDataManager.getInstance();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
    }

    updateDateTime() {
        this.setState({ FormatDateTime: this.commonData.getDateTimeFormat(), FormatDate: this.commonData.getDateFormat() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('AdminLoginAuditLogsPage');

        if (this.daterangepickerInstance != undefined) {
            this.daterangepickerInstance.startDate = this.state.FromDate;
            this.daterangepickerInstance.endDate = this.state.ToDate;
        }
    }
    componentWillUnmount() {
        if (this.vm != undefined)
            this.vm.$destroy();

        if (this.commonData != undefined)
            this.commonData.unsubscribe(this.updateLanguage);
        this.commonData?.unsubscribeDateTime(this.updateDateTime);
    }

    filterDateAlarmsHistory() {
        if (this.daterangepickerInstance.startDate != undefined)
            if (this.daterangepickerInstance.endDate != undefined)
                this.vm.$dispatch({ Filter: { FromDate: this.daterangepickerInstance.startDate, ToDate: this.daterangepickerInstance.endDate } });
    }

    exportAlarmsHistory() {
        let pathExcelExport = this.intl.formatMessage({ id: 'menuadminheader.loginauditlogs' }) + '_' + this.intl.formatDate(this.daterangepickerInstance.startDate) + '_' + this.intl.formatDate(this.daterangepickerInstance.endDate) + '.xlsx';
        if (this.gridInstance != undefined)
            this.gridInstance.excelExport({ fileName: pathExcelExport });
    }

    render() {

        let presets = [
            { label: this.intl?.formatMessage({ id: 'today' }), start: this.today, end: this.today },
            { label: this.intl?.formatMessage({ id: 'this_week' }), start: this.weekStart, end: this.weekEnd },
            { label: this.intl?.formatMessage({ id: 'this_month' }), start: this.monthStart, end: this.monthEnd },
            { label: this.intl?.formatMessage({ id: 'last_month' }), start: this.lastStart, end: this.lastEnd },
            { label: this.intl?.formatMessage({ id: 'last_year' }), start: this.yearStart, end: this.yearEnd },
        ];
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divLoginAuditLogs'>
                    <BasePage title={this.intl.formatMessage({ id: 'menuadminheader.loginauditlogs' })} navigation={this.intl.formatMessage({ id: 'menuadminheader.loginauditlogs' })}></BasePage>
                    <div className='row divToolbarHistoryCell'>
                        <div className='col-sm-12 col-md-10 col-lg-10'><span id='textFilter'><FormattedMessage id='filter' /></span>
                            <DateRangePickerComponent id='datarangeFilter' format={this.state.FormatDate}
                                startDate={this.state.FromDate} endDate={this.state.ToDate}
                                allowEdit={false} presets={presets} delayUpdate={true}
                                change={this.filterDateAlarmsHistory.bind(this)}
                                ref={calendar => this.daterangepickerInstance = calendar}>
                            </DateRangePickerComponent>
                        </div>
                        <div className='btnExportHistory col-sm-12 col-md-2 col-lg-2'>
                            <ButtonComponent className='Button'
                                disabled={this.state.LoginAuditLogs.length <= 0}
                                delayUpdate={true}
                                onClick={() => this.exportAlarmsHistory()}><FormattedMessage id='export' /></ButtonComponent>
                        </div>
                    </div>
                    <div id='divLoginAuditLogsGrid'>
                        <GridComponent id='loginAuditLogsGrid' ref={grid => this.gridInstance = grid}
                            dataSource={this.state.LoginAuditLogs} enableHover={true} enableVirtualization={false}
                            allowFiltering={true} selectionSettings={{ persistSelection: true, checkboxOnly: true }}
                            allowExcelExport={true}
                            filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                            allowSorting={true} allowSelection={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='DateTime' headerText={this.intl.formatMessage({ id: 'date' })} width='3%' type='datetime' format={this.state.FormatDateTime} ></ColumnDirective>
                                <ColumnDirective field='Username' headerText={this.intl.formatMessage({ id: 'username' })} width='5%'></ColumnDirective>
                                <ColumnDirective field='Name' headerText={this.intl.formatMessage({ id: 'name' })} width='5%'></ColumnDirective>
                                <ColumnDirective field='Surname' headerText={this.intl.formatMessage({ id: 'surname' })} width='5%' />
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort, VirtualScroll, ExcelExport]} />
                        </GridComponent>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

AdminLoginAuditLogsPage.propTypes = {
};

export default AdminLoginAuditLogsPage;