import React from 'react';
import PropTypes from 'prop-types';
import ImageModel from './ImageModel';

export function AvatarModel(props) {
    return (
        <div id='DeviceIDImage' className='e-avatar e-avatar-circle'  >
            <ImageModel
                Theme={props.Theme}
                ModelId={props.ModelId}
                DeviceVersion={props.DeviceVersion}
                Width={props.Width}
                Height={props.Height}
                Status={props.Status}
                DSPROId={props.DSPROId} />
        </div>);

}


AvatarModel.propTypes = {
    ModelId: PropTypes.number,
    DeviceVersion: PropTypes.string,
    Width: PropTypes.number,
    Height: PropTypes.number,
    Theme: PropTypes.string,
    Status: PropTypes.number,
    DSPROId: PropTypes.number
};

export default AvatarModel;
