import * as React from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/dialogAbout.css';
import { injectIntl, FormattedMessage } from 'react-intl';
class DialogAbout extends React.Component {

    constructor(props) {
        super(props);

    }

    dialogAboutClose() {
        this.props.HideAbout();
    }

    renderText() {
        return (
            <div id='divDialogAbout'>
                <img className='logo' src={'/images/logo/loginPage.png'} alt='Logo' />
                <div id='divText'>
                    <p id='instalInfo1'>
                        {this.props.InstallationInfo1}
                    </p>
                    <p id='instalInfo2'>
                        {this.props.InstallationInfo2}
                    </p>
                </div>
                <div id='divBtnAbt'>
                    <ButtonComponent id='btnAbt' onClick={this.dialogAboutClose.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                </div>
            </div>

        );
    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className='dialog'>
                    {this.renderText()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        );
    }
}

DialogAbout.propTypes = {
    InstallationInfo1: PropTypes.string,
    InstallationInfo2: PropTypes.string,
    HideAbout: PropTypes.func
};

export default injectIntl(DialogAbout);