import React from 'react';
import PropTypes from 'prop-types';
export function IDCyclesConfigurationTemplate(props) {
    try {
        let value = props.IDCycleItem;
        if ((value == null))
            return (
                <div></div>
            );
        else {
            return (
                <div>{value}</div>
            );
        }
    }
    catch (exp) {
        return (<div></div>);
    }
}

IDCyclesConfigurationTemplate.propTypes = {
    IDCycleItem: PropTypes.number
};
