import React from 'react';
import PropTypes from 'prop-types';

export function IDParamsConfigurationTemplate(props) {
    try {
        let value = props.Name;

        if (this.props.intl !== undefined) {
            value = this.props.intl.formatMessage({ id: value, defaultMessage: ' ' });
            return (
                <div>{value}</div>
            );
        }
    }
    catch (exp) {
        return (<div></div>);
    }
}

IDParamsConfigurationTemplate.propTypes = {
    Name: PropTypes.string
};
