import App from './views/App';
import MonitorPage from './views/MonitorPage';
import DashBoard from './views/DashBoard';
import HistoryAlarmsPage from './views/HistoryAlarmsPage';
import SetupUsersPage from './views/SetupUsersPage';
import SetupSitesPage from './views/SetupSitesPage';
import SetupDepartmentsPage from './views/SetupDepartmentsPage';
import ConfigurationsPage from './views/ConfigurationsPage';
import AdminLoginAuditLogsPage from './views/AdminLoginAuditLogsPage';
import MaintenancePage from './views/MaintenancePage';
import HistoryActivitesPage from './views/HistoryActivitesPage';
import HistoryImpactsPage from './views/HistoryImpactsPage';
import SetupAlarmsPage from './views/SetupAlarmsPage';
  
Object.assign(window, {
    MonitorPage,
    DashBoard,
    HistoryAlarmsPage,
    SetupUsersPage,
    SetupSitesPage,
    SetupDepartmentsPage,
    ConfigurationsPage,
    AdminLoginAuditLogsPage,
    MaintenancePage,
    HistoryActivitesPage,
    HistoryImpactsPage,
    SetupAlarmsPage
});

/*
if (module.hot) {
    const render = (react, elemId) => {
        ReactDOM.unmountComponentAtNode(document.getElementById(elemId));
        ReactDOM.render(React.createElement(react), document.getElementById(elemId));
    }

    module.hot.accept('./views/App.js', _ => render(require('views/App').default, 'App'));
    module.hot.accept('./views/DashBoard.js', _ => render(require('views/DashBoard').default, 'Content'));
    module.hot.accept('./views/MonitorPage.js', _ => render(require('views/MonitorPage').default, 'Content'));
    module.hot.accept('./views/HistoryAlarmsPage.js', _ => render(require('views/HistoryAlarmsPage').default, 'Content'));
    module.hot.accept('./views/SetupUsersPage.js', _ => render(require('views/SetupUsersPage').default, 'Content'));
}
 */
export default App;